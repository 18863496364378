import { AccessTypeEnum, Permissions, PermissionTypeEnum } from "../../api";

export const flowConfigPermissions: Array<Permissions> = [
  { Type: PermissionTypeEnum.EntryPointAdmin, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.QueueAdmin, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.QueueAdminCallbackConfiguration, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.FlowConfiguration, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.FlowConfigurationTemplates, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.Idv, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.PaymentFlows, Access: AccessTypeEnum.View },
];

export const flowConfigOperatingHoursPermission: Array<Permissions> = [
  { Type: PermissionTypeEnum.OperatingHours, Access: AccessTypeEnum.View },
];

export const flowConfigAddOperatingHoursPermissions: Array<Permissions> = [
  { Type: PermissionTypeEnum.OperatingHours, Access: AccessTypeEnum.Full },
];

export const flowConfigContactFlowsPermission: Array<Permissions> = [
  { Type: PermissionTypeEnum.EntryPointAdmin, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.QueueAdmin, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.FlowConfiguration, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.FlowConfigurationTemplates, Access: AccessTypeEnum.View },
];

export const flowConfigEPAPermission: Permissions = {
  Type: PermissionTypeEnum.EntryPointAdmin,
  Access: AccessTypeEnum.View,
};

export const flowConfigEditEPAPermission: Permissions = {
  Type: PermissionTypeEnum.EntryPointAdmin,
  Access: AccessTypeEnum.Edit,
};

export const flowConfigAddEPAPermission: Permissions = {
  Type: PermissionTypeEnum.EntryPointAdmin,
  Access: AccessTypeEnum.Full,
};

export const flowConfigConfigPermission: Array<Permissions> = [
  { Type: PermissionTypeEnum.FlowConfiguration, Access: AccessTypeEnum.View },
  {
    Type: PermissionTypeEnum.FlowConfigurationTemplates,
    Access: AccessTypeEnum.View,
  },
];

export const flowConfigFlowConfigPermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfiguration,
  Access: AccessTypeEnum.View,
};

export const flowConfigAddFlowConfigPermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfiguration,
  Access: AccessTypeEnum.Full,
};

export const flowConfigEditFlowConfigPermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfiguration,
  Access: AccessTypeEnum.Edit,
};

export const flowConfigConfigTemplatePermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfigurationTemplates,
  Access: AccessTypeEnum.View,
};

export const flowConfigEditConfigTemplatePermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfigurationTemplates,
  Access: AccessTypeEnum.Edit,
};

export const flowConfigAddConfigTemplatePermission: Permissions = {
  Type: PermissionTypeEnum.FlowConfigurationTemplates,
  Access: AccessTypeEnum.Full,
};

export const flowConfigEgressPermission: Permissions = {
  Type: PermissionTypeEnum.QueueAdminCallbackConfiguration,
  Access: AccessTypeEnum.View,
};

export const flowConfigEditEgressPermission: Permissions = {
  Type: PermissionTypeEnum.QueueAdminCallbackConfiguration,
  Access: AccessTypeEnum.Edit,
};

export const flowConfigAddEgressPermission: Permissions = {
  Type: PermissionTypeEnum.QueueAdminCallbackConfiguration,
  Access: AccessTypeEnum.Full,
};

export const userManagementPermissions: Array<Permissions> = [
  { Type: PermissionTypeEnum.RoleProfiles, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.AdministratorManagement, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.AgentManagementAgents, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.AgentManagementRoutingProfiles, Access: AccessTypeEnum.View },
];

export const userManagementAgentsPermission: Permissions = {
  Type: PermissionTypeEnum.AgentManagementAgents,
  Access: AccessTypeEnum.View,
};

export const userManagementEditAgentsPermission: Permissions = {
  Type: PermissionTypeEnum.AgentManagementAgents,
  Access: AccessTypeEnum.Edit,
};

export const userManagementAddAgentsPermission: Permissions = {
  Type: PermissionTypeEnum.AgentManagementAgents,
  Access: AccessTypeEnum.Full,
};

export const userManagementUsersPermission: Permissions = {
  Type: PermissionTypeEnum.AdministratorManagement,
  Access: AccessTypeEnum.View,
};

export const userManagementAddUserPermission: Permissions = {
  Type: PermissionTypeEnum.AdministratorManagement,
  Access: AccessTypeEnum.Full,
};

export const routingManagementPermissions: Array<Permissions> = [
  { Type: PermissionTypeEnum.AgentManagementRoutingProfiles, Access: AccessTypeEnum.View },
];

export const routingManagementRoutingProfilePermissions: Permissions = {
  Type: PermissionTypeEnum.AgentManagementRoutingProfiles,
  Access: AccessTypeEnum.View,
};

export const routingManagementQueuesPermissions: Permissions = {
  Type: PermissionTypeEnum.QueueAdmin,
  Access: AccessTypeEnum.View,
};

export const routingManagementAddQueuesPermissions: Permissions = {
  Type: PermissionTypeEnum.QueueAdmin,
  Access: AccessTypeEnum.Full,
};

export const routingManagementEditQueuesPermissions: Permissions = {
  Type: PermissionTypeEnum.QueueAdmin,
  Access: AccessTypeEnum.Edit,
};

export const routingManagementAddRoutingProfilePermissions: Permissions = {
  Type: PermissionTypeEnum.AgentManagementRoutingProfiles,
  Access: AccessTypeEnum.Full,
};

export const routingManagementEditRoutingProfilePermissions: Permissions = {
  Type: PermissionTypeEnum.AgentManagementRoutingProfiles,
  Access: AccessTypeEnum.Edit,
};

export const userManagementRolesPermission: Permissions = {
  Type: PermissionTypeEnum.RoleProfiles,
  Access: AccessTypeEnum.View,
};

export const auditLogsPermission: Permissions = {
  Type: PermissionTypeEnum.AuditTrail,
  Access: AccessTypeEnum.View,
};

export const managementInfoPermissions: Array<Permissions> = [
  { Type: PermissionTypeEnum.RealTimeViews, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.HistoricalData, Access: AccessTypeEnum.View },
  { Type: PermissionTypeEnum.Billing, Access: AccessTypeEnum.View },
];

export const manageUsersPermissions: Array<Permissions> = [
  ...userManagementPermissions,
  ...routingManagementPermissions,
];
