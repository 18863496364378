// These are lists of flow attribute types that we want to handle differently
// All other attribute types should just be dropdowns

export const TextInputAttributes = ["TEXT"];

export const NonArnDropdownAttributes = [
  "BOOLEAN",
  "CONFIGURATION",
  "OPTION",
  "OPTION1TO3",
  "OPTION1TO5",
  "WAITTIME",
  "VOICE",
];

export const ContactFlowAttributeType = "CONTACT_FLOW";
export const QueueAttributeType = "QUEUE";
export const OutboundWhisperType = "OUTBOUND_WHISPER";
export const VoiceType = "VOICE";
