import Button from "@vfuk/core-button";
import { Grid } from "@vfuk/core-grid";
import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import routes from "config/routes/routes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as PageNotFoundImage } from "../../assets/svg/page_not_found.svg";
import classes from "./NotFound.module.scss";

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const dashboardButtonPressedHandler = () => {
    history.push(routes.dashboard);
  };

  return (
    <Grid maxWidth="spring">
      <div className={classes.PageNotFound}>
        <PageNotFoundImage className={classes.Image} />
        <Heading level={3} weight={3} text={t("common:errors.page_not_found.title")} noMargin />
        <Paragraph>{t("common:errors.page_not_found.body")}</Paragraph>
        <Button
          text={t("common:navigation.dashboard")}
          appearance="secondary"
          onClick={dashboardButtonPressedHandler}
        />
      </div>
    </Grid>
  );
};

export default NotFound;
