import Footer from "@vfuk/core-footer";
import { OverlayProvider } from "@vfuk/core-overlay-controller";
import Ws10Theme from "@vfuk/core-theme-ws10";
import AuditLogs from "components/AuditLogs/AuditLogs";
import CenteredLoadingSpinner from "components/Shared/Loading/CenteredLoadingSpinner";
import SiteUpdatedNotification from "components/Shared/Notification/SiteUpdated/SiteUpdatedNotification";
import Splash from "components/Splash/Splash";
import routes from "config/routes/routes";
import useRevalidatedPermissions from "hooks/useRevalidatedPermissions/useRevalidatedPermissions";
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Login from "./components/Login/Login";
import NavBar from "./components/NavBar/NavBar";
import NotFound from "./components/NotFound/NotFound";
import GuardedRoute from "./components/Shared/Routes/GuardedRoute/GuardedRoute";
import ScrollToTop from "./components/Shared/Scroll/ScrollToTop/ScrollToTop";
import SignOut from "./components/SignOut/SignOut";
import {
  auditLogsPermission,
  flowConfigConfigPermission,
  flowConfigEgressPermission,
  flowConfigEPAPermission,
  flowConfigOperatingHoursPermission,
  flowConfigPermissions,
  userManagementPermissions,
} from "./config/permissions/routePermissions";
import { useAuthStore, useBusinessUnitsStore, usePermissionsStore } from "./hooks/stores";
import useInterceptors from "./hooks/useIntereptor/useInterceptors";

function App(): JSX.Element {
  useInterceptors();
  useRevalidatedPermissions();

  Ws10Theme.setBaseAssetLocation("/assets/");
  Ws10Theme.setAssetLocations("fonts", "fonts/");
  Ws10Theme.setAssetLocations("icons", "icons/");
  Ws10Theme.setAssetLocations("logos", "logos/");

  const [authChecked, setAuthChecked] = useState(false);
  const authenticated = useAuthStore((state) => state.authenticated);
  const token = useAuthStore((state) => state.token);
  const validateSession = useAuthStore((state) => state.validateSession);
  const signIn = useAuthStore((state) => state.signIn);

  const permissionsFetched = usePermissionsStore((state) => state.getPermissions.called);

  const fetchBusinessUnits = useBusinessUnitsStore((state) => state.getBusinessUnits.call);

  const checkAuth = () => {
    validateSession()
      .catch(() => {
        signIn();
      })
      .finally(() => {
        setAuthChecked(true);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (token) {
      fetchBusinessUnits();
    }
  }, [token]);

  const Dashboard = useMemo(() => lazy(() => import("./components/Dashboard/Dashboard")), []);
  const OperatingHours = useMemo(
    () => lazy(() => import("./components/OperatingHours/OperatingHours")),
    []
  );
  const FlowConfig = useMemo(() => lazy(() => import("./components/FlowConfig/FlowConfig")), []);
  const EPA = useMemo(() => lazy(() => import("./components/EPA/EPA")), []);
  const Egress = useMemo(() => lazy(() => import("./components/Egress/Egress")), []);
  const UserConfig = useMemo(() => lazy(() => import("./components/UserConfig/UsersConfig")), []);
  const RoutingManagement = useMemo(
    () => lazy(() => import("./components/RoutingManagement/RoutingManagement")),
    []
  );

  return (
    <>
      <ThemeProvider theme={Ws10Theme}>
        <OverlayProvider>
          <Ws10Theme.globalStyles />
          <SiteUpdatedNotification />
          {authChecked && permissionsFetched ? (
            <>
              <Router>
                <ScrollToTop />
                <Switch>
                  <GuardedRoute
                    exact
                    path="/"
                    allow={authenticated}
                    authRedirectPath={routes.login}
                    withNavBar
                    withFooter
                  >
                    <Suspense fallback={<CenteredLoadingSpinner />}>
                      <Dashboard />
                    </Suspense>
                  </GuardedRoute>
                  <GuardedRoute
                    path="/login"
                    allow={!authenticated}
                    authRedirectPath={routes.dashboard}
                  >
                    <Login />
                  </GuardedRoute>

                  <GuardedRoute
                    path="/flow-configuration"
                    allow={authenticated}
                    authRedirectPath={routes.login}
                    permissions={flowConfigPermissions}
                    permissionsRedirectPath={routes.dashboard}
                    withNavBar
                    withFooter
                  >
                    <Switch>
                      <GuardedRoute
                        permissions={flowConfigConfigPermission}
                        permissionsRedirectPath={routes.dashboard}
                        path={`/flow-configuration/configuration`}
                      >
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <FlowConfig />
                        </Suspense>
                      </GuardedRoute>
                      <GuardedRoute
                        permissions={[flowConfigEPAPermission]}
                        permissionsRedirectPath={routes.dashboard}
                        path={`/flow-configuration/epa`}
                      >
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <EPA />
                        </Suspense>
                      </GuardedRoute>
                      <GuardedRoute
                        permissions={flowConfigOperatingHoursPermission}
                        permissionsRedirectPath={routes.dashboard}
                        path={`/flow-configuration/operating-hours`}
                      >
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <OperatingHours />
                        </Suspense>
                      </GuardedRoute>
                      <GuardedRoute
                        permissions={[flowConfigEgressPermission]}
                        permissionsRedirectPath={routes.dashboard}
                        path={`/flow-configuration/queue-flow-admin`}
                      >
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <Egress />
                        </Suspense>
                      </GuardedRoute>
                    </Switch>
                  </GuardedRoute>

                  <GuardedRoute
                    path="/manage-users"
                    allow={authenticated}
                    authRedirectPath={routes.login}
                    withNavBar
                    withFooter
                  >
                    <Switch>
                      <GuardedRoute
                        permissions={userManagementPermissions}
                        permissionsRedirectPath={routes.dashboard}
                        path={`/manage-users/user-configuration`}
                      >
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <UserConfig />
                        </Suspense>
                      </GuardedRoute>
                      <Route path={`/manage-users/routing-management`}>
                        <Suspense fallback={<CenteredLoadingSpinner />}>
                          <RoutingManagement />
                        </Suspense>
                      </Route>
                    </Switch>
                  </GuardedRoute>
                  <GuardedRoute
                    path="/audit-logs"
                    allow={authenticated}
                    authRedirectPath={routes.login}
                    permissions={[auditLogsPermission]}
                    permissionsRedirectPath={routes.dashboard}
                    withNavBar
                    withFooter
                  >
                    <AuditLogs />
                  </GuardedRoute>
                  <GuardedRoute
                    exact
                    path="/SignOut"
                    allow={!authenticated}
                    authRedirectPath={routes.dashboard}
                  >
                    <SignOut />
                  </GuardedRoute>
                  <GuardedRoute
                    path="/"
                    allow={true}
                    authRedirectPath={routes.dashboard}
                    withNavBar
                    withFooter
                  >
                    <NotFound />
                  </GuardedRoute>
                  <Route>
                    <NavBar />
                    <Footer />
                  </Route>
                </Switch>
              </Router>
            </>
          ) : (
            <Splash />
          )}
        </OverlayProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
