const dev = {
  production: false,
  aws: {
    region: "eu-west-1",
    cognito: {
      userPoolId: "eu-west-1_F4yfI667O",
      userPoolAppClientId: "417inv42sq74npbptqp60jb4p2",
      authDomain: "auth.connectdev.connectedcomms.click",
      authRedirectSignIn: "https://adminportal.connectdev.connectedcomms.click",
      authRedirectSignOut: "https://adminportal.connectdev.connectedcomms.click/signout",
    },
    apiGateway: {
      basePath: "https://api.connectdev.connectedcomms.click/portal/dev",
    },
  },
  framework: {
    archivedRoutingProfilePrefix: "DELETED",
  },
  externalLinks: {
    verint: "https://cen-mmr-v15.cenverpp.centrica.com/wfo",
  },
};

export default dev;
