import { GetState, SetState } from "zustand";
import { BusinessUnit, BusinessUnitsApi } from "../../../api/api";
import { getBUApiInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export type BusinessUnitsStore = {
  buApi: BusinessUnitsApi;
  businessUnits: Array<BusinessUnit>;
  setBusinessUnits: (businessUnits: Array<BusinessUnit>) => void;
  getBusinessUnits: {
    called: boolean;
    loading: boolean;
    error: string | undefined;
    call: () => void;
  };
  currentBusinessUnit: BusinessUnit | undefined;
  setCurrentBusinessUnit: (selectedBusinessUnit: BusinessUnit) => void;
};

export const businessUnitsStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): BusinessUnitsStore => ({
  buApi: getBUApiInstance(),
  businessUnits: [],
  setBusinessUnits: (businessUnits: Array<BusinessUnit>) => {
    const { currentBusinessUnit, setCurrentBusinessUnit } = get();
    if (!businessUnits.find((bu) => bu.Id === currentBusinessUnit?.Id)) {
      setCurrentBusinessUnit(businessUnits[0]);
    }
    set((state) => {
      state.businessUnits = businessUnits;
    });
  },
  getBusinessUnits: {
    called: false,
    loading: false,
    error: undefined,
    call: () => {
      const state = get();
      set((state) => {
        state.getBusinessUnits.loading = true;
        state.getBusinessUnits.error = undefined;
      });
      state.buApi
        .businessunitsGet()
        .then((result) => {
          const businessUnits = result.data.BusinessUnits;
          if (businessUnits && businessUnits.length > 0) {
            const currentBU =
              businessUnits.find((bu) => bu.Id === localStorage.getItem("currentBusinessUnitId")) ||
              businessUnits[0];
            set((state) => {
              state.businessUnits = businessUnits;
              state.currentBusinessUnit = currentBU;
              state.getBusinessUnits.called = true;
              state.getBusinessUnits.loading = false;
            });
            state.getPermissions.call();
          }
        })
        .catch((error) => {
          set((state) => {
            state.getBusinessUnits.error = error;
          });
        });
    },
  },
  currentBusinessUnit: undefined,
  setCurrentBusinessUnit: (selectedBusinessUnit: BusinessUnit): void => {
    localStorage.setItem("currentBusinessUnitId", selectedBusinessUnit.Id);
    window.location.reload();
  },
});
