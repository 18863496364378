import Animate from "@vfuk/core-animate";
import { Grid } from "@vfuk/core-grid";
import SimpleNotification from "@vfuk/core-simple-notification";
import { useServiceWorkerStore } from "hooks/stores";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./SiteUpdatedNotification.module.scss";

const SiteUpdatedNotification = (): JSX.Element => {
  const { t } = useTranslation();
  const [notificationClosed, setNotificationClosed] = useState(false);
  const siteUpdated = useServiceWorkerStore((state) => state.serviceWorkerUpdated);
  const serviceWorkerReg = useServiceWorkerStore((state) => state.serviceWorkerRegistration);

  const updateServiceWorker = () => {
    const swRegWaiting = serviceWorkerReg?.waiting;
    if (swRegWaiting) {
      swRegWaiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  };

  return (
    <Animate
      show={siteUpdated && !notificationClosed}
      enter={{ animations: ["slideFromBottom"], duration: 500 }}
      exit={{ animations: ["slideToBottom"], duration: 500 }}
    >
      <Grid maxWidth="spring" className={classes.NotificationWrapper}>
        <SimpleNotification
          appearance="primary"
          text={t("common:notifications.site_updated.message")}
          link={{
            text: t("common:notifications.site_updated.link"),
            onClick: updateServiceWorker,
          }}
          closeButton={{
            onClick: () => {
              setNotificationClosed(true);
            },
            srText: t("common:notifications.site_updated.close_sr"),
          }}
        />
      </Grid>
    </Animate>
  );
};

export default SiteUpdatedNotification;
