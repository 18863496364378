import {
  CreateQueueRequest,
  ListQueuesHoursOfOperationItem,
  ListQuickConnectsItem,
  QueueDetailResponse,
  QueuesApi,
  QueuesListItem,
  QueueStatus,
  QuickConnectsApi,
  UpdateQueueRequest,
} from "api";
import { getQueuesInstance, getQuickConnectsInstance } from "utils/getApiInstance";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type QueuesStore = {
  queuesApi: QueuesApi;
  quickConnectsApi: QuickConnectsApi;
  getQueues: {
    queues: Array<QueuesListItem>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getQueueDetails: {
    detailedQueues: Array<QueueDetailResponse>;
    loading: boolean;
    call: (queueId: string) => Promise<QueueDetailResponse>;
    clear: () => void;
    refresh: (queueId: string) => void;
  };
  editQueue: {
    call: (queueId: string, queue: UpdateQueueRequest) => Promise<void>;
    loading: boolean;
  };
  editQueueStatus: {
    call: (queueId: string, status: QueueStatus) => Promise<void>;
    loading: boolean;
  };
  addQueue: {
    call: (queue: CreateQueueRequest) => Promise<void>;
    loading: boolean;
  };
  getHoursOfOperation: {
    hoursOfOperations: Array<ListQueuesHoursOfOperationItem>;
    loading: boolean;
    called: boolean;
    error: string;
    call: () => Promise<void>;
  };
  getQuickConnects: {
    quickConnects: Array<ListQuickConnectsItem>;
    loading: boolean;
    called: boolean;
    error: string;
    call: () => Promise<void>;
  };
};

export const queuesStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): QueuesStore => ({
  queuesApi: getQueuesInstance(),
  quickConnectsApi: getQuickConnectsInstance(),
  getQueues: {
    queues: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { queuesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getQueues.loading = true;
        state.getQueues.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No business unit set");
        } else {
          queuesApi
            .businessUnitIdQueuesGet(currentBusinessUnit.Id)
            .then((result) => {
              const queues = result.data.Queues;
              if (queues && queues.length > 0) {
                set((state) => {
                  state.getQueues.queues = queues;
                });
                resolve();
              } else if (!queues) {
                throw new Error("Unexpected response from Queues API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getQueues.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getQueues.loading = false;
                state.getQueues.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getQueues.queues = [];
      });
    },
    refresh: () => {
      get().getQueues.clear();
      get().getQueues.call();
    },
  },
  getQueueDetails: {
    detailedQueues: [],
    loading: false,
    call: (queueId: string) => {
      const { queuesApi, currentBusinessUnit } = get();
      const state = get();
      set((state) => {
        state.getQueueDetails.loading = true;
      });

      return new Promise<QueueDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No business unit set");
        } else {
          queuesApi
            .businessUnitIdQueuesQueueIdGet(queueId, currentBusinessUnit.Id)
            .then((result) => {
              const queueDetails = result.data;
              if (queueDetails) {
                const queueIndex = state.getQueueDetails.detailedQueues.findIndex(
                  (queue) => queue.Id === queueId
                );
                set((state) => {
                  if (queueIndex > -1)
                    state.getQueueDetails.detailedQueues[queueIndex] = queueDetails;
                  else state.getQueueDetails.detailedQueues.push(queueDetails);
                });
                resolve(queueDetails);
              } else if (!queueDetails) {
                throw new Error("Unexpected response from Queues API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getQueueDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getQueueDetails.detailedQueues = [];
      });
    },
    refresh: (queueId: string) => {
      get().getQueueDetails.clear();
      get().getQueueDetails.call(queueId);
    },
  },
  editQueue: {
    loading: false,
    call: (queueId: string, queue: UpdateQueueRequest) => {
      const {
        currentBusinessUnit,
        queuesApi,
        getQueues: { refresh: refreshQueues },
        getQueueDetails: { refresh: refreshQueueDetails },
      } = get();
      set((state) => {
        state.editQueue.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU or agent ID");
        else {
          queuesApi
            .businessUnitIdQueuesQueueIdPut(currentBusinessUnit.Id, queueId, queue)
            .then(() => {
              refreshQueues();
              refreshQueueDetails(queueId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editQueue.loading = false;
              })
            );
        }
      });
    },
  },
  editQueueStatus: {
    loading: false,
    call: (queueId: string, status: QueueStatus) => {
      const {
        currentBusinessUnit,
        queuesApi,
        getQueues: { refresh: refreshQueues },
        getQueueDetails: { refresh: refreshQueueDetails },
      } = get();
      set((state) => {
        state.editQueueStatus.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU or agent ID");
        else {
          queuesApi
            .businessUnitIdQueuesQueueIdStatusPut(currentBusinessUnit.Id, queueId, {
              Status: status,
            })
            .then(() => {
              refreshQueues();
              refreshQueueDetails(queueId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editQueueStatus.loading = false;
              })
            );
        }
      });
    },
  },
  addQueue: {
    loading: false,
    call: (queue: CreateQueueRequest) => {
      const {
        currentBusinessUnit,
        queuesApi,
        getQueues: { refresh: refreshQueues },
      } = get();
      set((state) => {
        state.addQueue.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          queuesApi
            .businessUnitIdQueuesPost(currentBusinessUnit.Id, queue)
            .then(() => {
              refreshQueues();
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addQueue.loading = false;
              })
            );
        }
      });
    },
  },
  getHoursOfOperation: {
    hoursOfOperations: [],
    loading: false,
    called: false,
    error: "",
    call: () => {
      const { queuesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getHoursOfOperation.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No business unit set");
        } else {
          queuesApi
            .businessUnitIdQueuesHoursOfOperationGet(currentBusinessUnit.Id)
            .then((result) => {
              const hoursOfOperation = result.data.HoursOfOperation;
              if (hoursOfOperation.length > 0) {
                set((state) => {
                  state.getHoursOfOperation.hoursOfOperations = hoursOfOperation;
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getHoursOfOperation.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getHoursOfOperation.loading = false;
                state.getHoursOfOperation.called = true;
              });
            });
        }
      });
    },
  },
  getQuickConnects: {
    quickConnects: [],
    loading: false,
    called: false,
    error: "",
    call: () => {
      const { currentBusinessUnit, quickConnectsApi } = get();
      set((state) => {
        state.getQuickConnects.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          quickConnectsApi
            .businessUnitIdQuickConnectsGet(currentBusinessUnit.Id)
            .then((result) => {
              const quickConnects = result.data.QuickConnects;
              if (quickConnects.length > 0) {
                set((state) => {
                  state.getQuickConnects.quickConnects = quickConnects;
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getQuickConnects.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getQuickConnects.loading = false;
                state.getQuickConnects.called = true;
              });
            });
        }
      });
    },
  },
});
