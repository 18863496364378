import Button from "@vfuk/core-button";
import Heading from "@vfuk/core-heading";
import { useAuthStore } from "../../hooks/stores";

const SignOut = (): JSX.Element => {
  const signIn = useAuthStore((state) => state.signIn);

  return (
    <>
      <Heading text="You've signed out" />
      <Button text="Sign back in?" onClick={() => signIn()} />
    </>
  );
};

export default SignOut;
