import Footer from "@vfuk/core-footer";
import { Permissions } from "api";
import NavBar from "components/NavBar/NavBar";
import Splash from "components/Splash/Splash";
import getFooterProps from "config/footer/getFooterProps";
import { usePermissionsStore } from "hooks/stores";
import usePermissions from "hooks/usePermissions/usePermissions";
import { Redirect, Route, RouteProps } from "react-router-dom";
import classes from "./GuardedRoute.module.scss";

type GuardedRouteProps = {
  allow?: boolean;
  authRedirectPath?: string;
  permissions?: Array<Permissions>;
  needSuperUser?: boolean;
  permissionsRedirectPath?: string;
  withNavBar?: boolean;
  withFooter?: boolean;
} & RouteProps;

const GuardedRoute = ({
  allow = true,
  authRedirectPath = "/login",
  permissions,
  needSuperUser,
  permissionsRedirectPath,
  withNavBar,
  withFooter,
  children,
  ...routeProps
}: GuardedRouteProps): JSX.Element => {
  const permissionsFetched = usePermissionsStore((state) => state.getPermissions.called);
  const isSuperUser = usePermissionsStore((state) => state.isSuperUser);
  const { hasAnyPermission } = usePermissions();

  if (allow) {
    if ((permissions || needSuperUser) && !permissionsFetched) {
      return <Splash />;
    }
    if (
      (permissions && permissions.length > 0 && !hasAnyPermission(permissions)) ||
      (needSuperUser && !isSuperUser)
    ) {
      return <Redirect to={{ pathname: permissionsRedirectPath }} />;
    }
    return (
      <Route {...routeProps}>
        <div className={withFooter ? classes.MinHeight : undefined}>
          {withNavBar && <NavBar />}
          {children}
        </div>
        {withFooter && <Footer {...getFooterProps()} />}
      </Route>
    );
  }
  return <Redirect to={{ pathname: authRedirectPath }} />;
};

export default GuardedRoute;
