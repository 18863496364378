import axios from "axios";
import { useEffect } from "react";
import { useAuthStore } from "../stores";

const useInterceptors = (): void => {
  const token = useAuthStore((state) => state.token);
  const validateSession = useAuthStore((state) => state.validateSession);

  // Add the auth token to the default axios headers each time it changes
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    }
  }, [token]);

  useEffect(() => {
    // Refresh token if it's expired
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;

        if (
          error.response &&
          error.response.status &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          return validateSession().then((session) => {
            const newToken = session.getIdToken().getJwtToken();
            originalRequest.headers["Authorization"] = newToken;
            return axios(originalRequest);
          });
        }

        return Promise.reject(error);
      }
    );
  }, []);
};

export default useInterceptors;
