import { GetState, SetState } from "zustand";
import {
  OperationalHoursApi,
  OperationalHoursDetailResponse,
  OperationalHoursRequest,
  OperationalHoursSummaryItem,
} from "../../../api";
import { getOperatingHoursInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export type OperatingHoursStore = {
  operatingHoursApi: OperationalHoursApi;
  operatingHours: Array<OperationalHoursSummaryItem | OperationalHoursDetailResponse>;
  getOperatingHours: {
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getOperatingHoursTemplateDetails: {
    loading: boolean;
    call: (operatingHoursId: string) => Promise<OperationalHoursDetailResponse>;
  };
  addOperatingHoursTemplate: {
    loading: boolean;
    call: (newTemplate: OperationalHoursRequest) => Promise<void>;
  };
  editOperatingHoursTemplate: {
    loading: boolean;
    call: (templateId: string, editedTemplate: OperationalHoursRequest) => Promise<void>;
  };
  selectedTemplate: OperationalHoursSummaryItem | OperationalHoursDetailResponse | undefined;
  addSelectedTemplate: (
    template: OperationalHoursSummaryItem | OperationalHoursDetailResponse
  ) => void;
  deselectTemplate: () => void;
  deleteOperatingHoursTemplate: {
    call: () => Promise<void>;
    loading: boolean;
  };
};

export const operatingHoursStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): OperatingHoursStore => ({
  operatingHoursApi: getOperatingHoursInstance(),
  operatingHours: [],
  selectedTemplate: undefined,
  getOperatingHours: {
    loading: false,
    called: false,
    error: "",
    call: () => {
      const { currentBusinessUnit, operatingHoursApi } = get();
      set((state) => {
        state.getOperatingHours.loading = true;
        state.getOperatingHours.error = "";
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          operatingHoursApi
            .businessUnitIdOperationalhoursGet(currentBusinessUnit.Id)
            .then((result) => {
              const operatingHours = result.data.OperationalHoursSummary;
              if (operatingHours && operatingHours.length > 0) {
                set((state) => {
                  state.operatingHours = operatingHours;
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getOperatingHours.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getOperatingHours.loading = false;
                state.getOperatingHours.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set({ operatingHours: [] });
    },
    refresh: () => {
      set((state) => {
        state.getOperatingHours.called = false;
      });
      get().getOperatingHours.call();
    },
  },
  getOperatingHoursTemplateDetails: {
    loading: false,
    call: (operatingHoursId: string) => {
      const { currentBusinessUnit, operatingHours, operatingHoursApi } = get();
      set((state) => {
        state.getOperatingHoursTemplateDetails.loading = true;
      });
      return new Promise<OperationalHoursDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit || !operatingHoursId) {
          reject();
        } else {
          operatingHoursApi
            .businessUnitIdOperationalhoursOperationalHoursIdGet(
              currentBusinessUnit.Id,
              operatingHoursId
            )
            .then((result) => {
              const operatingHourDetails = result.data;
              if (operatingHourDetails) {
                const templateIndex = operatingHours.findIndex(
                  (item) => item.Id === operatingHoursId
                );
                set((state) => {
                  if (templateIndex >= 0)
                    state.operatingHours[templateIndex] = operatingHourDetails;
                  else state.operatingHours.push(operatingHourDetails);
                });
                resolve(operatingHourDetails);
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getOperatingHoursTemplateDetails.loading = false;
              });
            });
        }
      });
    },
  },
  addOperatingHoursTemplate: {
    loading: false,
    call: (newTemplate: OperationalHoursRequest) => {
      const { currentBusinessUnit, operatingHoursApi } = get();
      set((state) => void (state.addOperatingHoursTemplate.loading = true));
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          operatingHoursApi
            .businessUnitIdOperationalhoursPost(currentBusinessUnit.Id, newTemplate)
            .then((response) => {
              const template = response.data;
              set((state) => {
                state.operatingHours.push(template);
              });
              resolve();
            })
            .catch(reject)
            .finally(() => set((state) => void (state.addOperatingHoursTemplate.loading = false)));
        }
      });
    },
  },
  editOperatingHoursTemplate: {
    loading: false,
    call: (templateId: string, editedTemplate: OperationalHoursRequest) => {
      const { currentBusinessUnit, operatingHoursApi } = get();
      set((state) => void (state.editOperatingHoursTemplate.loading = true));
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          operatingHoursApi
            .businessUnitIdOperationalhoursOperationalHoursIdPut(
              currentBusinessUnit.Id,
              templateId,
              editedTemplate
            )
            .then((response) => {
              const template = response.data;
              set((state) => {
                const templateIndex = state.operatingHours.findIndex(
                  (template) => template.Id === templateId
                );
                if (templateIndex > -1) state.operatingHours[templateIndex] = template;
                else state.operatingHours.push(template);
              });
              resolve();
            })
            .catch(reject)
            .finally(() => set((state) => void (state.editOperatingHoursTemplate.loading = false)));
        }
      });
    },
  },
  addSelectedTemplate: (template: OperationalHoursSummaryItem) => {
    set((state) => {
      state.selectedTemplate = template;
    });
  },
  deselectTemplate: () => {
    set((state) => {
      state.selectedTemplate = undefined;
    });
  },
  deleteOperatingHoursTemplate: {
    loading: false,
    call: () => {
      const state = get();
      const { currentBusinessUnit, operatingHoursApi } = get();
      set((state) => void (state.deleteOperatingHoursTemplate.loading = true));
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit || !state.selectedTemplate) reject("No BU or selected template");
        else {
          operatingHoursApi
            .businessUnitIdOperationalhoursOperationalHoursIdDelete(
              currentBusinessUnit.Id,
              state.selectedTemplate.Id
            )
            .then(() => {
              set((state) => {
                const trimmedTemplates = state.operatingHours.filter(
                  (template) => template.Id !== state.selectedTemplate?.Id
                );
                state.operatingHours = trimmedTemplates;
                state.selectedTemplate = undefined;
              });
              resolve();
            })
            .catch(reject)
            .finally(() =>
              set((state) => void (state.deleteOperatingHoursTemplate.loading = false))
            );
        }
      });
    },
  },
});
