import { AccessTypeEnum, Permissions, PermissionTypeEnum } from "../api";

export const getAllowedAccessTypes = (
  accessType: AccessTypeEnum | undefined
): Array<AccessTypeEnum> => {
  const n = AccessTypeEnum.None;
  const v = AccessTypeEnum.View;
  const e = AccessTypeEnum.Edit;
  const f = AccessTypeEnum.Full;

  if (accessType === AccessTypeEnum.None) return [n, v, e, f];
  else if (accessType === AccessTypeEnum.View) return [v, e, f];
  else if (accessType === AccessTypeEnum.Edit) return [e, f];
  else if (accessType === AccessTypeEnum.Full) return [f];
  else return [];
};

// Loop through the permission type enum and generate an array of permissions
// all set to None.
export const getDefaultNewRolePermissions = (): Array<Permissions> => {
  return Object.values(PermissionTypeEnum)
    .map((permission) => {
      return { Type: permission as PermissionTypeEnum, Access: AccessTypeEnum.None };
    })
    .sort((a, b) => (a.Type > b.Type ? 1 : b.Type > a.Type ? -1 : 0));
};
