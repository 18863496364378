const routes = {
  login: "/login",
  dashboard: "/",
  contactFlows: {
    list: `/flow-configuration/contact-flows`,
    add: `/flow-configuration/contact-flows/add`,
    edit: (id: string): string => `/flow-configuration/contact-flows/${id}/edit`,
    detail: (id: string): string => `/flow-configuration/contact-flows/${id}`,
  },
  operatingHoursTemplates: {
    list: `/flow-configuration/operating-hours`,
    add: `/flow-configuration/operating-hours/add`,
    edit: (id: string): string => `/flow-configuration/operating-hours/${id}/edit`,
    detail: (id: string): string => `/flow-configuration/operating-hours/${id}`,
  },
  ingressInstance: {
    list: `/flow-configuration/epa/ingress-instances`,
    add: `/flow-configuration/epa/ingress-instances/add`,
    edit: (id: string): string => `/flow-configuration/epa/ingress-instances/${id}/edit`,
    detail: (id: string): string => `/flow-configuration/epa/ingress-instances/${id}/`,
  },
  ingressTemplate: {
    list: `/flow-configuration/epa/ingress-templates`,
    detail: (id: string): string => `/flow-configuration/epa/ingress-templates/${id}/`,
  },
  agents: {
    list: `/manage-users/user-configuration/agents`,
    add: `/manage-users/user-configuration/agents/add`,
    edit: (id: string): string => `/manage-users/user-configuration/agents/${id}/edit`,
    detail: (id: string): string => `/manage-users/user-configuration/agents/${id}`,
  },
  portalUsers: {
    list: `/manage-users/user-configuration/users`,
    add: `/manage-users/user-configuration/users/add`,
  },
  roles: {
    list: `/manage-users/user-configuration/roles`,
  },
  routingProfiles: {
    list: `/manage-users/routing-management/routing-profiles`,
    add: `/manage-users/routing-management/routing-profiles/add`,
    edit: (id: string): string => `/manage-users/routing-management/routing-profiles/${id}/edit`,
    detail: (id: string): string => `/manage-users/routing-management/routing-profiles/${id}`,
  },
  queues: {
    list: `/manage-users/routing-management/queues`,
    add: `/manage-users/routing-management/queues/add`,
    edit: (id: string): string => `/manage-users/routing-management/queues/${id}/edit`,
    detail: (id: string): string => `/manage-users/routing-management/queues/${id}`,
  },
  flowConfigTemplates: {
    list: `/flow-configuration/configuration/config-templates`,
    add: `/flow-configuration/configuration/config-templates/add`,
    edit: (id: string): string => `/flow-configuration/configuration/config-templates/${id}/edit`,
    details: (id: string): string => `/flow-configuration/configuration/config-templates/${id}`,
  },
  flowConfig: {
    list: `/flow-configuration/configuration/flow-config`,
    add: `/flow-configuration/configuration/flow-config/add`,
    edit: (id: string): string => `/flow-configuration/configuration/flow-config/${id}/edit`,
    details: (id: string): string => `/flow-configuration/configuration/flow-config/${id}`,
  },
  egressTemplate: {
    list: `/flow-configuration/queue-flow-admin/queue-flow-templates`,
    detail: (id: string): string =>
      `/flow-configuration/queue-flow-admin/queue-flow-templates/${id}/`,
  },
  egressInstance: {
    list: `/flow-configuration/queue-flow-admin/queue-flow-instances`,
    add: `/flow-configuration/queue-flow-admin/queue-flow-instances/add`,
    edit: (id: string): string =>
      `/flow-configuration/queue-flow-admin/queue-flow-instances/${id}/edit`,
    detail: (id: string): string =>
      `/flow-configuration/queue-flow-admin/queue-flow-instances/${id}/`,
  },
  auditLogs: `/audit-logs`,
};

export default routes;
