import LoadingSpinner from "@vfuk/core-loading-spinner";
import classes from "./CenteredLoadingSpinner.module.scss";

const CenteredLoadingSpinner = (): JSX.Element => {
  return (
    <div className={classes.Loading} data-testid="loading-spinner">
      <LoadingSpinner size={5} />
    </div>
  );
};

export default CenteredLoadingSpinner;
