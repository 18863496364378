import { saveAs } from "file-saver";

const downloadAuditReport = (base64Response: string): void => {
  const binaryString = atob(base64Response);
  const binLength = binaryString.length;
  const bytes = new Uint8Array(binLength);
  for (let i = 0; i < binLength; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes.buffer], {
    type: "application/vnd.ms-excel;charset=utf-8",
  });
  saveAs(blob, "audit-log.xlsx");
};

export default downloadAuditReport;
