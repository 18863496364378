import Button from "@vfuk/core-button/dist/Button";
import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import { ReactComponent as FailureWarning } from "assets/svg/failure_warning.svg";
import { useTranslation } from "react-i18next";
import classes from "./FailureModalContent.module.scss";

export type FailureModalContentProps = {
  heading: string;
  body: string;
  onClose: () => void;
  onRetry?: () => void;
  closeMessage?: string;
  retryMessage?: string;
  loading?: boolean;
};

const FailureModalContent = (props: FailureModalContentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classes.FailureModalContent} role="dialog">
      <FailureWarning />
      <Heading size={2} weight={3}>
        {props.heading}
      </Heading>
      <Paragraph>{props.body}</Paragraph>
      <br />
      <div className={classes.Buttons}>
        <div className={classes.Button}>
          <Button
            width="full"
            appearance="alt1"
            text={props.closeMessage || t("common:modals.close")}
            onClick={props.onClose}
          />
        </div>
        {props.onRetry && (
          <div className={classes.Button}>
            <Button
              width="full"
              text={props.retryMessage || t("common:modals.retry")}
              onClick={props.onRetry}
              loading={props.loading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FailureModalContent;
