import { NavigationalTabs, Tab } from "@vfuk/core-navigational-tabs";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { match, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import classes from "./RoutingTabs.module.scss";

type RoutingTabsProps = {
  tabs: Array<{ name: string; route: string; visible: boolean; overrideClick?: () => void }>;
  className?: string;
};

// Here be dragons...
const RoutingTabs = ({ tabs, className }: RoutingTabsProps): JSX.Element => {
  // Quirk of the Tabs component; in order to select no tab, you must pass a string not matching one of the tab ids
  const [activeTab, setActiveTab] = useState("no match");
  const [initialTabSet, setInitialTabSet] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const matchers: (match | null)[] = [];

  tabs.forEach((tab) => {
    const matcher = useRouteMatch(tab.route);
    matchers.push(matcher);
  });

  // Set intial tab based on current route
  useEffect(() => {
    if (location.pathname !== activeTab) {
      let matchFound = false;
      matchers.forEach((routeActive, matcherNum) => {
        if (routeActive) {
          if (routeActive.url === "/") {
            if (routeActive.isExact) matchFound = true;
          } else matchFound = true;
          if (matchFound) {
            setActiveTab(tabs[matcherNum].route);
          }
        }
      });
      if (!matchFound) {
        setActiveTab("no match");
      }
    }
  }, [location]);

  // Another quirk of this NavigationalTabs component. It triggers the tab click callback every time the active tab is changed programatically.
  // This can cause strange routing behaviour given that we want to reroute on tab click.
  // Fixed this by tracking previous tab click and only rerouting if there is a previous tab click and it's different
  // There is still a problem where the overrideClick callback will trigger when a tab is changed programatically
  // e.g. via a Quick Link. I haven't been able to come up with a way of resolving this withough overhauling the navbar without
  // using the VF library NavigationalTabs component
  const handleTabClick = (tabId: string) => {
    const tab = tabs.find((tab) => tab.route === tabId);
    if (tab?.overrideClick && initialTabSet) tab.overrideClick();
    else if (initialTabSet && activeTab !== tabId) {
      if (tabId !== "no match") history.push(tabId);
    }
    setInitialTabSet(true);
  };

  const renderedTabs = useMemo(() => {
    return tabs.filter((tab) => tab.visible);
  }, [tabs]);

  return (
    <div className={classNames(className, classes.Background)}>
      <NavigationalTabs width="auto" externallySetTabId={activeTab} onTabClick={handleTabClick}>
        {renderedTabs.map((tab) => (
          <Tab key={tab.name} text={tab.name} id={tab.route} />
        ))}
      </NavigationalTabs>
    </div>
  );
};

export default RoutingTabs;
