import Auth from "@aws-amplify/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type AuthStore = {
  authenticated: boolean;
  token: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  signIn: () => Promise<void>;
  signOut: () => void;
  validateSession: () => Promise<CognitoUserSession>;
};

export const authStore = (set: SetState<CombinedStore>): AuthStore => ({
  authenticated: false,
  token: undefined,
  firstName: undefined,
  lastName: undefined,
  signIn: (): Promise<void> => {
    return new Promise((resolve, reject) => {
      Auth.federatedSignIn({ customProvider: "Centrica" })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signOut: (): void => {
    Auth.signOut()
      .then(() => {
        set({
          authenticated: false,
          firstName: undefined,
          lastName: undefined,
          token: undefined,
        });
      })
      .catch((error) => {
        console.log("error signing out: ", error);
      });
  },
  validateSession: (): Promise<CognitoUserSession> => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((currentAuthUser) => {
          Auth.userSession(currentAuthUser).then((session) => {
            if (session.isValid()) {
              const token = session.getIdToken().getJwtToken();
              const firstName = currentAuthUser.attributes.given_name;
              const lastName = currentAuthUser.attributes.family_name;
              set({
                authenticated: true,
                token: token,
                firstName: firstName,
                lastName: lastName,
              });
              resolve(session);
            } else {
              reject();
            }
          });
        })
        .catch(() => {
          reject();
        });
    });
  },
});
