import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  commonTranslationEN,
  dashboardTranslationEN,
  egressTranslationEN,
  epaTranslationEN,
  flowConfigTranslationEN,
  loginTranslationEN,
  operatingHoursTranslationEN,
  routingManagementTranslationEN,
  userConfigTranslationEN,
  validationTranslationEN,
} from "./localization";

const resources = {
  en: {
    common: commonTranslationEN,
    validation: validationTranslationEN,
    login: loginTranslationEN,
    dashboard: dashboardTranslationEN,
    users: userConfigTranslationEN,
    operatinghours: operatingHoursTranslationEN,
    routing: routingManagementTranslationEN,
    flowconfig: flowConfigTranslationEN,
    epa: epaTranslationEN,
    egress: egressTranslationEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    defaultNS: "common",
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
