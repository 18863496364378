import {
  AttributeValueItem,
  CreateFlowConfig,
  CreateFlowTemplate,
  DescribeFlowConfig,
  DescribeFlowTemplate,
  FlowAttributesApi,
  FlowConfigApi,
  FlowTemplatesApi,
  ListFlowAttribute,
  ListFlowConfigItem,
  ListFlowTemplateItem,
  UpdateFlowConfig,
  UpdateFlowTemplate,
} from "api";
import { ContactFlowAttributeType } from "config/attributeTypes/attributeTypes";
import {
  getFlowAttributesInstance,
  getFlowConfigsInstance,
  getFlowTemplatesInstance,
} from "utils/getApiInstance";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type FlowConfigStore = {
  flowTemplatesApi: FlowTemplatesApi;
  flowAttributesApi: FlowAttributesApi;
  flowConfigsApi: FlowConfigApi;
  getFlowTemplates: {
    flowTemplates: Array<ListFlowTemplateItem>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getFlowTemplateDetails: {
    detailedFlowTemplates: Array<DescribeFlowTemplate>;
    loading: boolean;
    call: (flowTemplateId: string) => Promise<DescribeFlowTemplate>;
    clear: () => void;
    refresh: (flowTemplateId: string) => void;
  };
  editFlowTemplate: {
    loading: boolean;
    call: (flowTemplateId: string, flowTemplate: UpdateFlowTemplate) => Promise<void>;
  };
  addFlowTemplate: {
    loading: boolean;
    call: (flowTemplate: CreateFlowTemplate) => Promise<void>;
  };
  deleteFlowTemplate: {
    loading: boolean;
    call: (flowTemplateId: string) => Promise<void>;
  };
  getFlowAttributeTypes: {
    flowAttributeTypes: Array<ListFlowAttribute>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getFlowAttributeTypeValues: {
    flowAttributeTypeValues: { [key: string]: Array<AttributeValueItem> };
    loading: { [key: string]: boolean };
    error: { [key: string]: string };
    call: (attributeType: string) => void;
    clear: (attributeType?: string) => void;
  };
  getUnassignedControlFlows: {
    controlFlows: Array<AttributeValueItem>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getFlowConfigs: {
    flowConfigs: Array<ListFlowConfigItem>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getFlowConfigDetails: {
    detailedFlowConfigs: Array<DescribeFlowConfig>;
    loading: boolean;
    call: (flowConfigId: string) => Promise<DescribeFlowConfig>;
    clear: () => void;
    refresh: (flowConfigId: string) => void;
  };
  editFlowConfig: {
    loading: boolean;
    call: (flowConfigId: string, flowConfig: UpdateFlowConfig) => Promise<void>;
  };
  addFlowConfig: {
    loading: boolean;
    call: (flowConfig: CreateFlowConfig) => Promise<void>;
  };
  deleteFlowConfig: {
    loading: boolean;
    call: (flowConfigId: string) => Promise<void>;
  };
};

export const flowConfigStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): FlowConfigStore => ({
  flowTemplatesApi: getFlowTemplatesInstance(),
  flowAttributesApi: getFlowAttributesInstance(),
  flowConfigsApi: getFlowConfigsInstance(),
  getFlowTemplates: {
    flowTemplates: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { flowTemplatesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getFlowTemplates.loading = true;
        state.getFlowTemplates.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          flowTemplatesApi
            .businessUnitIdFlowTemplatesGet(currentBusinessUnit.Id)
            .then((result) => {
              const flowTemplates = result.data;
              if (flowTemplates && flowTemplates.length > 0) {
                set((state) => {
                  state.getFlowTemplates.flowTemplates = flowTemplates;
                });
                resolve();
              } else if (!flowTemplates) {
                throw new Error("Unexpected response from Flow Templates API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getFlowTemplates.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getFlowTemplates.loading = false;
                state.getFlowTemplates.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getFlowTemplates.flowTemplates = [];
        state.getFlowTemplates.called = false;
      });
    },
    refresh: () => {
      get().getFlowTemplates.clear();
      get().getFlowTemplates.call();
    },
  },
  getFlowTemplateDetails: {
    detailedFlowTemplates: [],
    loading: false,
    call: (flowTemplateId: string) => {
      const state = get();
      const { currentBusinessUnit, flowTemplatesApi } = get();
      set((state) => {
        state.getFlowTemplateDetails.loading = true;
      });
      return new Promise<DescribeFlowTemplate>((resolve, reject) => {
        if (!currentBusinessUnit || !flowTemplateId) {
          reject();
        } else {
          flowTemplatesApi
            .businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId, currentBusinessUnit.Id)
            .then((result) => {
              const flowTempDetails = result.data;
              if (flowTempDetails) {
                const flowTemplateIndex =
                  state.getFlowTemplateDetails.detailedFlowTemplates.findIndex(
                    (flowTemp) => flowTemp.Id === flowTemplateId
                  );
                set((state) => {
                  if (flowTemplateIndex > -1)
                    state.getFlowTemplateDetails.detailedFlowTemplates[flowTemplateIndex] =
                      flowTempDetails;
                  else state.getFlowTemplateDetails.detailedFlowTemplates.push(flowTempDetails);
                });
                resolve(flowTempDetails);
              } else if (!flowTempDetails) {
                throw new Error("Unexpected response from Flow Templates API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getFlowTemplateDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getFlowTemplateDetails.detailedFlowTemplates = [];
      });
    },
    refresh: (flowTemplateId: string) => {
      get().getFlowTemplateDetails.clear();
      get().getFlowTemplateDetails.call(flowTemplateId);
    },
  },
  editFlowTemplate: {
    loading: false,
    call: (flowTemplateId: string, flowTemplate: UpdateFlowTemplate) => {
      const {
        currentBusinessUnit,
        flowTemplatesApi,
        getFlowTemplates: { refresh: refreshFlowTemplates },
        getFlowTemplateDetails: { refresh: refreshFlowTempDetails },
        getFlowConfigDetails: { clear: clearFlowConfigDetails },
      } = get();
      set((state) => {
        state.editFlowTemplate.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit || !flowTemplateId) reject();
        else {
          flowTemplatesApi
            .businessUnitIdFlowTemplatesFlowTemplateIdPut(
              flowTemplateId,
              currentBusinessUnit.Id,
              flowTemplate
            )
            .then(() => {
              refreshFlowTemplates();
              refreshFlowTempDetails(flowTemplateId);
              clearFlowConfigDetails();
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editFlowTemplate.loading = false;
              })
            );
        }
      });
    },
  },
  addFlowTemplate: {
    loading: false,
    call: (flowTemplate: CreateFlowTemplate) => {
      const {
        currentBusinessUnit,
        flowTemplatesApi,
        getFlowTemplates: { refresh: refreshFlowTemplates },
        getUnassignedControlFlows: { clear: clearUnassignedControlFlows },
        getFlowAttributeTypeValues: { clear: clearAttributeTypeValues },
      } = get();
      set((state) => {
        state.addFlowTemplate.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          flowTemplatesApi
            .businessUnitIdFlowTemplatesPost(currentBusinessUnit.Id, flowTemplate)
            .then(() => {
              refreshFlowTemplates();
              clearUnassignedControlFlows();
              clearAttributeTypeValues(ContactFlowAttributeType);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addFlowTemplate.loading = false;
              })
            );
        }
      });
    },
  },
  deleteFlowTemplate: {
    loading: false,
    call: (flowTemplateId: string) => {
      const {
        currentBusinessUnit,
        flowTemplatesApi,
        getFlowTemplates: { refresh: refreshFlowConfigTemplates },
        getFlowTemplateDetails: { clear: clearFlowTemplateDetails },
        getUnassignedControlFlows: { clear: clearUnassignedContactFlows },
        getFlowAttributeTypeValues: { clear: clearFlowAttributeTypeValues },
      } = get();
      set((state) => {
        state.deleteFlowTemplate.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          flowTemplatesApi
            .businessUnitIdFlowTemplatesFlowTemplateIdDelete(currentBusinessUnit.Id, flowTemplateId)
            .then(() => {
              refreshFlowConfigTemplates();
              clearFlowTemplateDetails();
              clearUnassignedContactFlows();
              clearFlowAttributeTypeValues(ContactFlowAttributeType);
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.deleteFlowTemplate.loading = false;
              });
            });
        }
      });
    },
  },
  getFlowAttributeTypes: {
    flowAttributeTypes: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { flowAttributesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getFlowAttributeTypes.loading = true;
        state.getFlowAttributeTypes.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          flowAttributesApi
            .businessUnitIdFlowAttributesGet(currentBusinessUnit.Id)
            .then((result) => {
              const attributes = result.data;
              if (attributes && attributes.length > 0) {
                set((state) => {
                  state.getFlowAttributeTypes.flowAttributeTypes = attributes;
                });
                resolve();
              } else if (!attributes) {
                throw new Error("Unexpected response from Flow Attributes API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getFlowAttributeTypes.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getFlowAttributeTypes.loading = false;
                state.getFlowAttributeTypes.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getFlowAttributeTypes.flowAttributeTypes = [];
        state.getFlowAttributeTypes.called = false;
      });
    },
    refresh: () => {
      get().getFlowAttributeTypes.clear();
      get().getFlowAttributeTypes.call();
    },
  },
  getFlowAttributeTypeValues: {
    flowAttributeTypeValues: {},
    loading: {},
    error: {},
    call: (attributeType: string) => {
      const { flowAttributesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getFlowAttributeTypeValues.loading[attributeType] = true;
        state.getFlowAttributeTypeValues.error[attributeType] = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          flowAttributesApi
            .getAttributeValues(currentBusinessUnit.Id, attributeType)
            .then((result) => {
              const attributeValues = result.data;
              if (attributeValues && attributeValues.length > 0) {
                set((state) => {
                  state.getFlowAttributeTypeValues.flowAttributeTypeValues[attributeType] =
                    attributeValues;
                });
                resolve();
              } else if (!attributeValues) {
                throw new Error("Unexpected response from Flow Attributes API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getFlowAttributeTypeValues.error[attributeType] = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getFlowAttributeTypeValues.loading[attributeType] = false;
              });
            });
        }
      });
    },
    clear: (attributeType?: string) => {
      set((state) => {
        if (attributeType) {
          delete state.getFlowAttributeTypeValues.flowAttributeTypeValues[attributeType];
        } else {
          state.getFlowAttributeTypeValues.flowAttributeTypeValues = {};
        }
      });
    },
  },
  getUnassignedControlFlows: {
    controlFlows: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { flowAttributesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getUnassignedControlFlows.loading = true;
        state.getUnassignedControlFlows.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          flowAttributesApi
            .getUnassignedAttributeValues(currentBusinessUnit.Id, ContactFlowAttributeType)
            .then((result) => {
              const controlFlows = result.data;
              if (controlFlows && controlFlows.length > 0) {
                set((state) => {
                  state.getUnassignedControlFlows.controlFlows = controlFlows;
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getUnassignedControlFlows.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getUnassignedControlFlows.loading = false;
                state.getUnassignedControlFlows.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getUnassignedControlFlows.controlFlows = [];
        state.getUnassignedControlFlows.called = false;
      });
    },
    refresh: () => {
      get().getUnassignedControlFlows.clear();
      get().getUnassignedControlFlows.call();
    },
  },
  getFlowConfigs: {
    flowConfigs: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { flowConfigsApi, currentBusinessUnit } = get();
      set((state) => {
        state.getFlowConfigs.loading = true;
        state.getFlowConfigs.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          flowConfigsApi
            .businessUnitIdFlowConfigGet(currentBusinessUnit.Id)
            .then((result) => {
              const flowConfigs = result.data;
              if (flowConfigs && flowConfigs.length > 0) {
                set((state) => {
                  state.getFlowConfigs.flowConfigs = flowConfigs;
                });
                resolve();
              } else if (!flowConfigs) {
                throw new Error("Unexpected response from Flow Configs API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getFlowConfigs.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getFlowConfigs.loading = false;
                state.getFlowConfigs.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getFlowConfigs.flowConfigs = [];
        state.getFlowConfigs.called = false;
      });
    },
    refresh: () => {
      get().getFlowConfigs.clear();
      get().getFlowConfigs.call();
    },
  },
  getFlowConfigDetails: {
    detailedFlowConfigs: [],
    loading: false,
    call: (flowConfigId: string) => {
      const state = get();
      const { currentBusinessUnit, flowConfigsApi } = get();
      set((state) => {
        state.getFlowConfigDetails.loading = true;
      });
      return new Promise<DescribeFlowConfig>((resolve, reject) => {
        if (!currentBusinessUnit || !flowConfigId) {
          reject();
        } else {
          flowConfigsApi
            .businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId, currentBusinessUnit.Id)
            .then((result) => {
              const flowConfigDetails = result.data;
              if (flowConfigDetails) {
                const flowConfigIndex = state.getFlowConfigDetails.detailedFlowConfigs.findIndex(
                  (flowConfig) => flowConfig.Configuration === flowConfigId /////////
                );
                set((state) => {
                  if (flowConfigIndex > -1)
                    state.getFlowConfigDetails.detailedFlowConfigs[flowConfigIndex] =
                      flowConfigDetails;
                  else state.getFlowConfigDetails.detailedFlowConfigs.push(flowConfigDetails);
                });
                resolve(flowConfigDetails);
              } else if (!flowConfigDetails) {
                throw new Error("Unexpected response from Flow Configs API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getFlowConfigDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getFlowConfigDetails.detailedFlowConfigs = [];
      });
    },
    refresh: (flowConfigId: string) => {
      get().getFlowConfigDetails.clear();
      get().getFlowConfigDetails.call(flowConfigId);
    },
  },
  editFlowConfig: {
    loading: false,
    call: (flowConfigId: string, flowConfig: UpdateFlowConfig) => {
      const {
        currentBusinessUnit,
        flowConfigsApi,
        getFlowConfigs: { refresh: refreshFlowConfigs },
        getFlowConfigDetails: { refresh: refreshFlowConfigDetails },
      } = get();
      set((state) => {
        state.editFlowConfig.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit || !flowConfigId) reject();
        else {
          flowConfigsApi
            .businessUnitIdFlowConfigFlowConfigIdPut(
              flowConfigId,
              currentBusinessUnit.Id,
              flowConfig
            )
            .then(() => {
              refreshFlowConfigs();
              refreshFlowConfigDetails(flowConfigId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editFlowConfig.loading = false;
              })
            );
        }
      });
    },
  },
  addFlowConfig: {
    loading: false,
    call: (flowConfigs: CreateFlowConfig) => {
      const {
        currentBusinessUnit,
        flowConfigsApi,
        getFlowConfigs: { refresh: refreshFlowConfigs },
        getFlowTemplates: { clear: clearFlowTemplates },
        getFlowAttributeTypeValues: { clear: clearAttributeTypeValues },
      } = get();
      set((state) => {
        state.addFlowConfig.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          flowConfigsApi
            .businessUnitIdFlowConfigPost(currentBusinessUnit.Id, flowConfigs)
            .then(() => {
              refreshFlowConfigs();
              clearFlowTemplates();
              clearAttributeTypeValues(ContactFlowAttributeType);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addFlowConfig.loading = false;
              })
            );
        }
      });
    },
  },
  deleteFlowConfig: {
    loading: false,
    call: (flowConfigId: string) => {
      const { currentBusinessUnit, flowConfigsApi } = get();
      set((state) => {
        state.deleteFlowConfig.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          flowConfigsApi
            .businessUnitIdFlowConfigFlowConfigIdDelete(currentBusinessUnit.Id, flowConfigId)
            .then(() => {
              set((state) => {
                state.getFlowConfigs.flowConfigs = state.getFlowConfigs.flowConfigs.filter(
                  (flowConfig) => flowConfig.Configuration !== flowConfigId
                );
                state.getFlowConfigDetails.detailedFlowConfigs =
                  state.getFlowConfigDetails.detailedFlowConfigs.filter(
                    (flowConfig) => flowConfig.Configuration !== flowConfigId
                  );
              });
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.deleteFlowConfig.loading = false;
              });
            });
        }
      });
    },
  },
});
