import { Auth } from "@aws-amplify/auth";
import { useServiceWorkerStore } from "hooks/stores";
import "normalize.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CognitoConfig from "./config/amplify/cognito-config";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./styles/global.scss";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const port = isLocalhost ? window.location.href.split("/")[2].split(":")[1] : "";

const updatedCognitoConfig = {
  ...CognitoConfig,
  oauth: {
    ...CognitoConfig.oauth,
    redirectSignIn: isLocalhost ? `http://localhost:${port}` : CognitoConfig.oauth.redirectSignIn,
    redirectSignOut: isLocalhost
      ? `http://localhost:${port}/SignOut`
      : CognitoConfig.oauth.redirectSignOut,
  },
};

Auth.configure(updatedCognitoConfig);

serviceWorkerRegistration.register({
  onSuccess: () => {
    useServiceWorkerStore.setState({ serviceWorkerInitialized: true });
  },
  onUpdate: (reg: ServiceWorkerRegistration) => {
    useServiceWorkerStore.setState({ serviceWorkerRegistration: reg, serviceWorkerUpdated: true });
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
