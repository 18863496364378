export type ServiceWorkerStore = {
  serviceWorkerInitialized: boolean;
  serviceWorkerUpdated: boolean;
  serviceWorkerRegistration: ServiceWorkerRegistration | undefined;
};

export const serviceWorkerStore = (): ServiceWorkerStore => ({
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: undefined,
});
