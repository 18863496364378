import dev from "./dev";
import prod from "./prod";
import stg from "./stg";
import test from "./test";

const environment = () => {
  if (process.env.NODE_ENV === "test") return test;
  switch (process.env.REACT_APP_STAGE) {
    case "prod":
      return prod;
    case "stg":
      return stg;
    case "dev":
    default:
      return dev;
  }
};

export default environment();
