import usePermissions from "hooks/usePermissions/usePermissions";
import { ReactNode } from "react";
import { Permissions } from "../../../api";

type HasAnyPermissionProps = {
  permissions: Array<Permissions>;
  children: ReactNode;
};

const HasAnyPermission = ({ permissions, children }: HasAnyPermissionProps): JSX.Element => {
  const { hasAnyPermission } = usePermissions();

  return <>{hasAnyPermission(permissions) && children}</>;
};

export default HasAnyPermission;
