import { GetState, SetState } from "zustand";
import { User, UsersApi } from "../../../api";
import { getUsersApiInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export type UserDetailsStore = {
  usersApi: UsersApi;
  users: Array<User>;
  selectedUsers: Array<User>;
  getUsers: {
    loading: boolean;
    fetched: boolean;
    error: string;
    call: () => void;
  };
  addUser: {
    loading: boolean;
    call: (user: User) => Promise<void>;
  };
  editUser: {
    loading: boolean;
    call: (user: User) => Promise<void>;
  };
  deleteUsers: {
    loading: boolean;
    call: () => Promise<Array<User>>;
  };
  refreshUsers: () => void;
  addSelectedUser: (user: User) => void;
  deselectUser: (user: User) => void;
  clearUsers: () => void;
  clearAllSelectedUsers: () => void;
};

export const userDetailsStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): UserDetailsStore => ({
  usersApi: getUsersApiInstance(),
  users: [],
  selectedUsers: [],
  getUsers: {
    loading: false,
    fetched: false,
    error: "",
    call: () => {
      const { currentBusinessUnit, usersApi } = get();
      set((state) => {
        state.getUsers.loading = true;
        state.getUsers.error = "";
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          usersApi
            .businessUnitIdUsersGet(currentBusinessUnit.Id)
            .then((result) => {
              const users = result.data.Users;
              if (users && users.length > 0) {
                set((state) => {
                  (state.users = users), (state.getUsers.fetched = true);
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getUsers.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getUsers.loading = false;
                state.getUsers.fetched = true;
              });
            });
        }
      });
    },
  },
  addUser: {
    loading: false,
    call: (user: User) => {
      const { currentBusinessUnit, usersApi } = get();
      set((state) => {
        state.addUser.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          usersApi
            .businessUnitIdUsersPost(currentBusinessUnit.Id, user)
            .then(() => {
              set((state) => {
                state.users.push(user);
                state.users.sort((a, b) => (a.Email > b.Email ? 1 : b.Email > a.Email ? -1 : 0));
              });
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.addUser.loading = false;
              });
            });
        }
      });
    },
  },
  editUser: {
    loading: false,
    call: (editedUser: User) => {
      const { currentBusinessUnit, usersApi } = get();
      set((state) => {
        state.editUser.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          usersApi
            .businessUnitIdUserUserIdPut(currentBusinessUnit.Id, editedUser.Email, {
              RoleId: editedUser.RoleId,
            })
            .then(() => {
              set((state) => {
                const storeUser = state.users.find((user) => user.Email === editedUser.Email);
                if (storeUser) {
                  storeUser.RoleId = editedUser.RoleId;
                  storeUser.RoleName = editedUser.RoleName;
                }
              });
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.editUser.loading = false;
              });
            });
        }
      });
    },
  },
  deleteUsers: {
    loading: false,
    call: () => {
      const { currentBusinessUnit, usersApi, users, selectedUsers } = get();
      const failedUsers: User[] = [];
      const deletedUsers: User[] = [];
      set((state) => {
        state.deleteUsers.loading = true;
      });
      return new Promise(
        (
          resolve: (deletedUsers: Array<User>) => void,
          reject: (failedDeletes: Array<User>) => void
        ) => {
          if (!currentBusinessUnit) {
            reject(selectedUsers);
          } else {
            Promise.all(
              selectedUsers.map((user) => {
                return usersApi
                  .businessUnitIdUserUserIdDelete(currentBusinessUnit.Id, user.Email)
                  .then(() => deletedUsers.push(user))
                  .catch(() => failedUsers.push(user));
              })
            )
              .then(() => {
                const trimmedUsers = users.filter((user) => {
                  if (selectedUsers.find((selectedUser) => selectedUser.Email === user.Email)) {
                    if (failedUsers.find((failedUser) => failedUser.Email === user.Email)) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                });

                set({ users: trimmedUsers, selectedUsers: failedUsers });

                if (failedUsers.length > 0) {
                  reject(failedUsers);
                } else resolve(deletedUsers);
              })
              .finally(() => {
                set((state) => {
                  state.deleteUsers.loading = false;
                });
              });
          }
        }
      );
    },
  },
  refreshUsers: () => {
    set((state) => {
      state.getUsers.fetched = false;
    });
    get().getUsers.call();
  },
  addSelectedUser: (user: User) => {
    set((state) => {
      state.selectedUsers.push(user);
    });
  },
  deselectUser: (user: User) => {
    set((state) => {
      const index = state.selectedUsers.findIndex(
        (selectedUser) => selectedUser.Email === user.Email
      );
      state.selectedUsers.splice(index, 1);
    });
  },
  clearUsers: () => {
    set({ users: [] });
  },
  clearAllSelectedUsers: () => {
    set({ selectedUsers: [] });
  },
});
