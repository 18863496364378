import { GetState, SetState } from "zustand";
import { RoleAndPermissions, RolePermissionsRequest, RolesApi } from "../../../api";
import { getRolesApiInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export type RolesStore = {
  rolesApi: RolesApi;
  rolesFetched: boolean;
  roles: Array<RoleAndPermissions>;
  openRole: {
    id: string | undefined;
    isDirty: boolean;
    hasBlockedToggle: boolean;
    nextRole: RoleAndPermissions | undefined;
    toggle: (role: RoleAndPermissions) => void;
    setBlockedToggle: (blocked: boolean, nextRole?: RoleAndPermissions) => void;
    setDirty: (isDirty: boolean) => void;
  };
  getRolesLoading: boolean;
  getRolesError: string;
  getRoles: () => void;
  editRoleLoading: boolean;
  addRole: (nameAndPermissions: RolePermissionsRequest) => Promise<void>;
  addRoleLoading: boolean;
  editRole: (roleId: string, nameAndPermissions: RolePermissionsRequest) => Promise<void>;
  deleteRoleLoading: boolean;
  deleteRole: () => Promise<void>;
  selectedRole: RoleAndPermissions | undefined;
  addSelectedRole: (role: RoleAndPermissions) => void;
  deselectRole: (role: RoleAndPermissions) => void;
};

export const rolesStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): RolesStore => ({
  rolesApi: getRolesApiInstance(),
  rolesFetched: false,
  roles: [],
  openRole: {
    id: undefined,
    isDirty: false,
    hasBlockedToggle: false,
    nextRole: undefined,
    toggle: (role: RoleAndPermissions) => {
      set((state) => {
        state.openRole.id = state.openRole.id !== role.RoleId ? role.RoleId : undefined;
        state.openRole.isDirty = false;
        state.openRole.hasBlockedToggle = false;
      });
    },
    setBlockedToggle: (blocked: boolean, nextRole?: RoleAndPermissions) => {
      set((state) => {
        state.openRole.hasBlockedToggle = blocked;
        state.openRole.nextRole = nextRole;
      });
    },
    setDirty: (isDirty: boolean) => {
      set((state) => {
        state.openRole.isDirty = isDirty;
      });
    },
  },
  getRolesLoading: false,
  addRoleLoading: false,
  editRoleLoading: false,
  deleteRoleLoading: false,
  selectedRole: undefined,
  getRolesError: "",
  getRoles: () => {
    const state = get();
    set({ getRolesLoading: true, getRolesError: "" });
    state.rolesApi
      .rolesGet()
      .then((result) => {
        const roles = result.data.Roles;
        if (roles && roles.length > 0 && !state.rolesFetched) {
          set({ roles: roles, rolesFetched: true, getRolesLoading: false });
        }
      })
      .catch((error) => {
        set({ getRolesLoading: false, rolesFetched: true, getRolesError: error });
      });
  },
  addRole: (nameAndPermissions: RolePermissionsRequest) => {
    const { rolesApi, openRole } = get();
    set({ addRoleLoading: true });
    return new Promise<void>((resolve, reject) => {
      rolesApi
        .rolesPost(nameAndPermissions)
        .then((result) => {
          const newRole = result.data;
          set((state) => {
            state.roles.unshift(newRole);
          });
          openRole.toggle(newRole);
          resolve();
        })
        .catch(reject)
        .finally(() => {
          set({ addRoleLoading: false });
        });
    });
  },
  editRole: (roleId: string, nameAndPermissions: RolePermissionsRequest) => {
    set({ editRoleLoading: true });
    return new Promise<void>((resolve, reject) => {
      if (!roleId || !nameAndPermissions) {
        reject();
      } else {
        get()
          .rolesApi.rolesRoleIdPut(roleId, nameAndPermissions)
          .then(() => {
            set((state) => {
              const storeRole = state.roles.find((role) => role.RoleId === roleId);
              if (storeRole) {
                storeRole.Name = nameAndPermissions.Name;
                storeRole.Permissions = nameAndPermissions.Permissions;
              }
            });
            resolve();
          })
          .catch(reject)
          .finally(() => {
            set({ editRoleLoading: false });
          });
      }
    });
  },
  deleteRole: () => {
    const state = get();
    set({ deleteRoleLoading: true });
    return new Promise<void>((resolve, reject) => {
      if (state.selectedRole) {
        state.rolesApi
          .rolesRoleIdDelete(state.selectedRole.RoleId)
          .then(() => {
            set((state) => {
              const trimmedRoles = state.roles.filter(
                (role) => role.RoleId !== state.selectedRole?.RoleId
              );
              state.roles = trimmedRoles;
              state.selectedRole = undefined;
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            set({ deleteRoleLoading: false });
          });
      } else {
        reject();
      }
    });
  },
  addSelectedRole: (role: RoleAndPermissions) => {
    set({
      selectedRole: role,
    });
  },
  deselectRole: () => {
    set({
      selectedRole: undefined,
    });
  },
});
