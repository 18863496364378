import { useBusinessUnitsStore, usePermissionsStore } from "hooks/stores";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import VFLivingSpeechmark from "../../assets/animations/VF_Living_Speechmark_Grey.json";
import classes from "./Splash.module.scss";

const Splash = (): JSX.Element => {
  const { t } = useTranslation();
  const buFetchError = useBusinessUnitsStore((state) => state.getBusinessUnits.error);
  const permissionsFetchError = usePermissionsStore((state) => state.getPermissions.error);

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: VFLivingSpeechmark,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.Splash} data-testid="loading-spinner">
      {!permissionsFetchError && !buFetchError ? (
        <Lottie options={animationOptions} height={450} width={450} />
      ) : (
        <span>{t("common:errors.permissions")}</span>
      )}
    </div>
  );
};

export default Splash;
