import {
  CreateRoutingProfileRequest,
  QueuesApi,
  RoutingProfileDetailResponse,
  RoutingProfileListItem,
  RoutingProfilesApi,
} from "api";
import { getQueuesInstance, getRoutingProfilesInstance } from "utils/getApiInstance";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type RoutingManagementStore = {
  routingProfilesApi: RoutingProfilesApi;
  queuesApi: QueuesApi;
  getRoutingProfiles: {
    routingProfiles: Array<RoutingProfileListItem>;
    called: boolean;
    loading: boolean;
    error: string;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getRoutingDetails: {
    detailedRoutingProfiles: Array<RoutingProfileDetailResponse>;
    loading: boolean;
    call: (routingId: string) => Promise<RoutingProfileDetailResponse>;
    refresh: (routingId: string) => Promise<RoutingProfileDetailResponse>;
  };
  addRoutingProfile: {
    call: (routingProfile: CreateRoutingProfileRequest) => Promise<RoutingProfileDetailResponse>;
    loading: boolean;
  };
  editRoutingProfile: {
    call: (routingProfileId: string, routingProfile: CreateRoutingProfileRequest) => Promise<void>;
    loading: boolean;
  };
  deleteRoutingProfile: {
    loading: boolean;
    call: (routingProfileId: string) => Promise<void>;
  };
};

export const rouroutingManagementStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): RoutingManagementStore => ({
  routingProfilesApi: getRoutingProfilesInstance(),
  queuesApi: getQueuesInstance(),
  getRoutingProfiles: {
    routingProfiles: [],
    called: false,
    loading: false,
    error: "",
    call: () => {
      const { routingProfilesApi, currentBusinessUnit } = get();
      set((state) => {
        state.getRoutingProfiles.loading = true;
        state.getRoutingProfiles.error = "";
      });

      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject();
        } else {
          routingProfilesApi
            .businessUnitIdRoutingProfilesGet(currentBusinessUnit.Id)
            .then((result) => {
              const routingProfiles = result.data.RoutingProfiles;
              if (routingProfiles && routingProfiles.length > 0) {
                set((state) => {
                  state.getRoutingProfiles.routingProfiles = routingProfiles;
                });
                resolve();
              } else if (!routingProfiles) {
                throw new Error("Unexpected response from Routing Profiles API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getRoutingProfiles.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getRoutingProfiles.loading = false;
                state.getRoutingProfiles.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getRoutingProfiles.routingProfiles = [];
      });
    },
    refresh: () => {
      get().getRoutingProfiles.clear();
      get().getRoutingProfiles.call();
    },
  },
  getRoutingDetails: {
    detailedRoutingProfiles: [],
    loading: false,
    call: (routingId: string) => {
      const state = get();
      const { currentBusinessUnit, routingProfilesApi } = get();
      set((state) => {
        state.getRoutingDetails.loading = true;
      });
      return new Promise<RoutingProfileDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit || !routingId) {
          reject();
        } else {
          routingProfilesApi
            .businessUnitIdRoutingProfilesRoutingProfileIdGet(currentBusinessUnit.Id, routingId)
            .then((result) => {
              const routingDetails = result.data;
              if (routingDetails) {
                const routingIndex = state.getRoutingDetails.detailedRoutingProfiles.findIndex(
                  (routingProfile) => routingProfile.Id === routingId
                );
                set((state) => {
                  if (routingIndex > -1)
                    state.getRoutingDetails.detailedRoutingProfiles[routingIndex] = routingDetails;
                  else state.getRoutingDetails.detailedRoutingProfiles.push(routingDetails);
                });
                resolve(routingDetails);
              } else if (!routingDetails) {
                throw new Error("Unexpected response from Routing Profiles API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getRoutingDetails.loading = false;
              })
            );
        }
      });
    },
    refresh: (routingId: string) => {
      set((state) => {
        state.getRoutingDetails.detailedRoutingProfiles =
          state.getRoutingDetails.detailedRoutingProfiles.filter((rp) => rp.Id !== routingId);
      });
      return get().getRoutingDetails.call(routingId);
    },
  },
  addRoutingProfile: {
    loading: false,
    call: (routingProfile: CreateRoutingProfileRequest) => {
      const {
        currentBusinessUnit,
        routingProfilesApi,
        getRoutingProfiles: { refresh: refreshRoutingProfiles },
      } = get();
      set((state) => {
        state.addRoutingProfile.loading = true;
      });
      return new Promise<RoutingProfileDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          routingProfilesApi
            .businessUnitIdRoutingProfilesPost(currentBusinessUnit.Id, routingProfile)
            .then((result) => {
              const newRoutingProfile = result.data;
              if (newRoutingProfile) {
                set((state) => {
                  state.getRoutingDetails.detailedRoutingProfiles.push(newRoutingProfile);
                });
                refreshRoutingProfiles();
                resolve(newRoutingProfile);
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addRoutingProfile.loading = false;
              })
            );
        }
      });
    },
  },
  editRoutingProfile: {
    loading: false,
    call: (routingProfileId: string, routingProfile: CreateRoutingProfileRequest) => {
      const {
        currentBusinessUnit,
        routingProfilesApi,
        getRoutingProfiles: { refresh: refreshRoutingProfiles },
        getRoutingDetails: { refresh: refreshRoutingProfileDetails },
      } = get();
      set((state) => {
        state.editRoutingProfile.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          routingProfilesApi
            .businessUnitIdRoutingProfilesRoutingProfileIdPut(
              currentBusinessUnit.Id,
              routingProfileId,
              routingProfile
            )
            .then(() => {
              refreshRoutingProfiles();
              refreshRoutingProfileDetails(routingProfileId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editRoutingProfile.loading = false;
              })
            );
        }
      });
    },
  },
  deleteRoutingProfile: {
    loading: false,
    call: (routingProfileId: string) => {
      const { currentBusinessUnit, routingProfilesApi } = get();
      set((state) => {
        state.deleteRoutingProfile.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          routingProfilesApi
            .businessUnitIdRoutingProfilesRoutingProfileIdDelete(
              currentBusinessUnit.Id,
              routingProfileId
            )
            .then(() => {
              set((state) => {
                const trimmedProfiles = state.getRoutingProfiles.routingProfiles.filter(
                  (profile) => profile.Id !== routingProfileId
                );
                const trimmedDetailedProfiles =
                  state.getRoutingDetails.detailedRoutingProfiles.filter(
                    (profile) => profile.Id !== routingProfileId
                  );
                state.getRoutingProfiles.routingProfiles = trimmedProfiles;
                state.getRoutingDetails.detailedRoutingProfiles = trimmedDetailedProfiles;
              });
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.deleteRoutingProfile.loading = false;
              });
            });
        }
      });
    },
  },
});
