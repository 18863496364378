import usePermissions from "hooks/usePermissions/usePermissions";
import { ReactNode } from "react";
import { Permissions } from "../../../api";

type HasPermissionProps = {
  permission: Permissions;
  children: ReactNode;
};

const HasPermission = ({ permission, children }: HasPermissionProps): JSX.Element => {
  const { hasPermission } = usePermissions();

  return <>{hasPermission(permission) && children}</>;
};

export default HasPermission;
