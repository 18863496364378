import environment from "../environment";

const CognitoConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  // identityPoolId: environment.aws.cognito.identityPoolId,

  // REQUIRED - Amazon Cognito Region
  region: environment.aws.region,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: environment.aws.cognito.userPoolId,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: environment.aws.cognito.userPoolAppClientId,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  // cookieStorage: {
  //   domain: environment.production
  //     ? `.${environment.aws.cloudfront.domainId}.cloudfront.net`
  //     : "localhost",
  //   path: "/",
  //   expires: 365,
  //   secure: environment.production ? true : false,
  // },

  // OPTIONAL - customized storage object
  // storage: MyStorage,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: "USER_PASSWORD_AUTH",

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  // clientMetadata: { myCustomKey: 'myCustomValue' },

  // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: environment.aws.cognito.authDomain,
    scope: ["email", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: environment.aws.cognito.authRedirectSignIn,
    redirectSignOut: environment.aws.cognito.authRedirectSignOut,
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

export default CognitoConfig;
