import {
  CreateEmergencyClosure,
  CreateIngressConfig,
  DescribeIngressConfig,
  DescribePhoneNumber,
  IngressApi,
  IngressTemplateDetailResponse,
  ListIngressConfigItem,
  ListIngressTemplateItem,
  UpdateIngressConfig,
} from "api";
import { getIngressInstance } from "utils/getApiInstance";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type IngressStore = {
  ingressApi: IngressApi;
  getIngressTemplates: {
    ingressTemplates: Array<ListIngressTemplateItem>;
    loading: boolean;
    error: string;
    called: boolean;
    call: () => Promise<Array<ListIngressTemplateItem>>;
    clear: () => void;
    refresh: () => void;
  };
  getIngressTemplateDetails: {
    detailedIngressTemplates: Array<IngressTemplateDetailResponse>;
    loading: boolean;
    call: (ingressTemplateId: string) => Promise<IngressTemplateDetailResponse>;
    clear: () => void;
    refresh: (ingressTemplateId: string) => void;
  };
  getIngressInstances: {
    ingressInstances: Array<ListIngressConfigItem>;
    loading: boolean;
    error: string;
    called: boolean;
    call: () => Promise<Array<ListIngressConfigItem>>;
    clear: () => void;
    refresh: () => void;
  };
  getIngressInstanceDetails: {
    detailedIngressInstances: Array<DescribeIngressConfig>;
    loading: boolean;
    call: (ingressInstanceId: string) => Promise<DescribeIngressConfig>;
    clear: () => void;
    refresh: (ingressInstanceId: string) => void;
  };
  editIngressInstance: {
    loading: boolean;
    call: (ingressInstanceId: string, ingressInstance: UpdateIngressConfig) => Promise<void>;
  };
  addIngressInstance: {
    loading: boolean;
    call: (ingressIntance: CreateIngressConfig) => Promise<void>;
  };
  deleteIngressInstance: {
    loading: boolean;
    call: (ingressInstanceId: string) => Promise<void>;
  };
  changeEmergencyClosure: {
    loading: boolean;
    call: (emergencyClosure: CreateEmergencyClosure) => Promise<void>;
  };
  getDDIs: {
    ddis: Array<DescribePhoneNumber>;
    loading: boolean;
    error: string;
    called: boolean;
    call: () => Promise<Array<DescribePhoneNumber>>;
    clear: () => void;
    refresh: () => void;
  };
};

export const ingressStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): IngressStore => ({
  ingressApi: getIngressInstance(),
  getIngressTemplates: {
    ingressTemplates: [],
    loading: false,
    error: "",
    called: false,
    call: () => {
      const { ingressApi, currentBusinessUnit } = get();
      set((state) => {
        state.getIngressTemplates.loading = true;
        state.getIngressTemplates.error = "";
      });

      return new Promise<Array<ListIngressTemplateItem>>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          ingressApi
            .businessUnitIdIngressTemplatesGet(currentBusinessUnit.Id)
            .then((result) => {
              const templates = result.data;
              if (templates && templates.length > 0) {
                set((state) => {
                  state.getIngressTemplates.ingressTemplates = templates;
                });
                resolve(templates);
              } else if (!templates) {
                throw new Error("Unexpected response from Ingress API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getIngressTemplates.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getIngressTemplates.loading = false;
                state.getIngressTemplates.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getIngressTemplates.ingressTemplates = [];
        state.getIngressTemplates.called = false;
      });
    },
    refresh: () => {
      get().getIngressTemplates.clear();
      get().getIngressTemplates.call();
    },
  },
  getIngressTemplateDetails: {
    detailedIngressTemplates: [],
    loading: false,
    call: (ingressTemplateId: string) => {
      const { currentBusinessUnit, ingressApi } = get();
      set((state) => {
        state.getIngressTemplateDetails.loading = true;
      });
      return new Promise<IngressTemplateDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          ingressApi
            .businessUnitIdIngressTemplatesIngressTemplateIdGet(
              currentBusinessUnit.Id,
              ingressTemplateId
            )
            .then((result) => {
              const ingressTemplateDetails = result.data;
              if (ingressTemplateDetails) {
                set((state) => {
                  const i = state.getIngressTemplateDetails.detailedIngressTemplates.findIndex(
                    (storedTemplate) => storedTemplate.FlowId === ingressTemplateId
                  );
                  if (i >= 0)
                    state.getIngressTemplateDetails.detailedIngressTemplates[i] =
                      ingressTemplateDetails;
                  else
                    state.getIngressTemplateDetails.detailedIngressTemplates.push(
                      ingressTemplateDetails
                    );
                });
                resolve(ingressTemplateDetails);
              } else {
                throw new Error("Unexpected response from Ingress API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getIngressTemplateDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getIngressTemplateDetails.detailedIngressTemplates = [];
      });
    },
    refresh: (flowTemplateId: string) => {
      get().getIngressTemplateDetails.clear();
      get().getIngressTemplateDetails.call(flowTemplateId);
    },
  },
  getIngressInstances: {
    ingressInstances: [],
    loading: false,
    error: "",
    called: false,
    call: () => {
      const { ingressApi, currentBusinessUnit } = get();
      set((state) => {
        state.getIngressInstances.loading = true;
        state.getIngressInstances.error = "";
      });

      return new Promise<Array<ListIngressConfigItem>>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          ingressApi
            .businessUnitIdIngressConfigGet(currentBusinessUnit.Id)
            .then((result) => {
              const ingressInstances = result.data;
              if (ingressInstances && ingressInstances.length > 0) {
                set((state) => {
                  state.getIngressInstances.ingressInstances = ingressInstances;
                });
                resolve(ingressInstances);
              } else if (!ingressInstances) {
                throw new Error("Unexpected response from Ingress API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getIngressInstances.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getIngressInstances.loading = false;
                state.getIngressInstances.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getIngressInstances.ingressInstances = [];
        state.getIngressInstances.called = false;
      });
    },
    refresh: () => {
      get().getIngressInstances.clear();
      get().getIngressInstances.call();
    },
  },
  getIngressInstanceDetails: {
    detailedIngressInstances: [],
    loading: false,
    call: (ingressInstanceId: string) => {
      const { currentBusinessUnit, ingressApi } = get();
      set((state) => {
        state.getIngressInstanceDetails.loading = true;
      });
      return new Promise<DescribeIngressConfig>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU set");
        } else {
          ingressApi
            .businessUnitIdIngressConfigIngressConfigIdGet(
              currentBusinessUnit.Id,
              ingressInstanceId
            )
            .then((result) => {
              const ingressInstanceDetails = result.data;
              if (ingressInstanceDetails) {
                set((state) => {
                  const i = state.getIngressInstanceDetails.detailedIngressInstances.findIndex(
                    (storedInstance) => storedInstance.InboundDDI === ingressInstanceId
                  );
                  if (i >= 0)
                    state.getIngressInstanceDetails.detailedIngressInstances[i] =
                      ingressInstanceDetails;
                  else
                    state.getIngressInstanceDetails.detailedIngressInstances.push(
                      ingressInstanceDetails
                    );
                });
                resolve(ingressInstanceDetails);
              } else {
                throw new Error("Unexpected response from Flow Configs API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getIngressInstanceDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getIngressInstanceDetails.detailedIngressInstances = [];
      });
    },
    refresh: (ingressInstanceId: string) => {
      get().getIngressInstanceDetails.clear();
      get().getIngressInstanceDetails.call(ingressInstanceId);
    },
  },
  editIngressInstance: {
    loading: false,
    call: (ingressInstanceId: string, ingressInstance: UpdateIngressConfig) => {
      const {
        currentBusinessUnit,
        ingressApi,
        getIngressInstances: { refresh: refreshIngressInstances },
        getIngressInstanceDetails: { refresh: refreshIngressInstanceDetails },
      } = get();
      set((state) => {
        state.editIngressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU set");
        else {
          ingressApi
            .businessUnitIdIngressConfigIngressConfigIdPut(
              currentBusinessUnit.Id,
              ingressInstanceId,
              ingressInstance
            )
            .then(() => {
              refreshIngressInstances();
              refreshIngressInstanceDetails(ingressInstanceId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getDDIs.clear();
                state.editIngressInstance.loading = false;
              })
            );
        }
      });
    },
  },
  addIngressInstance: {
    loading: false,
    call: (ingressInstance: CreateIngressConfig) => {
      const {
        currentBusinessUnit,
        ingressApi,
        getIngressInstances: { refresh: refreshIngressInstances },
        getDDIs: { clear: clearDDIs },
      } = get();
      set((state) => {
        state.addIngressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          ingressApi
            .businessUnitIdIngressConfigPost(currentBusinessUnit.Id, ingressInstance)
            .then(() => {
              refreshIngressInstances();
              clearDDIs();
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addIngressInstance.loading = false;
              })
            );
        }
      });
    },
  },
  deleteIngressInstance: {
    loading: false,
    call: (ingressInstanceId: string) => {
      const {
        currentBusinessUnit,
        ingressApi,
        getDDIs: { clear: clearDDIs },
      } = get();
      set((state) => {
        state.deleteIngressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          ingressApi
            .deleteIngressConfig(currentBusinessUnit.Id, ingressInstanceId)
            .then(() => {
              set((state) => {
                state.getIngressInstances.ingressInstances =
                  state.getIngressInstances.ingressInstances.filter(
                    (instance) => instance.InboundDDI !== ingressInstanceId
                  );
                state.getIngressInstanceDetails.detailedIngressInstances =
                  state.getIngressInstanceDetails.detailedIngressInstances.filter(
                    (instance) => instance.InboundDDI !== ingressInstanceId
                  );
              });
              clearDDIs();
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.deleteIngressInstance.loading = false;
              });
            });
        }
      });
    },
  },
  changeEmergencyClosure: {
    loading: false,
    call: (emergencyClosure: CreateEmergencyClosure) => {
      const {
        currentBusinessUnit,
        ingressApi,
        getIngressInstanceDetails: { clear: clearIngressInstanceDetails },
      } = get();
      set((state) => {
        state.changeEmergencyClosure.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          ingressApi
            .businessUnitIdIngressConfigEmergencyPost(currentBusinessUnit.Id, emergencyClosure)
            .then(() => {
              set((state) => {
                emergencyClosure.InboundDDIs.forEach((ddi) => {
                  const ingressInstance = state.getIngressInstances.ingressInstances.find(
                    (ingress) => ingress.InboundDDI === ddi
                  );
                  if (ingressInstance) {
                    ingressInstance.IngressEmergency = emergencyClosure.IngressEmergency;
                  }
                });
              });
              clearIngressInstanceDetails();
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.changeEmergencyClosure.loading = false;
              });
            });
        }
      });
    },
  },
  getDDIs: {
    ddis: [],
    loading: false,
    error: "",
    called: false,
    call: () => {
      const { ingressApi, currentBusinessUnit } = get();
      set((state) => {
        state.getDDIs.loading = true;
        state.getDDIs.error = "";
      });

      return new Promise<Array<DescribePhoneNumber>>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          ingressApi
            .businessUnitIdIngressPhoneNumbersGet(currentBusinessUnit.Id)
            .then((result) => {
              const ddis = result.data;
              if (ddis && ddis.length > 0) {
                set((state) => {
                  state.getDDIs.ddis = ddis;
                });
                resolve(ddis);
              } else if (!ddis) {
                throw new Error("Unexpected response from Ingress API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getDDIs.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getDDIs.loading = false;
                state.getDDIs.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getDDIs.ddis = [];
        state.getDDIs.called = false;
      });
    },
    refresh: () => {
      get().getDDIs.clear();
      get().getDDIs.call();
    },
  },
});
