/* tslint:disable */
/* eslint-disable */
/**
 * ${aws_prefix}.portal
 * This is the API for updating and returning data required by the admin portal
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosInstance, AxiosPromise } from 'axios';
// @ts-ignore
import { BaseAPI, BASE_PATH, COLLECTION_FORMATS, RequestArgs } from './base';
// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, serializeDataIfNeeded, setApiKeyToObject, setSearchParams, toPathString } from './common';
import { Configuration } from './configuration';

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessTypeEnum {
    Full = 'Full',
    View = 'View',
    Edit = 'Edit',
    None = 'None'
}

/**
 * 
 * @export
 * @interface AttributeValueItem
 */
export interface AttributeValueItem {
    /**
     * Last part of the ARN which is usually the uuid (optional as its mainly used for CONTACT_FLOW)
     * @type {string | boolean}
     * @memberof AttributeValueItem
     */
    Id?: string | boolean;
    /**
     * Attribute value unique identifier
     * @type {string}
     * @memberof AttributeValueItem
     */
    Arn: string;
    /**
     * Display name of the attribute value
     * @type {string}
     * @memberof AttributeValueItem
     */
    Name: string;
    /**
     * Has the attribute value got a template
     * @type {boolean}
     * @memberof AttributeValueItem
     */
    HasTemplate?: boolean;
}
/**
 * 
 * @export
 * @interface AuditRequest
 */
export interface AuditRequest {
    /**
     * Audit type / grouping
     * @type {string}
     * @memberof AuditRequest
     */
    Type: string;
    /**
     * Audit message in context to the audit type
     * @type {string}
     * @memberof AuditRequest
     */
    Message: string;
    /**
     * Free form object containing relevant data for the audit entry
     * @type {any}
     * @memberof AuditRequest
     */
    Data?: any | null;
    /**
     * Business unit unique identifier
     * @type {string}
     * @memberof AuditRequest
     */
    BusinessUnitId?: string;
}
/**
 * 
 * @export
 * @interface BusinessUnit
 */
export interface BusinessUnit {
    /**
     * 
     * @type {string}
     * @memberof BusinessUnit
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessUnit
     */
    Description: string;
}
/**
 * 
 * @export
 * @interface BusinessUnitsResponse
 */
export interface BusinessUnitsResponse {
    /**
     * 
     * @type {Array<BusinessUnit>}
     * @memberof BusinessUnitsResponse
     */
    BusinessUnits: Array<BusinessUnit>;
}
/**
 * The type of channel.
 * @export
 * @enum {string}
 */
export enum ChannelEnum {
    Tasks = 'TASKS',
    Chat = 'CHAT',
    Voice = 'VOICE'
}

/**
 * 
 * @export
 * @interface CreateAgentRequest
 */
export interface CreateAgentRequest {
    /**
     * The username / login of the agent (employee id number)
     * @type {string}
     * @memberof CreateAgentRequest
     */
    LoginName: string;
    /**
     * First name of the agent
     * @type {string}
     * @memberof CreateAgentRequest
     */
    Firstname: string;
    /**
     * Family name of the agent
     * @type {string}
     * @memberof CreateAgentRequest
     */
    Surname: string;
    /**
     * Is the agent eligible for survey flag
     * @type {boolean}
     * @memberof CreateAgentRequest
     */
    EligibleForSurvey: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAgentRequest
     */
    SecurityProfiles: Array<string>;
    /**
     * Routing profile associated with the agent
     * @type {string}
     * @memberof CreateAgentRequest
     */
    RoutingProfileId: string;
}
/**
 * 
 * @export
 * @interface CreateEgressConfig
 */
export interface CreateEgressConfig {
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof CreateEgressConfig
     */
    FlowArn: string;
    /**
     * Configuration unqiue identifier (globally unique)
     * @type {string}
     * @memberof CreateEgressConfig
     */
    Configuration: string;
    /**
     * Description of the egress configuration
     * @type {string}
     * @memberof CreateEgressConfig
     */
    Description?: string;
    /**
     * AWS Connect queue ARN
     * @type {string}
     * @memberof CreateEgressConfig
     */
    QueueArn: string;
    /**
     * Determines if the callback in queue feature has been enabled (true = enabled)
     * @type {boolean}
     * @memberof CreateEgressConfig
     */
    CallbackEnabled: boolean;
    /**
     * 
     * @type {Array<UpdateFlowConfigAttribute>}
     * @memberof CreateEgressConfig
     */
    ContactAttributes: Array<UpdateFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface CreateEmergencyClosure
 */
export interface CreateEmergencyClosure {
    /**
     * Emergency closure flag
     * @type {boolean}
     * @memberof CreateEmergencyClosure
     */
    IngressEmergency: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateEmergencyClosure
     */
    InboundDDIs: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateFlowConfig
 */
export interface CreateFlowConfig {
    /**
     * Flow template unique identifier
     * @type {string}
     * @memberof CreateFlowConfig
     */
    FlowArn: string;
    /**
     * Flow config unique identifier (unique across all business units)
     * @type {string}
     * @memberof CreateFlowConfig
     */
    Configuration: string;
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof CreateFlowConfig
     */
    Description?: string;
    /**
     * 
     * @type {Array<DescribeFlowConfigAttribute>}
     * @memberof CreateFlowConfig
     */
    ContactAttributes: Array<DescribeFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface CreateFlowTemplate
 */
export interface CreateFlowTemplate {
    /**
     * AWS connect flow unique identifier to associate with a template
     * @type {string}
     * @memberof CreateFlowTemplate
     */
    FlowId: string;
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof CreateFlowTemplate
     */
    Description: string;
    /**
     * 
     * @type {Array<DescribeFlowTemplateAttribute>}
     * @memberof CreateFlowTemplate
     */
    Attributes: Array<DescribeFlowTemplateAttribute>;
}
/**
 * 
 * @export
 * @interface CreateIngressConfig
 */
export interface CreateIngressConfig {
    /**
     * Business unit unique identifier
     * @type {string}
     * @memberof CreateIngressConfig
     */
    BusinessUnitId: string;
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof CreateIngressConfig
     */
    FlowArn: string;
    /**
     * Inbound DDI (direct dial in) international phone format
     * @type {string}
     * @memberof CreateIngressConfig
     */
    InboundDDI: string;
    /**
     * Emergency closure flag
     * @type {boolean}
     * @memberof CreateIngressConfig
     */
    IngressEmergency: boolean;
    /**
     * Emergency message to use when emergency line closure is on
     * @type {string}
     * @memberof CreateIngressConfig
     */
    IngressEmergencyMessage: string;
    /**
     * Welcome message flag (true is enabled)
     * @type {boolean}
     * @memberof CreateIngressConfig
     */
    WelcomeMessageEnabled: boolean;
    /**
     * Welcome message to use when welcome flag is set to true
     * @type {string}
     * @memberof CreateIngressConfig
     */
    WelcomeMessage?: string;
    /**
     * Hours of operation to apply to the ingress / DDI
     * @type {string}
     * @memberof CreateIngressConfig
     */
    OperationalHoursId: string;
    /**
     * Outbound configuration flow to forward the caller
     * @type {string}
     * @memberof CreateIngressConfig
     */
    NextConfiguration: string;
    /**
     * Id of the VOICE type from attributeType endpoint
     * @type {string}
     * @memberof CreateIngressConfig
     */
    Voice: string;
    /**
     * 
     * @type {Array<UpdateFlowConfigAttribute>}
     * @memberof CreateIngressConfig
     */
    ContactAttributes: Array<UpdateFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface CreateQueueRequest
 */
export interface CreateQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateQueueRequest
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQueueRequest
     */
    Description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQueueRequest
     */
    HoursOfOperationId: string;
    /**
     * Outbound caller ID name
     * @type {string}
     * @memberof CreateQueueRequest
     */
    OutboundCallerIdName?: string;
    /**
     * Outbound AWS flow Id for the queue
     * @type {string}
     * @memberof CreateQueueRequest
     */
    OutboundFlowId?: string;
    /**
     * Outbound telephone number unique identifier
     * @type {string}
     * @memberof CreateQueueRequest
     */
    OutboundCallerIdNumberId?: string;
    /**
     * The maximum number of contacts that can be in the queue before it is considered full.
     * @type {number}
     * @memberof CreateQueueRequest
     */
    MaxContacts?: number;
    /**
     * 
     * @type {Array<DescribeQueueQuickConnect>}
     * @memberof CreateQueueRequest
     */
    QuickConnects?: Array<DescribeQueueQuickConnect>;
}
/**
 * 
 * @export
 * @interface CreateRoutingProfileRequest
 */
export interface CreateRoutingProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoutingProfileRequest
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoutingProfileRequest
     */
    Description: string;
    /**
     * 
     * @type {RoutingProfileChannels}
     * @memberof CreateRoutingProfileRequest
     */
    Channels: RoutingProfileChannels;
    /**
     * 
     * @type {Array<RoutingProfileQueue>}
     * @memberof CreateRoutingProfileRequest
     */
    Queues: Array<RoutingProfileQueue>;
    /**
     * 
     * @type {string}
     * @memberof CreateRoutingProfileRequest
     */
    DefaultOutboundQueueId: string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    RoleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    Email: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeekEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

/**
 * 
 * @export
 * @interface DeleteFlowConfigResponse
 */
export interface DeleteFlowConfigResponse {
    /**
     * Describes why the delete has failed.
     * @type {string}
     * @memberof DeleteFlowConfigResponse
     */
    Message?: string;
    /**
     * 
     * @type {DeleteFlowConfigResponseReferences}
     * @memberof DeleteFlowConfigResponse
     */
    References?: DeleteFlowConfigResponseReferences;
}
/**
 * 
 * @export
 * @interface DeleteFlowConfigResponseReferences
 */
export interface DeleteFlowConfigResponseReferences {
    /**
     * List of configuration names that are referencing the flow config for deletetion
     * @type {Array<string>}
     * @memberof DeleteFlowConfigResponseReferences
     */
    Configurations?: Array<string>;
    /**
     * List of contact flow names that are referencing the flow config for deletetion
     * @type {Array<string>}
     * @memberof DeleteFlowConfigResponseReferences
     */
    ContactFlows?: Array<string>;
}
/**
 * 
 * @export
 * @interface DescribeAgentResponse
 */
export interface DescribeAgentResponse {
    /**
     * Agent unique identifier
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    Id: string;
    /**
     * The username / login of the agent (employee id number)
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    LoginName: string;
    /**
     * First name of the agent
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    Firstname: string;
    /**
     * Family name of the agent
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    Surname: string;
    /**
     * Is the agent eligible for survey flag
     * @type {boolean}
     * @memberof DescribeAgentResponse
     */
    EligibleForSurvey: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DescribeAgentResponse
     */
    SecurityProfiles: Array<string>;
    /**
     * Routing profile associated with the agent
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    RoutingProfileId: string;
    /**
     * Hierarchy name for the current level of the agent
     * @type {string}
     * @memberof DescribeAgentResponse
     */
    HierarchyName?: string;
}
/**
 * 
 * @export
 * @interface DescribeEgressConfig
 */
export interface DescribeEgressConfig {
    /**
     * Configuration unqiue identifier (globally unique)
     * @type {string}
     * @memberof DescribeEgressConfig
     */
    Configuration: string;
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof DescribeEgressConfig
     */
    FlowArn: string;
    /**
     * Description of the egress configuration
     * @type {string}
     * @memberof DescribeEgressConfig
     */
    Description?: string;
    /**
     * AWS Connect queue ARN
     * @type {string}
     * @memberof DescribeEgressConfig
     */
    QueueArn: string;
    /**
     * Determines if the callback in queue feature has been enabled (true = enabled)
     * @type {boolean}
     * @memberof DescribeEgressConfig
     */
    CallbackEnabled: boolean;
    /**
     * 
     * @type {Array<DescribeFlowConfigAttribute>}
     * @memberof DescribeEgressConfig
     */
    ContactAttributes: Array<DescribeFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface DescribeFlowConfig
 */
export interface DescribeFlowConfig {
    /**
     * Flow config unique identifier and display name
     * @type {string}
     * @memberof DescribeFlowConfig
     */
    Configuration: string;
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof DescribeFlowConfig
     */
    Description?: string;
    /**
     * Flow name - linked to the flow ARN (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof DescribeFlowConfig
     */
    FlowName?: string;
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof DescribeFlowConfig
     */
    FlowArn: string;
    /**
     * 
     * @type {Array<DescribeFlowConfigAttribute>}
     * @memberof DescribeFlowConfig
     */
    ContactAttributes: Array<DescribeFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface DescribeFlowConfigAttribute
 */
export interface DescribeFlowConfigAttribute {
    /**
     * Flow config attribute unique identifier
     * @type {string}
     * @memberof DescribeFlowConfigAttribute
     */
    AttrName: string;
    /**
     * 
     * @type {string | boolean}
     * @memberof DescribeFlowConfigAttribute
     */
    AttrValue?: string | boolean;
    /**
     * Attribute value content type
     * @type {string}
     * @memberof DescribeFlowConfigAttribute
     */
    AttrType?: string;
    /**
     * The order in which to display the list.
     * @type {number}
     * @memberof DescribeFlowConfigAttribute
     */
    Ordinal?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DescribeFlowConfigAttribute
     */
    DeveloperOnly?: boolean;
}
/**
 * 
 * @export
 * @interface DescribeFlowTemplate
 */
export interface DescribeFlowTemplate {
    /**
     * Template unique identifier
     * @type {string}
     * @memberof DescribeFlowTemplate
     */
    Id: string;
    /**
     * AWS Connect flow name
     * @type {string}
     * @memberof DescribeFlowTemplate
     */
    FlowName: string;
    /**
     * Description of the template
     * @type {string}
     * @memberof DescribeFlowTemplate
     */
    Description?: string;
    /**
     * 
     * @type {Array<DescribeFlowTemplateAttribute>}
     * @memberof DescribeFlowTemplate
     */
    Attributes: Array<DescribeFlowTemplateAttribute>;
}
/**
 * 
 * @export
 * @interface DescribeFlowTemplateAttribute
 */
export interface DescribeFlowTemplateAttribute {
    /**
     * Flow config attribute unique identifier
     * @type {string}
     * @memberof DescribeFlowTemplateAttribute
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof DescribeFlowTemplateAttribute
     */
    AttributeType: string;
    /**
     * 
     * @type {boolean}
     * @memberof DescribeFlowTemplateAttribute
     */
    DeveloperOnly: boolean;
    /**
     * 
     * @type {number}
     * @memberof DescribeFlowTemplateAttribute
     */
    Ordinal: number;
}
/**
 * 
 * @export
 * @interface DescribeIngressConfig
 */
export interface DescribeIngressConfig {
    /**
     * Flow name - linked to the flow ARN (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    FlowName?: string;
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    FlowArn: string;
    /**
     * Inbound DDI (direct dial in) international phone format
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    InboundDDI: string;
    /**
     * Emergency closure flag
     * @type {boolean}
     * @memberof DescribeIngressConfig
     */
    IngressEmergency: boolean;
    /**
     * Emergency message to use when emergency line closure is on
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    IngressEmergencyMessage: string;
    /**
     * Welcome message flag (true is enabled)
     * @type {boolean}
     * @memberof DescribeIngressConfig
     */
    WelcomeMessageEnabled: boolean;
    /**
     * Welcome message to use when welcome flag is set to true
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    WelcomeMessage?: string;
    /**
     * Hours of operation to apply to the ingress / DDI
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    OperationalHoursId: string;
    /**
     * Outbound configuration flow to forward the caller
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    NextConfiguration: string;
    /**
     * Id of the VOICE type from attributeType endpoint
     * @type {string}
     * @memberof DescribeIngressConfig
     */
    Voice: string;
    /**
     * 
     * @type {Array<DescribeFlowConfigAttribute>}
     * @memberof DescribeIngressConfig
     */
    ContactAttributes: Array<DescribeFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface DescribePhoneNumber
 */
export interface DescribePhoneNumber {
    /**
     * Phone number unique identifier
     * @type {string}
     * @memberof DescribePhoneNumber
     */
    Id: string;
    /**
     * DDI phone number in international format
     * @type {string}
     * @memberof DescribePhoneNumber
     */
    PhoneNumber: string;
    /**
     * Flag to determine if the phone number is in use / assigned to an ingress flow
     * @type {boolean}
     * @memberof DescribePhoneNumber
     */
    InUse?: boolean;
}
/**
 * 
 * @export
 * @interface DescribeQueueQuickConnect
 */
export interface DescribeQueueQuickConnect {
    /**
     * Quick connect unique identifier
     * @type {string}
     * @memberof DescribeQueueQuickConnect
     */
    Id: string;
    /**
     * The name of the quick connect
     * @type {string}
     * @memberof DescribeQueueQuickConnect
     */
    Name?: string;
    /**
     * 
     * @type {QuickConnectTypeEnum}
     * @memberof DescribeQueueQuickConnect
     */
    QuickConnectType?: QuickConnectTypeEnum;
}
/**
 * 
 * @export
 * @interface DescribeTemplateDetailAttribute
 */
export interface DescribeTemplateDetailAttribute {
    /**
     * Flow config attribute unique identifier
     * @type {string}
     * @memberof DescribeTemplateDetailAttribute
     */
    AttrName: string;
    /**
     * Attribute value content type
     * @type {string}
     * @memberof DescribeTemplateDetailAttribute
     */
    AttrType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DescribeTemplateDetailAttribute
     */
    DeveloperOnly?: boolean;
    /**
     * The order in which to display the list.
     * @type {number}
     * @memberof DescribeTemplateDetailAttribute
     */
    Ordinal?: number;
}
/**
 * 
 * @export
 * @interface EgressTemplateDetailResponse
 */
export interface EgressTemplateDetailResponse {
    /**
     * Ingress template unique identifier
     * @type {string}
     * @memberof EgressTemplateDetailResponse
     */
    FlowId: string;
    /**
     * Name for the AWS Connect flow associated with this Ingress template (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof EgressTemplateDetailResponse
     */
    FlowName: string;
    /**
     * 
     * @type {Array<DescribeTemplateDetailAttribute>}
     * @memberof EgressTemplateDetailResponse
     */
    ContactAttributes: Array<DescribeTemplateDetailAttribute>;
}
/**
 * 
 * @export
 * @interface IngressTemplateDetailResponse
 */
export interface IngressTemplateDetailResponse {
    /**
     * Ingress template unique identifier
     * @type {string}
     * @memberof IngressTemplateDetailResponse
     */
    FlowId: string;
    /**
     * Name for the AWS Connect flow associated with this Ingress template (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof IngressTemplateDetailResponse
     */
    FlowName: string;
    /**
     * 
     * @type {Array<DescribeTemplateDetailAttribute>}
     * @memberof IngressTemplateDetailResponse
     */
    ContactAttributes: Array<DescribeTemplateDetailAttribute>;
}
/**
 * 
 * @export
 * @interface ListAgentResponse
 */
export interface ListAgentResponse {
    /**
     * If there are additional results, this is the token for the next set of results.
     * @type {string}
     * @memberof ListAgentResponse
     */
    NextToken?: string;
    /**
     * 
     * @type {Array<ListAgentSummaryItem>}
     * @memberof ListAgentResponse
     */
    AgentSummary: Array<ListAgentSummaryItem>;
}
/**
 * 
 * @export
 * @interface ListAgentSummaryItem
 */
export interface ListAgentSummaryItem {
    /**
     * Unique identifier of the agent.
     * @type {string}
     * @memberof ListAgentSummaryItem
     */
    Id: string;
    /**
     * Employee id number
     * @type {string}
     * @memberof ListAgentSummaryItem
     */
    LoginName: string;
    /**
     * 
     * @type {string}
     * @memberof ListAgentSummaryItem
     */
    Firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof ListAgentSummaryItem
     */
    Surname?: string;
    /**
     * Routing profile associated with the agent
     * @type {string}
     * @memberof ListAgentSummaryItem
     */
    RoutingProfileName: string;
}
/**
 * 
 * @export
 * @interface ListEgressConfigItem
 */
export interface ListEgressConfigItem {
    /**
     * Flow config unique identifier
     * @type {string}
     * @memberof ListEgressConfigItem
     */
    Configuration: string;
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof ListEgressConfigItem
     */
    Description?: string;
    /**
     * Flow name - linked to the flow ARN (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof ListEgressConfigItem
     */
    FlowName: string;
    /**
     * Flag to determine if the egress callback has been enabled
     * @type {boolean}
     * @memberof ListEgressConfigItem
     */
    CallbackEnabled: boolean;
    /**
     * Name of the queue associated with the egress config
     * @type {string}
     * @memberof ListEgressConfigItem
     */
    QueueName?: string;
    /**
     * AWS Connect queue ARN
     * @type {string}
     * @memberof ListEgressConfigItem
     */
    QueueArn?: string;
}
/**
 * 
 * @export
 * @interface ListEgressTemplateItem
 */
export interface ListEgressTemplateItem {
    /**
     * Ingress template unique identifier
     * @type {string}
     * @memberof ListEgressTemplateItem
     */
    FlowId: string;
    /**
     * Flow template unique identifier
     * @type {string}
     * @memberof ListEgressTemplateItem
     */
    FlowArn: string;
    /**
     * Name for the AWS Connect flow associated with this Ingress template (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof ListEgressTemplateItem
     */
    FlowName: string;
}
/**
 * 
 * @export
 * @interface ListFlowAttribute
 */
export interface ListFlowAttribute {
    /**
     * Unique identifier for an attribute
     * @type {string}
     * @memberof ListFlowAttribute
     */
    AttributeType: string;
    /**
     * 
     * @type {string}
     * @memberof ListFlowAttribute
     */
    DisplayName: string;
}
/**
 * 
 * @export
 * @interface ListFlowConfigItem
 */
export interface ListFlowConfigItem {
    /**
     * Flow config unique identifier
     * @type {string}
     * @memberof ListFlowConfigItem
     */
    Configuration: string;
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof ListFlowConfigItem
     */
    Description?: string;
    /**
     * Flow name - linked to the flow ARN (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof ListFlowConfigItem
     */
    FlowName: string;
}
/**
 * 
 * @export
 * @interface ListFlowTemplateItem
 */
export interface ListFlowTemplateItem {
    /**
     * Template unique identifier (uuid)
     * @type {string}
     * @memberof ListFlowTemplateItem
     */
    Id: string;
    /**
     * Name for the AWS Connect flow associated with this template
     * @type {string}
     * @memberof ListFlowTemplateItem
     */
    FlowName: string;
    /**
     * Description of the flow template
     * @type {string}
     * @memberof ListFlowTemplateItem
     */
    Description?: string;
}
/**
 * 
 * @export
 * @interface ListIngressConfigItem
 */
export interface ListIngressConfigItem {
    /**
     * Direct dialing in international telephone number
     * @type {string}
     * @memberof ListIngressConfigItem
     */
    InboundDDI: string;
    /**
     * Flag to determine if the ingress emergency is switched on
     * @type {boolean}
     * @memberof ListIngressConfigItem
     */
    IngressEmergency?: boolean;
    /**
     * Operational hours name applied to the DDI
     * @type {string}
     * @memberof ListIngressConfigItem
     */
    OperationalHoursName?: string;
    /**
     * Operational hours unique identifier
     * @type {string}
     * @memberof ListIngressConfigItem
     */
    OperationalHoursId?: string;
}
/**
 * 
 * @export
 * @interface ListIngressTemplateItem
 */
export interface ListIngressTemplateItem {
    /**
     * Ingress template unique identifier
     * @type {string}
     * @memberof ListIngressTemplateItem
     */
    FlowId: string;
    /**
     * Flow template unique identifier
     * @type {string}
     * @memberof ListIngressTemplateItem
     */
    FlowArn: string;
    /**
     * Name for the AWS Connect flow associated with this Ingress template (this is set by the developer when creating the flow in AWS connect)
     * @type {string}
     * @memberof ListIngressTemplateItem
     */
    FlowName: string;
}
/**
 * 
 * @export
 * @interface ListQueuesHoursOfOperationItem
 */
export interface ListQueuesHoursOfOperationItem {
    /**
     * Unique identifier of the hours of operation.
     * @type {string}
     * @memberof ListQueuesHoursOfOperationItem
     */
    Id: string;
    /**
     * Name of the hours of operation
     * @type {string}
     * @memberof ListQueuesHoursOfOperationItem
     */
    Name: string;
}
/**
 * 
 * @export
 * @interface ListQueuesHoursOfOperationResponse
 */
export interface ListQueuesHoursOfOperationResponse {
    /**
     * 
     * @type {Array<ListQueuesHoursOfOperationItem>}
     * @memberof ListQueuesHoursOfOperationResponse
     */
    HoursOfOperation: Array<ListQueuesHoursOfOperationItem>;
}
/**
 * 
 * @export
 * @interface ListQueuesResponse
 */
export interface ListQueuesResponse {
    /**
     * 
     * @type {Array<QueuesListItem>}
     * @memberof ListQueuesResponse
     */
    Queues: Array<QueuesListItem>;
}
/**
 * 
 * @export
 * @interface ListQuickConnectsItem
 */
export interface ListQuickConnectsItem {
    /**
     * Unique identifier of the quick connect.
     * @type {string}
     * @memberof ListQuickConnectsItem
     */
    Id: string;
    /**
     * Name of the quick connect
     * @type {string}
     * @memberof ListQuickConnectsItem
     */
    Name: string;
    /**
     * 
     * @type {QuickConnectTypeEnum}
     * @memberof ListQuickConnectsItem
     */
    QuickConnectType: QuickConnectTypeEnum;
}
/**
 * 
 * @export
 * @interface ListQuickConnectsResponse
 */
export interface ListQuickConnectsResponse {
    /**
     * 
     * @type {Array<ListQuickConnectsItem>}
     * @memberof ListQuickConnectsResponse
     */
    QuickConnects: Array<ListQuickConnectsItem>;
}
/**
 * 
 * @export
 * @interface ListRoutingProfileResponse
 */
export interface ListRoutingProfileResponse {
    /**
     * 
     * @type {Array<RoutingProfileListItem>}
     * @memberof ListRoutingProfileResponse
     */
    RoutingProfiles: Array<RoutingProfileListItem>;
}
/**
 * 
 * @export
 * @interface LookupItem
 */
export interface LookupItem {
    /**
     * 
     * @type {string}
     * @memberof LookupItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LookupItem
     */
    description?: string;
}
/**
 * 
 * @export
 * @interface OperationalHoursDayItem
 */
export interface OperationalHoursDayItem {
    /**
     * 
     * @type {boolean}
     * @memberof OperationalHoursDayItem
     */
    Enabled: boolean;
    /**
     * 
     * @type {Array<OperationalHoursItem>}
     * @memberof OperationalHoursDayItem
     */
    Hours: Array<OperationalHoursItem>;
}
/**
 * 
 * @export
 * @interface OperationalHoursDetailResponse
 */
export interface OperationalHoursDetailResponse {
    /**
     * Operational hours unique identifier
     * @type {string}
     * @memberof OperationalHoursDetailResponse
     */
    Id: string;
    /**
     * Description of the operational hours item
     * @type {string}
     * @memberof OperationalHoursDetailResponse
     */
    Description: string;
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursDetailResponse
     */
    BusinessUnitId: string;
    /**
     * Has the operational hours item got DDI flows associated
     * @type {boolean}
     * @memberof OperationalHoursDetailResponse
     */
    InUse: boolean;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Monday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Tuesday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Wednesday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Thursday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Friday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Saturday: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursDetailResponse
     */
    Sunday: OperationalHoursDayItem;
}
/**
 * 
 * @export
 * @interface OperationalHoursItem
 */
export interface OperationalHoursItem {
    /**
     * 
     * @type {DayOfWeekEnum}
     * @memberof OperationalHoursItem
     */
    DayOfWeek: DayOfWeekEnum;
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursItem
     */
    From: string;
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursItem
     */
    To: string;
}
/**
 * 
 * @export
 * @interface OperationalHoursRequest
 */
export interface OperationalHoursRequest {
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursRequest
     */
    Description: string;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Monday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Tuesday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Wednesday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Thursday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Friday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Saturday?: OperationalHoursDayItem;
    /**
     * 
     * @type {OperationalHoursDayItem}
     * @memberof OperationalHoursRequest
     */
    Sunday?: OperationalHoursDayItem;
}
/**
 * 
 * @export
 * @interface OperationalHoursSummaryItem
 */
export interface OperationalHoursSummaryItem {
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursSummaryItem
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof OperationalHoursSummaryItem
     */
    Description: string;
    /**
     * 
     * @type {boolean}
     * @memberof OperationalHoursSummaryItem
     */
    InUse: boolean;
}
/**
 * 
 * @export
 * @interface OperationalHoursSummaryResponse
 */
export interface OperationalHoursSummaryResponse {
    /**
     * 
     * @type {Array<OperationalHoursSummaryItem>}
     * @memberof OperationalHoursSummaryResponse
     */
    OperationalHoursSummary: Array<OperationalHoursSummaryItem>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PermissionTypeEnum {
    RoleProfiles = 'RoleProfiles',
    AgentManagementRoutingProfiles = 'AgentManagement_RoutingProfiles',
    AgentManagementAgents = 'AgentManagement_Agents',
    OperatingHours = 'OperatingHours',
    RealTimeViews = 'RealTimeViews',
    HistoricalData = 'HistoricalData',
    Billing = 'Billing',
    EntryPointAdmin = 'EntryPointAdmin',
    QueueAdmin = 'QueueAdmin',
    QueueAdminCallbackConfiguration = 'QueueAdmin_CallbackConfiguration',
    FlowConfiguration = 'FlowConfiguration',
    FlowConfigurationTemplates = 'FlowConfigurationTemplates',
    AdministratorManagement = 'AdministratorManagement',
    Idv = 'ID&V',
    PaymentFlows = 'PaymentFlows',
    AspectVia = 'AspectVia',
    Verint = 'Verint',
    AuditTrail = 'AuditTrail',
    QueueStatus = 'QueueStatus'
}

/**
 * 
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * 
     * @type {PermissionTypeEnum}
     * @memberof Permissions
     */
    Type: PermissionTypeEnum;
    /**
     * 
     * @type {AccessTypeEnum}
     * @memberof Permissions
     */
    Access: AccessTypeEnum;
}
/**
 * 
 * @export
 * @interface PermissionsResponse
 */
export interface PermissionsResponse {
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof PermissionsResponse
     */
    Permissions: Array<Permissions>;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionsResponse
     */
    IsSuperUser: boolean;
}
/**
 * 
 * @export
 * @interface QueueDetailResponse
 */
export interface QueueDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof QueueDetailResponse
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof QueueDetailResponse
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof QueueDetailResponse
     */
    Description?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueDetailResponse
     */
    HoursOfOperationId: string;
    /**
     * Outbound caller ID name
     * @type {string}
     * @memberof QueueDetailResponse
     */
    OutboundCallerIdName?: string;
    /**
     * Outbound AWS flow Id for the queue
     * @type {string}
     * @memberof QueueDetailResponse
     */
    OutboundFlowId?: string;
    /**
     * Outbound telephone number unique identifier
     * @type {string}
     * @memberof QueueDetailResponse
     */
    OutboundCallerIdNumberId?: string;
    /**
     * The maximum number of contacts that can be in the queue before it is considered full.
     * @type {number}
     * @memberof QueueDetailResponse
     */
    MaxContacts?: number;
    /**
     * 
     * @type {Array<DescribeQueueQuickConnect>}
     * @memberof QueueDetailResponse
     */
    QuickConnects?: Array<DescribeQueueQuickConnect>;
    /**
     * 
     * @type {QueueStatus}
     * @memberof QueueDetailResponse
     */
    Status: QueueStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum QueueStatus {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

/**
 * 
 * @export
 * @interface QueueStatusResponse
 */
export interface QueueStatusResponse {
    /**
     * 
     * @type {QueueStatus}
     * @memberof QueueStatusResponse
     */
    Status: QueueStatus;
}
/**
 * 
 * @export
 * @interface QueuesListItem
 */
export interface QueuesListItem {
    /**
     * 
     * @type {string}
     * @memberof QueuesListItem
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof QueuesListItem
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof QueuesListItem
     */
    Description?: string;
    /**
     * 
     * @type {QueueStatus}
     * @memberof QueuesListItem
     */
    Status: QueueStatus;
}
/**
 * The type of quick connect. In the Amazon Connect console, when you create a quick connect, you are prompted to assign one of the following types: Agent (USER), External (PHONE_NUMBER), or Queue (QUEUE).
 * @export
 * @enum {string}
 */
export enum QuickConnectTypeEnum {
    User = 'USER',
    Queue = 'QUEUE',
    PhoneNumber = 'PHONE_NUMBER'
}

/**
 * 
 * @export
 * @interface RoleAndPermissions
 */
export interface RoleAndPermissions {
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof RoleAndPermissions
     */
    Permissions: Array<Permissions>;
    /**
     * 
     * @type {string}
     * @memberof RoleAndPermissions
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAndPermissions
     */
    RoleId: string;
    /**
     * 
     * @type {boolean}
     * @memberof RoleAndPermissions
     */
    IsSuperUserRole: boolean;
}
/**
 * 
 * @export
 * @interface RolePermissionsRequest
 */
export interface RolePermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof RolePermissionsRequest
     */
    Name: string;
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof RolePermissionsRequest
     */
    Permissions: Array<Permissions>;
}
/**
 * 
 * @export
 * @interface RolesAndPermissionsResponse
 */
export interface RolesAndPermissionsResponse {
    /**
     * 
     * @type {Array<RoleAndPermissions>}
     * @memberof RolesAndPermissionsResponse
     */
    Roles: Array<RoleAndPermissions>;
}
/**
 * 
 * @export
 * @interface RoutingProfileChannels
 */
export interface RoutingProfileChannels {
    /**
     * 
     * @type {boolean}
     * @memberof RoutingProfileChannels
     */
    Voice: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoutingProfileChannels
     */
    Chat: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileChannels
     */
    MaxChats?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoutingProfileChannels
     */
    Tasks: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileChannels
     */
    MaxTasks?: number;
}
/**
 * 
 * @export
 * @interface RoutingProfileDetailResponse
 */
export interface RoutingProfileDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileDetailResponse
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileDetailResponse
     */
    Description: string;
    /**
     * 
     * @type {RoutingProfileChannels}
     * @memberof RoutingProfileDetailResponse
     */
    Channels: RoutingProfileChannels;
    /**
     * 
     * @type {Array<RoutingProfileQueue>}
     * @memberof RoutingProfileDetailResponse
     */
    Queues: Array<RoutingProfileQueue>;
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileDetailResponse
     */
    DefaultOutboundQueueId: string;
    /**
     * Routing profile unique identifier
     * @type {string}
     * @memberof RoutingProfileDetailResponse
     */
    Id: string;
}
/**
 * 
 * @export
 * @interface RoutingProfileDetailResponseAllOf
 */
export interface RoutingProfileDetailResponseAllOf {
    /**
     * Routing profile unique identifier
     * @type {string}
     * @memberof RoutingProfileDetailResponseAllOf
     */
    Id?: string;
}
/**
 * 
 * @export
 * @interface RoutingProfileListItem
 */
export interface RoutingProfileListItem {
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileListItem
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileListItem
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileListItem
     */
    Description: string;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileListItem
     */
    NumAssociatedQueues: number;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileListItem
     */
    NumAgentsStaffed: number;
}
/**
 * 
 * @export
 * @interface RoutingProfileQueue
 */
export interface RoutingProfileQueue {
    /**
     * 
     * @type {string}
     * @memberof RoutingProfileQueue
     */
    QueueId: string;
    /**
     * 
     * @type {ChannelEnum}
     * @memberof RoutingProfileQueue
     */
    Channel: ChannelEnum;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileQueue
     */
    DelayInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof RoutingProfileQueue
     */
    Priority?: number;
}
/**
 * 
 * @export
 * @interface UpdateAgentRequest
 */
export interface UpdateAgentRequest {
    /**
     * The username / login of the agent (employee id number)
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    LoginName: string;
    /**
     * First name of the agent
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    Firstname: string;
    /**
     * Family name of the agent
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    Surname: string;
    /**
     * Is the agent eligible for survey flag
     * @type {boolean}
     * @memberof UpdateAgentRequest
     */
    EligibleForSurvey: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAgentRequest
     */
    SecurityProfiles: Array<string>;
    /**
     * Routing profile associated with the agent
     * @type {string}
     * @memberof UpdateAgentRequest
     */
    RoutingProfileId: string;
}
/**
 * 
 * @export
 * @interface UpdateEgressConfig
 */
export interface UpdateEgressConfig {
    /**
     * Description of the egress configuration
     * @type {string}
     * @memberof UpdateEgressConfig
     */
    Description?: string;
    /**
     * AWS Connect queue ARN
     * @type {string}
     * @memberof UpdateEgressConfig
     */
    QueueArn: string;
    /**
     * Determines if the callback in queue feature has been enabled (true = enabled)
     * @type {boolean}
     * @memberof UpdateEgressConfig
     */
    CallbackEnabled: boolean;
    /**
     * 
     * @type {Array<UpdateFlowConfigAttribute>}
     * @memberof UpdateEgressConfig
     */
    ContactAttributes: Array<UpdateFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface UpdateFlowConfig
 */
export interface UpdateFlowConfig {
    /**
     * Description of the flow configuration
     * @type {string}
     * @memberof UpdateFlowConfig
     */
    Description?: string;
    /**
     * 
     * @type {Array<UpdateFlowConfigAttribute>}
     * @memberof UpdateFlowConfig
     */
    ContactAttributes: Array<UpdateFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface UpdateFlowConfigAttribute
 */
export interface UpdateFlowConfigAttribute {
    /**
     * Flow config attribute unique identifier
     * @type {string}
     * @memberof UpdateFlowConfigAttribute
     */
    AttrName: string;
    /**
     * 
     * @type {string | boolean}
     * @memberof UpdateFlowConfigAttribute
     */
    AttrValue?: string | boolean;
}
/**
 * 
 * @export
 * @interface UpdateFlowTemplate
 */
export interface UpdateFlowTemplate {
    /**
     * Description of the template
     * @type {string}
     * @memberof UpdateFlowTemplate
     */
    Description: string;
    /**
     * 
     * @type {Array<DescribeFlowTemplateAttribute>}
     * @memberof UpdateFlowTemplate
     */
    Attributes: Array<DescribeFlowTemplateAttribute>;
}
/**
 * 
 * @export
 * @interface UpdateIngressConfig
 */
export interface UpdateIngressConfig {
    /**
     * AWS Connect flow ARN
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    FlowArn: string;
    /**
     * Emergency closure flag
     * @type {boolean}
     * @memberof UpdateIngressConfig
     */
    IngressEmergency: boolean;
    /**
     * Emergency message to use when emergency line closure is on
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    IngressEmergencyMessage: string;
    /**
     * Welcome message flag (true is enabled)
     * @type {boolean}
     * @memberof UpdateIngressConfig
     */
    WelcomeMessageEnabled: boolean;
    /**
     * Welcome message to use when welcome flag is set to true
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    WelcomeMessage?: string;
    /**
     * Hours of operation to apply to the ingress / DDI
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    OperationalHoursId: string;
    /**
     * Outbound configuration flow to forward the caller
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    NextConfiguration: string;
    /**
     * Id of the VOICE type from attributeType endpoint
     * @type {string}
     * @memberof UpdateIngressConfig
     */
    Voice: string;
    /**
     * 
     * @type {Array<UpdateFlowConfigAttribute>}
     * @memberof UpdateIngressConfig
     */
    ContactAttributes: Array<UpdateFlowConfigAttribute>;
}
/**
 * 
 * @export
 * @interface UpdateQueueRequest
 */
export interface UpdateQueueRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    Description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    HoursOfOperationId: string;
    /**
     * Outbound caller ID name
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    OutboundCallerIdName?: string;
    /**
     * Outbound AWS flow Id for the queue
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    OutboundFlowId?: string;
    /**
     * Outbound telephone number unique identifier
     * @type {string}
     * @memberof UpdateQueueRequest
     */
    OutboundCallerIdNumberId?: string;
    /**
     * The maximum number of contacts that can be in the queue before it is considered full.
     * @type {number}
     * @memberof UpdateQueueRequest
     */
    MaxContacts?: number;
    /**
     * 
     * @type {Array<DescribeQueueQuickConnect>}
     * @memberof UpdateQueueRequest
     */
    QuickConnects?: Array<DescribeQueueQuickConnect>;
}
/**
 * 
 * @export
 * @interface UpdateQueueStatusRequest
 */
export interface UpdateQueueStatusRequest {
    /**
     * 
     * @type {QueueStatus}
     * @memberof UpdateQueueStatusRequest
     */
    Status: QueueStatus;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    RoleId: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    FirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    LastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    Email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    RoleId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    RoleName: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    IsSuperUser: boolean;
}
/**
 * 
 * @export
 * @interface UsersResponse
 */
export interface UsersResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersResponse
     */
    Users: Array<User>;
}

/**
 * AgentApi - axios parameter creator
 * @export
 */
export const AgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes an agents association with a business unit, but will not remove the agent from AWS connect
         * @summary Remove an agent from a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdDelete: async (businessUnitId: string, agentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdDelete', 'agentId', agentId)
            const localVarPath = `/{businessUnitId}/agents/{agentId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Describe an agent for a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdGet: async (businessUnitId: string, agentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdGet', 'agentId', agentId)
            const localVarPath = `/{businessUnitId}/agents/{agentId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/agents/{agentId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an agents details
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {UpdateAgentRequest} updateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdPut: async (businessUnitId: string, agentId: string, updateAgentRequest: UpdateAgentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdPut', 'agentId', agentId)
            // verify required parameter 'updateAgentRequest' is not null or undefined
            assertParamExists('businessUnitIdAgentsAgentIdPut', 'updateAgentRequest', updateAgentRequest)
            const localVarPath = `/{businessUnitId}/agents/{agentId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all agents for a business unit
         * @summary Retrieve all agents for a business unit
         * @param {string} businessUnitId 
         * @param {string} [nameAndLoginFilter] Filter by name and/or login
         * @param {Array<string>} [routingProfileIdFilter] Filter by routing profile identifier
         * @param {number} [maxResults] The maximum number of results to return per page.  Valid Range: Minimum value of 1. Maximum value of 1000.
         * @param {string} [nextToken] The token for the next set of results.  Use the value returned in the previous response in the next request to retrieve the next set of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsGet: async (businessUnitId: string, nameAndLoginFilter?: string, routingProfileIdFilter?: Array<string>, maxResults?: number, nextToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/agents`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (nameAndLoginFilter !== undefined) {
                localVarQueryParameter['nameAndLoginFilter'] = nameAndLoginFilter;
            }

            if (routingProfileIdFilter) {
                localVarQueryParameter['routingProfileIdFilter'] = routingProfileIdFilter.join(COLLECTION_FORMATS.csv);
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }

            if (nextToken !== undefined) {
                localVarQueryParameter['nextToken'] = nextToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new agent and associate with a business unit
         * @param {string} businessUnitId 
         * @param {CreateAgentRequest} createAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsPost: async (businessUnitId: string, createAgentRequest: CreateAgentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createAgentRequest' is not null or undefined
            assertParamExists('businessUnitIdAgentsPost', 'createAgentRequest', createAgentRequest)
            const localVarPath = `/{businessUnitId}/agents`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve security profiles for a business unit
         * @summary Retrieve security profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsSecurityProfilesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAgentsSecurityProfilesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/agents/securityProfiles`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsSecurityProfilesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/agents/securityProfiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentApi - functional programming interface
 * @export
 */
export const AgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes an agents association with a business unit, but will not remove the agent from AWS connect
         * @summary Remove an agent from a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsAgentIdDelete(businessUnitId: string, agentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsAgentIdDelete(businessUnitId, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Describe an agent for a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsAgentIdGet(businessUnitId: string, agentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsAgentIdGet(businessUnitId, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsAgentIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsAgentIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an agents details
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {UpdateAgentRequest} updateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsAgentIdPut(businessUnitId: string, agentId: string, updateAgentRequest: UpdateAgentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsAgentIdPut(businessUnitId, agentId, updateAgentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all agents for a business unit
         * @summary Retrieve all agents for a business unit
         * @param {string} businessUnitId 
         * @param {string} [nameAndLoginFilter] Filter by name and/or login
         * @param {Array<string>} [routingProfileIdFilter] Filter by routing profile identifier
         * @param {number} [maxResults] The maximum number of results to return per page.  Valid Range: Minimum value of 1. Maximum value of 1000.
         * @param {string} [nextToken] The token for the next set of results.  Use the value returned in the previous response in the next request to retrieve the next set of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsGet(businessUnitId: string, nameAndLoginFilter?: string, routingProfileIdFilter?: Array<string>, maxResults?: number, nextToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsGet(businessUnitId, nameAndLoginFilter, routingProfileIdFilter, maxResults, nextToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new agent and associate with a business unit
         * @param {string} businessUnitId 
         * @param {CreateAgentRequest} createAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsPost(businessUnitId: string, createAgentRequest: CreateAgentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsPost(businessUnitId, createAgentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve security profiles for a business unit
         * @summary Retrieve security profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsSecurityProfilesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LookupItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsSecurityProfilesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAgentsSecurityProfilesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAgentsSecurityProfilesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentApi - factory interface
 * @export
 */
export const AgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentApiFp(configuration)
    return {
        /**
         * Removes an agents association with a business unit, but will not remove the agent from AWS connect
         * @summary Remove an agent from a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdDelete(businessUnitId: string, agentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdAgentsAgentIdDelete(businessUnitId, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Describe an agent for a business unit
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdGet(businessUnitId: string, agentId: string, options?: any): AxiosPromise<DescribeAgentResponse> {
            return localVarFp.businessUnitIdAgentsAgentIdGet(businessUnitId, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdAgentsAgentIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an agents details
         * @param {string} businessUnitId 
         * @param {string} agentId 
         * @param {UpdateAgentRequest} updateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsAgentIdPut(businessUnitId: string, agentId: string, updateAgentRequest: UpdateAgentRequest, options?: any): AxiosPromise<DescribeAgentResponse> {
            return localVarFp.businessUnitIdAgentsAgentIdPut(businessUnitId, agentId, updateAgentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all agents for a business unit
         * @summary Retrieve all agents for a business unit
         * @param {string} businessUnitId 
         * @param {string} [nameAndLoginFilter] Filter by name and/or login
         * @param {Array<string>} [routingProfileIdFilter] Filter by routing profile identifier
         * @param {number} [maxResults] The maximum number of results to return per page.  Valid Range: Minimum value of 1. Maximum value of 1000.
         * @param {string} [nextToken] The token for the next set of results.  Use the value returned in the previous response in the next request to retrieve the next set of results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsGet(businessUnitId: string, nameAndLoginFilter?: string, routingProfileIdFilter?: Array<string>, maxResults?: number, nextToken?: string, options?: any): AxiosPromise<ListAgentResponse> {
            return localVarFp.businessUnitIdAgentsGet(businessUnitId, nameAndLoginFilter, routingProfileIdFilter, maxResults, nextToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdAgentsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new agent and associate with a business unit
         * @param {string} businessUnitId 
         * @param {CreateAgentRequest} createAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsPost(businessUnitId: string, createAgentRequest: CreateAgentRequest, options?: any): AxiosPromise<DescribeAgentResponse> {
            return localVarFp.businessUnitIdAgentsPost(businessUnitId, createAgentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve security profiles for a business unit
         * @summary Retrieve security profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsSecurityProfilesGet(businessUnitId: string, options?: any): AxiosPromise<Array<LookupItem>> {
            return localVarFp.businessUnitIdAgentsSecurityProfilesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAgentsSecurityProfilesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdAgentsSecurityProfilesOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentApi - object-oriented interface
 * @export
 * @class AgentApi
 * @extends {BaseAPI}
 */
export class AgentApi extends BaseAPI {
    /**
     * Removes an agents association with a business unit, but will not remove the agent from AWS connect
     * @summary Remove an agent from a business unit
     * @param {string} businessUnitId 
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsAgentIdDelete(businessUnitId: string, agentId: string, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsAgentIdDelete(businessUnitId, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Describe an agent for a business unit
     * @param {string} businessUnitId 
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsAgentIdGet(businessUnitId: string, agentId: string, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsAgentIdGet(businessUnitId, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsAgentIdOptions(options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsAgentIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an agents details
     * @param {string} businessUnitId 
     * @param {string} agentId 
     * @param {UpdateAgentRequest} updateAgentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsAgentIdPut(businessUnitId: string, agentId: string, updateAgentRequest: UpdateAgentRequest, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsAgentIdPut(businessUnitId, agentId, updateAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all agents for a business unit
     * @summary Retrieve all agents for a business unit
     * @param {string} businessUnitId 
     * @param {string} [nameAndLoginFilter] Filter by name and/or login
     * @param {Array<string>} [routingProfileIdFilter] Filter by routing profile identifier
     * @param {number} [maxResults] The maximum number of results to return per page.  Valid Range: Minimum value of 1. Maximum value of 1000.
     * @param {string} [nextToken] The token for the next set of results.  Use the value returned in the previous response in the next request to retrieve the next set of results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsGet(businessUnitId: string, nameAndLoginFilter?: string, routingProfileIdFilter?: Array<string>, maxResults?: number, nextToken?: string, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsGet(businessUnitId, nameAndLoginFilter, routingProfileIdFilter, maxResults, nextToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsOptions(options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new agent and associate with a business unit
     * @param {string} businessUnitId 
     * @param {CreateAgentRequest} createAgentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsPost(businessUnitId: string, createAgentRequest: CreateAgentRequest, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsPost(businessUnitId, createAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve security profiles for a business unit
     * @summary Retrieve security profiles for a business unit
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsSecurityProfilesGet(businessUnitId: string, options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsSecurityProfilesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentApi
     */
    public businessUnitIdAgentsSecurityProfilesOptions(options?: any) {
        return AgentApiFp(this.configuration).businessUnitIdAgentsSecurityProfilesOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditApi - axios parameter creator
 * @export
 */
export const AuditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuditRequest} auditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditPost: async (auditRequest: AuditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'auditRequest' is not null or undefined
            assertParamExists('auditPost', 'auditRequest', auditRequest)
            const localVarPath = `/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} startDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAuditGet: async (businessUnitId: string, startDate: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdAuditGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('businessUnitIdAuditGet', 'startDate', startDate)
            const localVarPath = `/{businessUnitId}/audit`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAuditOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditApi - functional programming interface
 * @export
 */
export const AuditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuditRequest} auditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auditPost(auditRequest: AuditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auditPost(auditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} startDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAuditGet(businessUnitId: string, startDate: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAuditGet(businessUnitId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdAuditOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdAuditOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditApi - factory interface
 * @export
 */
export const AuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditOptions(options?: any): AxiosPromise<void> {
            return localVarFp.auditOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuditRequest} auditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auditPost(auditRequest: AuditRequest, options?: any): AxiosPromise<void> {
            return localVarFp.auditPost(auditRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} startDate 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAuditGet(businessUnitId: string, startDate: string, endDate?: string, options?: any): AxiosPromise<any> {
            return localVarFp.businessUnitIdAuditGet(businessUnitId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdAuditOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdAuditOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
export class AuditApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public auditOptions(options?: any) {
        return AuditApiFp(this.configuration).auditOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuditRequest} auditRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public auditPost(auditRequest: AuditRequest, options?: any) {
        return AuditApiFp(this.configuration).auditPost(auditRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} startDate 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public businessUnitIdAuditGet(businessUnitId: string, startDate: string, endDate?: string, options?: any) {
        return AuditApiFp(this.configuration).businessUnitIdAuditGet(businessUnitId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public businessUnitIdAuditOptions(options?: any) {
        return AuditApiFp(this.configuration).businessUnitIdAuditOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessUnitsApi - axios parameter creator
 * @export
 */
export const BusinessUnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessunitsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/businessunits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessunitsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/businessunits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessUnitsApi - functional programming interface
 * @export
 */
export const BusinessUnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessUnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessunitsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessUnitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessunitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessunitsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessunitsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessUnitsApi - factory interface
 * @export
 */
export const BusinessUnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessUnitsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessunitsGet(options?: any): AxiosPromise<BusinessUnitsResponse> {
            return localVarFp.businessunitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessunitsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessunitsOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessUnitsApi - object-oriented interface
 * @export
 * @class BusinessUnitsApi
 * @extends {BaseAPI}
 */
export class BusinessUnitsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessUnitsApi
     */
    public businessunitsGet(options?: any) {
        return BusinessUnitsApiFp(this.configuration).businessunitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessUnitsApi
     */
    public businessunitsOptions(options?: any) {
        return BusinessUnitsApiFp(this.configuration).businessunitsOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EgressApi - axios parameter creator
 * @export
 */
export const EgressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gives details of an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdGet: async (businessUnitId: string, egressConfigId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigEgressConfigIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'egressConfigId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigEgressConfigIdGet', 'egressConfigId', egressConfigId)
            const localVarPath = `/{businessUnitId}/egress/config/{egressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"egressConfigId"}}`, encodeURIComponent(String(egressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/egress/config/{egressConfigId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {UpdateEgressConfig} updateEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdPut: async (businessUnitId: string, egressConfigId: string, updateEgressConfig: UpdateEgressConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigEgressConfigIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'egressConfigId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigEgressConfigIdPut', 'egressConfigId', egressConfigId)
            // verify required parameter 'updateEgressConfig' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigEgressConfigIdPut', 'updateEgressConfig', updateEgressConfig)
            const localVarPath = `/{businessUnitId}/egress/config/{egressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"egressConfigId"}}`, encodeURIComponent(String(egressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEgressConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of egress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/egress/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/egress/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an egress configuration
         * @param {string} businessUnitId 
         * @param {CreateEgressConfig} createEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigPost: async (businessUnitId: string, createEgressConfig: CreateEgressConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createEgressConfig' is not null or undefined
            assertParamExists('businessUnitIdEgressConfigPost', 'createEgressConfig', createEgressConfig)
            const localVarPath = `/{businessUnitId}/egress/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEgressConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of an egress template
         * @param {string} businessUnitId 
         * @param {string} egressTemplateId Egress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesEgressTemplateIdGet: async (businessUnitId: string, egressTemplateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressTemplatesEgressTemplateIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'egressTemplateId' is not null or undefined
            assertParamExists('businessUnitIdEgressTemplatesEgressTemplateIdGet', 'egressTemplateId', egressTemplateId)
            const localVarPath = `/{businessUnitId}/egress/templates/{egressTemplateId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"egressTemplateId"}}`, encodeURIComponent(String(egressTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesEgressTemplateIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/egress/templates/{egressTemplateId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of egress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdEgressTemplatesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/egress/templates`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/egress/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEgressConfig: async (businessUnitId: string, egressConfigId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('deleteEgressConfig', 'businessUnitId', businessUnitId)
            // verify required parameter 'egressConfigId' is not null or undefined
            assertParamExists('deleteEgressConfig', 'egressConfigId', egressConfigId)
            const localVarPath = `/{businessUnitId}/egress/config/{egressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"egressConfigId"}}`, encodeURIComponent(String(egressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EgressApi - functional programming interface
 * @export
 */
export const EgressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EgressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gives details of an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId: string, egressConfigId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeEgressConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId, egressConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigEgressConfigIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigEgressConfigIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {UpdateEgressConfig} updateEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId: string, egressConfigId: string, updateEgressConfig: UpdateEgressConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId, egressConfigId, updateEgressConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of egress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListEgressConfigItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an egress configuration
         * @param {string} businessUnitId 
         * @param {CreateEgressConfig} createEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressConfigPost(businessUnitId: string, createEgressConfig: CreateEgressConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressConfigPost(businessUnitId, createEgressConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of an egress template
         * @param {string} businessUnitId 
         * @param {string} egressTemplateId Egress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId: string, egressTemplateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EgressTemplateDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId, egressTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressTemplatesEgressTemplateIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressTemplatesEgressTemplateIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of egress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressTemplatesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListEgressTemplateItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressTemplatesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdEgressTemplatesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdEgressTemplatesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEgressConfig(businessUnitId: string, egressConfigId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEgressConfig(businessUnitId, egressConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EgressApi - factory interface
 * @export
 */
export const EgressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EgressApiFp(configuration)
    return {
        /**
         * 
         * @summary Gives details of an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId: string, egressConfigId: string, options?: any): AxiosPromise<DescribeEgressConfig> {
            return localVarFp.businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId, egressConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressConfigEgressConfigIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {UpdateEgressConfig} updateEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId: string, egressConfigId: string, updateEgressConfig: UpdateEgressConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId, egressConfigId, updateEgressConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of egress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListEgressConfigItem>> {
            return localVarFp.businessUnitIdEgressConfigGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressConfigOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an egress configuration
         * @param {string} businessUnitId 
         * @param {CreateEgressConfig} createEgressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressConfigPost(businessUnitId: string, createEgressConfig: CreateEgressConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressConfigPost(businessUnitId, createEgressConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of an egress template
         * @param {string} businessUnitId 
         * @param {string} egressTemplateId Egress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId: string, egressTemplateId: string, options?: any): AxiosPromise<EgressTemplateDetailResponse> {
            return localVarFp.businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId, egressTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesEgressTemplateIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressTemplatesEgressTemplateIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of egress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListEgressTemplateItem>> {
            return localVarFp.businessUnitIdEgressTemplatesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdEgressTemplatesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdEgressTemplatesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an egress configuration
         * @param {string} businessUnitId 
         * @param {string} egressConfigId Egress config unique identifier (globally unique)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEgressConfig(businessUnitId: string, egressConfigId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEgressConfig(businessUnitId, egressConfigId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EgressApi - object-oriented interface
 * @export
 * @class EgressApi
 * @extends {BaseAPI}
 */
export class EgressApi extends BaseAPI {
    /**
     * 
     * @summary Gives details of an egress configuration
     * @param {string} businessUnitId 
     * @param {string} egressConfigId Egress config unique identifier (globally unique)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId: string, egressConfigId: string, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigEgressConfigIdGet(businessUnitId, egressConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigEgressConfigIdOptions(options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigEgressConfigIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an egress configuration
     * @param {string} businessUnitId 
     * @param {string} egressConfigId Egress config unique identifier (globally unique)
     * @param {UpdateEgressConfig} updateEgressConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId: string, egressConfigId: string, updateEgressConfig: UpdateEgressConfig, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigEgressConfigIdPut(businessUnitId, egressConfigId, updateEgressConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of egress configuration
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigGet(businessUnitId: string, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigOptions(options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an egress configuration
     * @param {string} businessUnitId 
     * @param {CreateEgressConfig} createEgressConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressConfigPost(businessUnitId: string, createEgressConfig: CreateEgressConfig, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressConfigPost(businessUnitId, createEgressConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of an egress template
     * @param {string} businessUnitId 
     * @param {string} egressTemplateId Egress template unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId: string, egressTemplateId: string, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressTemplatesEgressTemplateIdGet(businessUnitId, egressTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressTemplatesEgressTemplateIdOptions(options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressTemplatesEgressTemplateIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of egress templates
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressTemplatesGet(businessUnitId: string, options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressTemplatesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public businessUnitIdEgressTemplatesOptions(options?: any) {
        return EgressApiFp(this.configuration).businessUnitIdEgressTemplatesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an egress configuration
     * @param {string} businessUnitId 
     * @param {string} egressConfigId Egress config unique identifier (globally unique)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EgressApi
     */
    public deleteEgressConfig(businessUnitId: string, egressConfigId: string, options?: any) {
        return EgressApiFp(this.configuration).deleteEgressConfig(businessUnitId, egressConfigId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlowAttributesApi - axios parameter creator
 * @export
 */
export const FlowAttributesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesAttributeTypeOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/attributes/{attributeType}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesAttributeTypeUnassignedOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/attributes/{attributeType}/unassigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of flow attribute types
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowAttributesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/flow/attributes`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of flow attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeValues: async (businessUnitId: string, attributeType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('getAttributeValues', 'businessUnitId', businessUnitId)
            // verify required parameter 'attributeType' is not null or undefined
            assertParamExists('getAttributeValues', 'attributeType', attributeType)
            const localVarPath = `/{businessUnitId}/flow/attributes/{attributeType}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"attributeType"}}`, encodeURIComponent(String(attributeType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of unassigned attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedAttributeValues: async (businessUnitId: string, attributeType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('getUnassignedAttributeValues', 'businessUnitId', businessUnitId)
            // verify required parameter 'attributeType' is not null or undefined
            assertParamExists('getUnassignedAttributeValues', 'attributeType', attributeType)
            const localVarPath = `/{businessUnitId}/flow/attributes/{attributeType}/unassigned`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"attributeType"}}`, encodeURIComponent(String(attributeType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowAttributesApi - functional programming interface
 * @export
 */
export const FlowAttributesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowAttributesApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowAttributesAttributeTypeOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowAttributesAttributeTypeOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of flow attribute types
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowAttributesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListFlowAttribute>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowAttributesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowAttributesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowAttributesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of flow attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributeValues(businessUnitId: string, attributeType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttributeValueItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributeValues(businessUnitId, attributeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of unassigned attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnassignedAttributeValues(businessUnitId: string, attributeType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttributeValueItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnassignedAttributeValues(businessUnitId, attributeType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowAttributesApi - factory interface
 * @export
 */
export const FlowAttributesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowAttributesApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesAttributeTypeOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowAttributesAttributeTypeOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of flow attribute types
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListFlowAttribute>> {
            return localVarFp.businessUnitIdFlowAttributesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowAttributesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowAttributesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of flow attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeValues(businessUnitId: string, attributeType: string, options?: any): AxiosPromise<Array<AttributeValueItem>> {
            return localVarFp.getAttributeValues(businessUnitId, attributeType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of unassigned attribute type values
         * @param {string} businessUnitId 
         * @param {string} attributeType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnassignedAttributeValues(businessUnitId: string, attributeType: string, options?: any): AxiosPromise<Array<AttributeValueItem>> {
            return localVarFp.getUnassignedAttributeValues(businessUnitId, attributeType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowAttributesApi - object-oriented interface
 * @export
 * @class FlowAttributesApi
 * @extends {BaseAPI}
 */
export class FlowAttributesApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public businessUnitIdFlowAttributesAttributeTypeOptions(options?: any) {
        return FlowAttributesApiFp(this.configuration).businessUnitIdFlowAttributesAttributeTypeOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options?: any) {
        return FlowAttributesApiFp(this.configuration).businessUnitIdFlowAttributesAttributeTypeUnassignedOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of flow attribute types
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public businessUnitIdFlowAttributesGet(businessUnitId: string, options?: any) {
        return FlowAttributesApiFp(this.configuration).businessUnitIdFlowAttributesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public businessUnitIdFlowAttributesOptions(options?: any) {
        return FlowAttributesApiFp(this.configuration).businessUnitIdFlowAttributesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of flow attribute type values
     * @param {string} businessUnitId 
     * @param {string} attributeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public getAttributeValues(businessUnitId: string, attributeType: string, options?: any) {
        return FlowAttributesApiFp(this.configuration).getAttributeValues(businessUnitId, attributeType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of unassigned attribute type values
     * @param {string} businessUnitId 
     * @param {string} attributeType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowAttributesApi
     */
    public getUnassignedAttributeValues(businessUnitId: string, attributeType: string, options?: any) {
        return FlowAttributesApiFp(this.configuration).getUnassignedAttributeValues(businessUnitId, attributeType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlowConfigApi - axios parameter creator
 * @export
 */
export const FlowConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowConfigId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdDelete: async (businessUnitId: string, flowConfigId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'flowConfigId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdDelete', 'flowConfigId', flowConfigId)
            const localVarPath = `/{businessUnitId}/flow/config/{flowConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"flowConfigId"}}`, encodeURIComponent(String(flowConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of a flow configuration
         * @param {string} flowConfigId flow config unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdGet: async (flowConfigId: string, businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowConfigId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdGet', 'flowConfigId', flowConfigId)
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/flow/config/{flowConfigId}`
                .replace(`{${"flowConfigId"}}`, encodeURIComponent(String(flowConfigId)))
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/config/{flowConfigId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a flow configuration
         * @param {string} flowConfigId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowConfig} updateFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdPut: async (flowConfigId: string, businessUnitId: string, updateFlowConfig: UpdateFlowConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowConfigId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdPut', 'flowConfigId', flowConfigId)
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'updateFlowConfig' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigFlowConfigIdPut', 'updateFlowConfig', updateFlowConfig)
            const localVarPath = `/{businessUnitId}/flow/config/{flowConfigId}`
                .replace(`{${"flowConfigId"}}`, encodeURIComponent(String(flowConfigId)))
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlowConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of flow configurations
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/flow/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a flow configuration
         * @param {string} businessUnitId 
         * @param {CreateFlowConfig} createFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigPost: async (businessUnitId: string, createFlowConfig: CreateFlowConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createFlowConfig' is not null or undefined
            assertParamExists('businessUnitIdFlowConfigPost', 'createFlowConfig', createFlowConfig)
            const localVarPath = `/{businessUnitId}/flow/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlowConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowConfigApi - functional programming interface
 * @export
 */
export const FlowConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowConfigId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId: string, flowConfigId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId, flowConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of a flow configuration
         * @param {string} flowConfigId flow config unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId: string, businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeFlowConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId, businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigFlowConfigIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigFlowConfigIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a flow configuration
         * @param {string} flowConfigId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowConfig} updateFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId: string, businessUnitId: string, updateFlowConfig: UpdateFlowConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId, businessUnitId, updateFlowConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of flow configurations
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListFlowConfigItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a flow configuration
         * @param {string} businessUnitId 
         * @param {CreateFlowConfig} createFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowConfigPost(businessUnitId: string, createFlowConfig: CreateFlowConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowConfigPost(businessUnitId, createFlowConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowConfigApi - factory interface
 * @export
 */
export const FlowConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowConfigId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId: string, flowConfigId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId, flowConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of a flow configuration
         * @param {string} flowConfigId flow config unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId: string, businessUnitId: string, options?: any): AxiosPromise<DescribeFlowConfig> {
            return localVarFp.businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId, businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowConfigFlowConfigIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a flow configuration
         * @param {string} flowConfigId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowConfig} updateFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId: string, businessUnitId: string, updateFlowConfig: UpdateFlowConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId, businessUnitId, updateFlowConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of flow configurations
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListFlowConfigItem>> {
            return localVarFp.businessUnitIdFlowConfigGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowConfigOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a flow configuration
         * @param {string} businessUnitId 
         * @param {CreateFlowConfig} createFlowConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowConfigPost(businessUnitId: string, createFlowConfig: CreateFlowConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowConfigPost(businessUnitId, createFlowConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowConfigApi - object-oriented interface
 * @export
 * @class FlowConfigApi
 * @extends {BaseAPI}
 */
export class FlowConfigApi extends BaseAPI {
    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} flowConfigId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId: string, flowConfigId: string, options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigFlowConfigIdDelete(businessUnitId, flowConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of a flow configuration
     * @param {string} flowConfigId flow config unique identifier
     * @param {string} businessUnitId Business unit unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId: string, businessUnitId: string, options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigFlowConfigIdGet(flowConfigId, businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigFlowConfigIdOptions(options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigFlowConfigIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a flow configuration
     * @param {string} flowConfigId 
     * @param {string} businessUnitId 
     * @param {UpdateFlowConfig} updateFlowConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId: string, businessUnitId: string, updateFlowConfig: UpdateFlowConfig, options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigFlowConfigIdPut(flowConfigId, businessUnitId, updateFlowConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of flow configurations
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigGet(businessUnitId: string, options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigOptions(options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a flow configuration
     * @param {string} businessUnitId 
     * @param {CreateFlowConfig} createFlowConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowConfigApi
     */
    public businessUnitIdFlowConfigPost(businessUnitId: string, createFlowConfig: CreateFlowConfig, options?: any) {
        return FlowConfigApiFp(this.configuration).businessUnitIdFlowConfigPost(businessUnitId, createFlowConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlowTemplatesApi - axios parameter creator
 * @export
 */
export const FlowTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdDelete: async (businessUnitId: string, flowTemplateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'flowTemplateId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdDelete', 'flowTemplateId', flowTemplateId)
            const localVarPath = `/{businessUnitId}/flow/templates/{flowTemplateId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"flowTemplateId"}}`, encodeURIComponent(String(flowTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of a flow template
         * @param {string} flowTemplateId flow template unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdGet: async (flowTemplateId: string, businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowTemplateId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdGet', 'flowTemplateId', flowTemplateId)
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/flow/templates/{flowTemplateId}`
                .replace(`{${"flowTemplateId"}}`, encodeURIComponent(String(flowTemplateId)))
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/templates/{flowTemplateId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a flow template
         * @param {string} flowTemplateId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowTemplate} updateFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdPut: async (flowTemplateId: string, businessUnitId: string, updateFlowTemplate: UpdateFlowTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'flowTemplateId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdPut', 'flowTemplateId', flowTemplateId)
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'updateFlowTemplate' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesFlowTemplateIdPut', 'updateFlowTemplate', updateFlowTemplate)
            const localVarPath = `/{businessUnitId}/flow/templates/{flowTemplateId}`
                .replace(`{${"flowTemplateId"}}`, encodeURIComponent(String(flowTemplateId)))
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlowTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of flow templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/flow/templates`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/flow/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a flow template and assign to an AWS control flow
         * @param {string} businessUnitId 
         * @param {CreateFlowTemplate} createFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesPost: async (businessUnitId: string, createFlowTemplate: CreateFlowTemplate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createFlowTemplate' is not null or undefined
            assertParamExists('businessUnitIdFlowTemplatesPost', 'createFlowTemplate', createFlowTemplate)
            const localVarPath = `/{businessUnitId}/flow/templates`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlowTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlowTemplatesApi - functional programming interface
 * @export
 */
export const FlowTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlowTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId: string, flowTemplateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId, flowTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of a flow template
         * @param {string} flowTemplateId flow template unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId: string, businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeFlowTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId, businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesFlowTemplateIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesFlowTemplateIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a flow template
         * @param {string} flowTemplateId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowTemplate} updateFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId: string, businessUnitId: string, updateFlowTemplate: UpdateFlowTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId, businessUnitId, updateFlowTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of flow templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListFlowTemplateItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a flow template and assign to an AWS control flow
         * @param {string} businessUnitId 
         * @param {CreateFlowTemplate} createFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdFlowTemplatesPost(businessUnitId: string, createFlowTemplate: CreateFlowTemplate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdFlowTemplatesPost(businessUnitId, createFlowTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlowTemplatesApi - factory interface
 * @export
 */
export const FlowTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlowTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} flowTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId: string, flowTemplateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId, flowTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of a flow template
         * @param {string} flowTemplateId flow template unique identifier
         * @param {string} businessUnitId Business unit unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId: string, businessUnitId: string, options?: any): AxiosPromise<DescribeFlowTemplate> {
            return localVarFp.businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId, businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowTemplatesFlowTemplateIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a flow template
         * @param {string} flowTemplateId 
         * @param {string} businessUnitId 
         * @param {UpdateFlowTemplate} updateFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId: string, businessUnitId: string, updateFlowTemplate: UpdateFlowTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId, businessUnitId, updateFlowTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of flow templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListFlowTemplateItem>> {
            return localVarFp.businessUnitIdFlowTemplatesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowTemplatesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a flow template and assign to an AWS control flow
         * @param {string} businessUnitId 
         * @param {CreateFlowTemplate} createFlowTemplate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdFlowTemplatesPost(businessUnitId: string, createFlowTemplate: CreateFlowTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdFlowTemplatesPost(businessUnitId, createFlowTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlowTemplatesApi - object-oriented interface
 * @export
 * @class FlowTemplatesApi
 * @extends {BaseAPI}
 */
export class FlowTemplatesApi extends BaseAPI {
    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} flowTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId: string, flowTemplateId: string, options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesFlowTemplateIdDelete(businessUnitId, flowTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of a flow template
     * @param {string} flowTemplateId flow template unique identifier
     * @param {string} businessUnitId Business unit unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId: string, businessUnitId: string, options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesFlowTemplateIdGet(flowTemplateId, businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesFlowTemplateIdOptions(options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesFlowTemplateIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a flow template
     * @param {string} flowTemplateId 
     * @param {string} businessUnitId 
     * @param {UpdateFlowTemplate} updateFlowTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId: string, businessUnitId: string, updateFlowTemplate: UpdateFlowTemplate, options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesFlowTemplateIdPut(flowTemplateId, businessUnitId, updateFlowTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of flow templates
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesGet(businessUnitId: string, options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesOptions(options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a flow template and assign to an AWS control flow
     * @param {string} businessUnitId 
     * @param {CreateFlowTemplate} createFlowTemplate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlowTemplatesApi
     */
    public businessUnitIdFlowTemplatesPost(businessUnitId: string, createFlowTemplate: CreateFlowTemplate, options?: any) {
        return FlowTemplatesApiFp(this.configuration).businessUnitIdFlowTemplatesPost(businessUnitId, createFlowTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IngressApi - axios parameter creator
 * @export
 */
export const IngressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigEmergencyOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/config/emergency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets an emergency closure on multiple ingress configurations
         * @param {string} businessUnitId 
         * @param {CreateEmergencyClosure} createEmergencyClosure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigEmergencyPost: async (businessUnitId: string, createEmergencyClosure: CreateEmergencyClosure, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigEmergencyPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createEmergencyClosure' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigEmergencyPost', 'createEmergencyClosure', createEmergencyClosure)
            const localVarPath = `/{businessUnitId}/ingress/config/emergency`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEmergencyClosure, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of ingress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/ingress/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdGet: async (businessUnitId: string, ingressConfigId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigIngressConfigIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'ingressConfigId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigIngressConfigIdGet', 'ingressConfigId', ingressConfigId)
            const localVarPath = `/{businessUnitId}/ingress/config/{ingressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"ingressConfigId"}}`, encodeURIComponent(String(ingressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/config/{ingressConfigId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {UpdateIngressConfig} updateIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdPut: async (businessUnitId: string, ingressConfigId: string, updateIngressConfig: UpdateIngressConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigIngressConfigIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'ingressConfigId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigIngressConfigIdPut', 'ingressConfigId', ingressConfigId)
            // verify required parameter 'updateIngressConfig' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigIngressConfigIdPut', 'updateIngressConfig', updateIngressConfig)
            const localVarPath = `/{businessUnitId}/ingress/config/{ingressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"ingressConfigId"}}`, encodeURIComponent(String(ingressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIngressConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an ingress configuration
         * @param {string} businessUnitId 
         * @param {CreateIngressConfig} createIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigPost: async (businessUnitId: string, createIngressConfig: CreateIngressConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createIngressConfig' is not null or undefined
            assertParamExists('businessUnitIdIngressConfigPost', 'createIngressConfig', createIngressConfig)
            const localVarPath = `/{businessUnitId}/ingress/config`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createIngressConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of DDI phone numbers
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressPhoneNumbersGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressPhoneNumbersGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/ingress/phoneNumbers`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressPhoneNumbersOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/phoneNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of ingress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressTemplatesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/ingress/templates`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of an ingress template
         * @param {string} businessUnitId 
         * @param {string} ingressTemplateId Ingress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesIngressTemplateIdGet: async (businessUnitId: string, ingressTemplateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdIngressTemplatesIngressTemplateIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'ingressTemplateId' is not null or undefined
            assertParamExists('businessUnitIdIngressTemplatesIngressTemplateIdGet', 'ingressTemplateId', ingressTemplateId)
            const localVarPath = `/{businessUnitId}/ingress/templates/{ingressTemplateId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"ingressTemplateId"}}`, encodeURIComponent(String(ingressTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesIngressTemplateIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/templates/{ingressTemplateId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/ingress/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIngressConfig: async (businessUnitId: string, ingressConfigId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('deleteIngressConfig', 'businessUnitId', businessUnitId)
            // verify required parameter 'ingressConfigId' is not null or undefined
            assertParamExists('deleteIngressConfig', 'ingressConfigId', ingressConfigId)
            const localVarPath = `/{businessUnitId}/ingress/config/{ingressConfigId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"ingressConfigId"}}`, encodeURIComponent(String(ingressConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngressApi - functional programming interface
 * @export
 */
export const IngressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngressApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigEmergencyOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigEmergencyOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets an emergency closure on multiple ingress configurations
         * @param {string} businessUnitId 
         * @param {CreateEmergencyClosure} createEmergencyClosure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigEmergencyPost(businessUnitId: string, createEmergencyClosure: CreateEmergencyClosure, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigEmergencyPost(businessUnitId, createEmergencyClosure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of ingress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListIngressConfigItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId: string, ingressConfigId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DescribeIngressConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId, ingressConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigIngressConfigIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigIngressConfigIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {UpdateIngressConfig} updateIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId: string, ingressConfigId: string, updateIngressConfig: UpdateIngressConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId, ingressConfigId, updateIngressConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an ingress configuration
         * @param {string} businessUnitId 
         * @param {CreateIngressConfig} createIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressConfigPost(businessUnitId: string, createIngressConfig: CreateIngressConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressConfigPost(businessUnitId, createIngressConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of DDI phone numbers
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressPhoneNumbersGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DescribePhoneNumber>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressPhoneNumbersGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressPhoneNumbersOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressPhoneNumbersOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of ingress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressTemplatesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListIngressTemplateItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressTemplatesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of an ingress template
         * @param {string} businessUnitId 
         * @param {string} ingressTemplateId Ingress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId: string, ingressTemplateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngressTemplateDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId, ingressTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressTemplatesIngressTemplateIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressTemplatesIngressTemplateIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdIngressTemplatesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdIngressTemplatesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteIngressConfig(businessUnitId: string, ingressConfigId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteIngressConfig(businessUnitId, ingressConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngressApi - factory interface
 * @export
 */
export const IngressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngressApiFp(configuration)
    return {
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigEmergencyOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigEmergencyOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets an emergency closure on multiple ingress configurations
         * @param {string} businessUnitId 
         * @param {CreateEmergencyClosure} createEmergencyClosure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigEmergencyPost(businessUnitId: string, createEmergencyClosure: CreateEmergencyClosure, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigEmergencyPost(businessUnitId, createEmergencyClosure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of ingress configuration
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListIngressConfigItem>> {
            return localVarFp.businessUnitIdIngressConfigGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId: string, ingressConfigId: string, options?: any): AxiosPromise<DescribeIngressConfig> {
            return localVarFp.businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId, ingressConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigIngressConfigIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {UpdateIngressConfig} updateIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId: string, ingressConfigId: string, updateIngressConfig: UpdateIngressConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId, ingressConfigId, updateIngressConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an ingress configuration
         * @param {string} businessUnitId 
         * @param {CreateIngressConfig} createIngressConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressConfigPost(businessUnitId: string, createIngressConfig: CreateIngressConfig, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressConfigPost(businessUnitId, createIngressConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of DDI phone numbers
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressPhoneNumbersGet(businessUnitId: string, options?: any): AxiosPromise<Array<DescribePhoneNumber>> {
            return localVarFp.businessUnitIdIngressPhoneNumbersGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressPhoneNumbersOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressPhoneNumbersOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of ingress templates
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesGet(businessUnitId: string, options?: any): AxiosPromise<Array<ListIngressTemplateItem>> {
            return localVarFp.businessUnitIdIngressTemplatesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of an ingress template
         * @param {string} businessUnitId 
         * @param {string} ingressTemplateId Ingress template unique identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId: string, ingressTemplateId: string, options?: any): AxiosPromise<IngressTemplateDetailResponse> {
            return localVarFp.businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId, ingressTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesIngressTemplateIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressTemplatesIngressTemplateIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdIngressTemplatesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdIngressTemplatesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an ingress configuration
         * @param {string} businessUnitId 
         * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIngressConfig(businessUnitId: string, ingressConfigId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteIngressConfig(businessUnitId, ingressConfigId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngressApi - object-oriented interface
 * @export
 * @class IngressApi
 * @extends {BaseAPI}
 */
export class IngressApi extends BaseAPI {
    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigEmergencyOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigEmergencyOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets an emergency closure on multiple ingress configurations
     * @param {string} businessUnitId 
     * @param {CreateEmergencyClosure} createEmergencyClosure 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigEmergencyPost(businessUnitId: string, createEmergencyClosure: CreateEmergencyClosure, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigEmergencyPost(businessUnitId, createEmergencyClosure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of ingress configuration
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigGet(businessUnitId: string, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of an ingress configuration
     * @param {string} businessUnitId 
     * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId: string, ingressConfigId: string, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigIngressConfigIdGet(businessUnitId, ingressConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigIngressConfigIdOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigIngressConfigIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an ingress configuration
     * @param {string} businessUnitId 
     * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
     * @param {UpdateIngressConfig} updateIngressConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId: string, ingressConfigId: string, updateIngressConfig: UpdateIngressConfig, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigIngressConfigIdPut(businessUnitId, ingressConfigId, updateIngressConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an ingress configuration
     * @param {string} businessUnitId 
     * @param {CreateIngressConfig} createIngressConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressConfigPost(businessUnitId: string, createIngressConfig: CreateIngressConfig, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressConfigPost(businessUnitId, createIngressConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of DDI phone numbers
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressPhoneNumbersGet(businessUnitId: string, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressPhoneNumbersGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressPhoneNumbersOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressPhoneNumbersOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of ingress templates
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressTemplatesGet(businessUnitId: string, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressTemplatesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of an ingress template
     * @param {string} businessUnitId 
     * @param {string} ingressTemplateId Ingress template unique identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId: string, ingressTemplateId: string, options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressTemplatesIngressTemplateIdGet(businessUnitId, ingressTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressTemplatesIngressTemplateIdOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressTemplatesIngressTemplateIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public businessUnitIdIngressTemplatesOptions(options?: any) {
        return IngressApiFp(this.configuration).businessUnitIdIngressTemplatesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an ingress configuration
     * @param {string} businessUnitId 
     * @param {string} ingressConfigId Ingress config unique identifier (globally unique) - DDI (direct dial in) international phone format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngressApi
     */
    public deleteIngressConfig(businessUnitId: string, ingressConfigId: string, options?: any) {
        return IngressApiFp(this.configuration).deleteIngressConfig(businessUnitId, ingressConfigId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OperationalHoursApi - axios parameter creator
 * @export
 */
export const OperationalHoursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/operationalhours`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdDelete: async (businessUnitId: string, operationalHoursId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'operationalHoursId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdDelete', 'operationalHoursId', operationalHoursId)
            const localVarPath = `/{businessUnitId}/operationalhours/{operationalHoursId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"operationalHoursId"}}`, encodeURIComponent(String(operationalHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdGet: async (businessUnitId: string, operationalHoursId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'operationalHoursId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdGet', 'operationalHoursId', operationalHoursId)
            const localVarPath = `/{businessUnitId}/operationalhours/{operationalHoursId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"operationalHoursId"}}`, encodeURIComponent(String(operationalHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/operationalhours/{operationalHoursId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdPut: async (businessUnitId: string, operationalHoursId: string, operationalHoursRequest: OperationalHoursRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'operationalHoursId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdPut', 'operationalHoursId', operationalHoursId)
            // verify required parameter 'operationalHoursRequest' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursOperationalHoursIdPut', 'operationalHoursRequest', operationalHoursRequest)
            const localVarPath = `/{businessUnitId}/operationalhours/{operationalHoursId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"operationalHoursId"}}`, encodeURIComponent(String(operationalHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operationalHoursRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/operationalhours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursPost: async (businessUnitId: string, operationalHoursRequest: OperationalHoursRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'operationalHoursRequest' is not null or undefined
            assertParamExists('businessUnitIdOperationalhoursPost', 'operationalHoursRequest', operationalHoursRequest)
            const localVarPath = `/{businessUnitId}/operationalhours`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(operationalHoursRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationalHoursApi - functional programming interface
 * @export
 */
export const OperationalHoursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OperationalHoursApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationalHoursSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId: string, operationalHoursId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId, operationalHoursId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId: string, operationalHoursId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationalHoursDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId, operationalHoursId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursOperationalHoursIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursOperationalHoursIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId: string, operationalHoursId: string, operationalHoursRequest: OperationalHoursRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationalHoursDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId, operationalHoursId, operationalHoursRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdOperationalhoursPost(businessUnitId: string, operationalHoursRequest: OperationalHoursRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperationalHoursDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdOperationalhoursPost(businessUnitId, operationalHoursRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OperationalHoursApi - factory interface
 * @export
 */
export const OperationalHoursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OperationalHoursApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursGet(businessUnitId: string, options?: any): AxiosPromise<OperationalHoursSummaryResponse> {
            return localVarFp.businessUnitIdOperationalhoursGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId: string, operationalHoursId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId, operationalHoursId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId: string, operationalHoursId: string, options?: any): AxiosPromise<OperationalHoursDetailResponse> {
            return localVarFp.businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId, operationalHoursId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdOperationalhoursOperationalHoursIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {string} operationalHoursId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId: string, operationalHoursId: string, operationalHoursRequest: OperationalHoursRequest, options?: any): AxiosPromise<OperationalHoursDetailResponse> {
            return localVarFp.businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId, operationalHoursId, operationalHoursRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdOperationalhoursOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {OperationalHoursRequest} operationalHoursRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdOperationalhoursPost(businessUnitId: string, operationalHoursRequest: OperationalHoursRequest, options?: any): AxiosPromise<OperationalHoursDetailResponse> {
            return localVarFp.businessUnitIdOperationalhoursPost(businessUnitId, operationalHoursRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OperationalHoursApi - object-oriented interface
 * @export
 * @class OperationalHoursApi
 * @extends {BaseAPI}
 */
export class OperationalHoursApi extends BaseAPI {
    /**
     * 
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursGet(businessUnitId: string, options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} operationalHoursId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId: string, operationalHoursId: string, options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursOperationalHoursIdDelete(businessUnitId, operationalHoursId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} operationalHoursId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId: string, operationalHoursId: string, options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursOperationalHoursIdGet(businessUnitId, operationalHoursId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursOperationalHoursIdOptions(options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursOperationalHoursIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {string} operationalHoursId 
     * @param {OperationalHoursRequest} operationalHoursRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId: string, operationalHoursId: string, operationalHoursRequest: OperationalHoursRequest, options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursOperationalHoursIdPut(businessUnitId, operationalHoursId, operationalHoursRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursOptions(options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {OperationalHoursRequest} operationalHoursRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationalHoursApi
     */
    public businessUnitIdOperationalhoursPost(businessUnitId: string, operationalHoursRequest: OperationalHoursRequest, options?: any) {
        return OperationalHoursApiFp(this.configuration).businessUnitIdOperationalhoursPost(businessUnitId, operationalHoursRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueuesApi - axios parameter creator
 * @export
 */
export const QueuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/queues`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of hours of operation for queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesHoursOfOperationGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesHoursOfOperationGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/queues/hoursOfOperation`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesHoursOfOperationOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/queues/hoursOfOperation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/queues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new queue
         * @param {string} businessUnitId 
         * @param {CreateQueueRequest} createQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesPost: async (businessUnitId: string, createQueueRequest: CreateQueueRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createQueueRequest' is not null or undefined
            assertParamExists('businessUnitIdQueuesPost', 'createQueueRequest', createQueueRequest)
            const localVarPath = `/{businessUnitId}/queues`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gives details of a queue
         * @param {string} queueId Queue unique identifier
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdGet: async (queueId: string, businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdGet', 'queueId', queueId)
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/queues/{queueId}`
                .replace(`{${"queueId"}}`, encodeURIComponent(String(queueId)))
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/queues/{queueId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates queue details
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueRequest} [updateQueueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdPut: async (businessUnitId: string, queueId: string, updateQueueRequest?: UpdateQueueRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdPut', 'queueId', queueId)
            const localVarPath = `/{businessUnitId}/queues/{queueId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"queueId"}}`, encodeURIComponent(String(queueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateQueueRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdStatusOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/queues/{queueId}/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates queue status
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueStatusRequest} [updateQueueStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdStatusPut: async (businessUnitId: string, queueId: string, updateQueueStatusRequest?: UpdateQueueStatusRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdStatusPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'queueId' is not null or undefined
            assertParamExists('businessUnitIdQueuesQueueIdStatusPut', 'queueId', queueId)
            const localVarPath = `/{businessUnitId}/queues/{queueId}/status`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"queueId"}}`, encodeURIComponent(String(queueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateQueueStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueuesApi - functional programming interface
 * @export
 */
export const QueuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListQueuesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a list of hours of operation for queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesHoursOfOperationGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListQueuesHoursOfOperationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesHoursOfOperationGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesHoursOfOperationOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesHoursOfOperationOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new queue
         * @param {string} businessUnitId 
         * @param {CreateQueueRequest} createQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesPost(businessUnitId: string, createQueueRequest: CreateQueueRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesPost(businessUnitId, createQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gives details of a queue
         * @param {string} queueId Queue unique identifier
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesQueueIdGet(queueId: string, businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesQueueIdGet(queueId, businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesQueueIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesQueueIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates queue details
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueRequest} [updateQueueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesQueueIdPut(businessUnitId: string, queueId: string, updateQueueRequest?: UpdateQueueRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesQueueIdPut(businessUnitId, queueId, updateQueueRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesQueueIdStatusOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesQueueIdStatusOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates queue status
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueStatusRequest} [updateQueueStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQueuesQueueIdStatusPut(businessUnitId: string, queueId: string, updateQueueStatusRequest?: UpdateQueueStatusRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQueuesQueueIdStatusPut(businessUnitId, queueId, updateQueueStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueuesApi - factory interface
 * @export
 */
export const QueuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueuesApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesGet(businessUnitId: string, options?: any): AxiosPromise<ListQueuesResponse> {
            return localVarFp.businessUnitIdQueuesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of hours of operation for queues
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesHoursOfOperationGet(businessUnitId: string, options?: any): AxiosPromise<ListQueuesHoursOfOperationResponse> {
            return localVarFp.businessUnitIdQueuesHoursOfOperationGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesHoursOfOperationOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQueuesHoursOfOperationOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQueuesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new queue
         * @param {string} businessUnitId 
         * @param {CreateQueueRequest} createQueueRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesPost(businessUnitId: string, createQueueRequest: CreateQueueRequest, options?: any): AxiosPromise<QueueDetailResponse> {
            return localVarFp.businessUnitIdQueuesPost(businessUnitId, createQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gives details of a queue
         * @param {string} queueId Queue unique identifier
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdGet(queueId: string, businessUnitId: string, options?: any): AxiosPromise<QueueDetailResponse> {
            return localVarFp.businessUnitIdQueuesQueueIdGet(queueId, businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQueuesQueueIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates queue details
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueRequest} [updateQueueRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdPut(businessUnitId: string, queueId: string, updateQueueRequest?: UpdateQueueRequest, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQueuesQueueIdPut(businessUnitId, queueId, updateQueueRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdStatusOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQueuesQueueIdStatusOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates queue status
         * @param {string} businessUnitId 
         * @param {string} queueId Queue unique identifier
         * @param {UpdateQueueStatusRequest} [updateQueueStatusRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQueuesQueueIdStatusPut(businessUnitId: string, queueId: string, updateQueueStatusRequest?: UpdateQueueStatusRequest, options?: any): AxiosPromise<QueueStatusResponse> {
            return localVarFp.businessUnitIdQueuesQueueIdStatusPut(businessUnitId, queueId, updateQueueStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueuesApi - object-oriented interface
 * @export
 * @class QueuesApi
 * @extends {BaseAPI}
 */
export class QueuesApi extends BaseAPI {
    /**
     * 
     * @summary Gets a list of queues
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesGet(businessUnitId: string, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of hours of operation for queues
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesHoursOfOperationGet(businessUnitId: string, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesHoursOfOperationGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesHoursOfOperationOptions(options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesHoursOfOperationOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesOptions(options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new queue
     * @param {string} businessUnitId 
     * @param {CreateQueueRequest} createQueueRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesPost(businessUnitId: string, createQueueRequest: CreateQueueRequest, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesPost(businessUnitId, createQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gives details of a queue
     * @param {string} queueId Queue unique identifier
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesQueueIdGet(queueId: string, businessUnitId: string, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesQueueIdGet(queueId, businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesQueueIdOptions(options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesQueueIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates queue details
     * @param {string} businessUnitId 
     * @param {string} queueId Queue unique identifier
     * @param {UpdateQueueRequest} [updateQueueRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesQueueIdPut(businessUnitId: string, queueId: string, updateQueueRequest?: UpdateQueueRequest, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesQueueIdPut(businessUnitId, queueId, updateQueueRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesQueueIdStatusOptions(options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesQueueIdStatusOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates queue status
     * @param {string} businessUnitId 
     * @param {string} queueId Queue unique identifier
     * @param {UpdateQueueStatusRequest} [updateQueueStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueuesApi
     */
    public businessUnitIdQueuesQueueIdStatusPut(businessUnitId: string, queueId: string, updateQueueStatusRequest?: UpdateQueueStatusRequest, options?: any) {
        return QueuesApiFp(this.configuration).businessUnitIdQueuesQueueIdStatusPut(businessUnitId, queueId, updateQueueStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuickConnectsApi - axios parameter creator
 * @export
 */
export const QuickConnectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of quick connects
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQuickConnectsGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdQuickConnectsGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/quickConnects`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQuickConnectsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/quickConnects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuickConnectsApi - functional programming interface
 * @export
 */
export const QuickConnectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuickConnectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of quick connects
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQuickConnectsGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListQuickConnectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQuickConnectsGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdQuickConnectsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdQuickConnectsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuickConnectsApi - factory interface
 * @export
 */
export const QuickConnectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuickConnectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of quick connects
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQuickConnectsGet(businessUnitId: string, options?: any): AxiosPromise<ListQuickConnectsResponse> {
            return localVarFp.businessUnitIdQuickConnectsGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdQuickConnectsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdQuickConnectsOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuickConnectsApi - object-oriented interface
 * @export
 * @class QuickConnectsApi
 * @extends {BaseAPI}
 */
export class QuickConnectsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a list of quick connects
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickConnectsApi
     */
    public businessUnitIdQuickConnectsGet(businessUnitId: string, options?: any) {
        return QuickConnectsApiFp(this.configuration).businessUnitIdQuickConnectsGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuickConnectsApi
     */
    public businessUnitIdQuickConnectsOptions(options?: any) {
        return QuickConnectsApiFp(this.configuration).businessUnitIdQuickConnectsOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPost: async (rolePermissionsRequest: RolePermissionsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rolePermissionsRequest' is not null or undefined
            assertParamExists('rolesPost', 'rolePermissionsRequest', rolePermissionsRequest)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolePermissionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdDelete: async (roleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('rolesRoleIdDelete', 'roleId', roleId)
            const localVarPath = `/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles/{roleId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} roleId 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdPut: async (roleId: string, rolePermissionsRequest: RolePermissionsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('rolesRoleIdPut', 'roleId', roleId)
            // verify required parameter 'rolePermissionsRequest' is not null or undefined
            assertParamExists('rolesRoleIdPut', 'rolePermissionsRequest', rolePermissionsRequest)
            const localVarPath = `/roles/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rolePermissionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesAndPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesPost(rolePermissionsRequest: RolePermissionsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAndPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesPost(rolePermissionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRoleIdDelete(roleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRoleIdDelete(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRoleIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRoleIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} roleId 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesRoleIdPut(roleId: string, rolePermissionsRequest: RolePermissionsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAndPermissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesRoleIdPut(roleId, rolePermissionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGet(options?: any): AxiosPromise<RolesAndPermissionsResponse> {
            return localVarFp.rolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.rolesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesPost(rolePermissionsRequest: RolePermissionsRequest, options?: any): AxiosPromise<RoleAndPermissions> {
            return localVarFp.rolesPost(rolePermissionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdDelete(roleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rolesRoleIdDelete(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.rolesRoleIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} roleId 
         * @param {RolePermissionsRequest} rolePermissionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesRoleIdPut(roleId: string, rolePermissionsRequest: RolePermissionsRequest, options?: any): AxiosPromise<RoleAndPermissions> {
            return localVarFp.rolesRoleIdPut(roleId, rolePermissionsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesGet(options?: any) {
        return RolesApiFp(this.configuration).rolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesOptions(options?: any) {
        return RolesApiFp(this.configuration).rolesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolePermissionsRequest} rolePermissionsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesPost(rolePermissionsRequest: RolePermissionsRequest, options?: any) {
        return RolesApiFp(this.configuration).rolesPost(rolePermissionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesRoleIdDelete(roleId: string, options?: any) {
        return RolesApiFp(this.configuration).rolesRoleIdDelete(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesRoleIdOptions(options?: any) {
        return RolesApiFp(this.configuration).rolesRoleIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} roleId 
     * @param {RolePermissionsRequest} rolePermissionsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public rolesRoleIdPut(roleId: string, rolePermissionsRequest: RolePermissionsRequest, options?: any) {
        return RolesApiFp(this.configuration).rolesRoleIdPut(roleId, rolePermissionsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoutingProfilesApi - axios parameter creator
 * @export
 */
export const RoutingProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve routing profiles for a business unit
         * @summary Retrieve routing profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/routingProfiles`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/routingProfiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a  routing profile for a business unit
         * @summary Create a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesPost: async (businessUnitId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createRoutingProfileRequest' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesPost', 'createRoutingProfileRequest', createRoutingProfileRequest)
            const localVarPath = `/{businessUnitId}/routingProfiles`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoutingProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a  routing profile for a business unit
         * @summary Deletes a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdDelete: async (businessUnitId: string, routingProfileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'routingProfileId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdDelete', 'routingProfileId', routingProfileId)
            const localVarPath = `/{businessUnitId}/routingProfiles/{routingProfileId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"routingProfileId"}}`, encodeURIComponent(String(routingProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a routing profile by business unit and id
         * @summary Retrieve a routing profile by business unit and id
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdGet: async (businessUnitId: string, routingProfileId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'routingProfileId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdGet', 'routingProfileId', routingProfileId)
            const localVarPath = `/{businessUnitId}/routingProfiles/{routingProfileId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"routingProfileId"}}`, encodeURIComponent(String(routingProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/routingProfiles/{routingProfileId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a  routing profile for a business unit
         * @summary Updates a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdPut: async (businessUnitId: string, routingProfileId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'routingProfileId' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdPut', 'routingProfileId', routingProfileId)
            // verify required parameter 'createRoutingProfileRequest' is not null or undefined
            assertParamExists('businessUnitIdRoutingProfilesRoutingProfileIdPut', 'createRoutingProfileRequest', createRoutingProfileRequest)
            const localVarPath = `/{businessUnitId}/routingProfiles/{routingProfileId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"routingProfileId"}}`, encodeURIComponent(String(routingProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoutingProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoutingProfilesApi - functional programming interface
 * @export
 */
export const RoutingProfilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoutingProfilesApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve routing profiles for a business unit
         * @summary Retrieve routing profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRoutingProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a  routing profile for a business unit
         * @summary Create a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesPost(businessUnitId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutingProfileDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesPost(businessUnitId, createRoutingProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a  routing profile for a business unit
         * @summary Deletes a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId: string, routingProfileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId, routingProfileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a routing profile by business unit and id
         * @summary Retrieve a routing profile by business unit and id
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId: string, routingProfileId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoutingProfileDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId, routingProfileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesRoutingProfileIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesRoutingProfileIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a  routing profile for a business unit
         * @summary Updates a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId: string, routingProfileId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId, routingProfileId, createRoutingProfileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoutingProfilesApi - factory interface
 * @export
 */
export const RoutingProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoutingProfilesApiFp(configuration)
    return {
        /**
         * Retrieve routing profiles for a business unit
         * @summary Retrieve routing profiles for a business unit
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesGet(businessUnitId: string, options?: any): AxiosPromise<ListRoutingProfileResponse> {
            return localVarFp.businessUnitIdRoutingProfilesGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdRoutingProfilesOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a  routing profile for a business unit
         * @summary Create a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesPost(businessUnitId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any): AxiosPromise<RoutingProfileDetailResponse> {
            return localVarFp.businessUnitIdRoutingProfilesPost(businessUnitId, createRoutingProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a  routing profile for a business unit
         * @summary Deletes a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId: string, routingProfileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId, routingProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a routing profile by business unit and id
         * @summary Retrieve a routing profile by business unit and id
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId: string, routingProfileId: string, options?: any): AxiosPromise<RoutingProfileDetailResponse> {
            return localVarFp.businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId, routingProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdRoutingProfilesRoutingProfileIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a  routing profile for a business unit
         * @summary Updates a routing profile for a business unit
         * @param {string} businessUnitId 
         * @param {string} routingProfileId 
         * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId: string, routingProfileId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId, routingProfileId, createRoutingProfileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoutingProfilesApi - object-oriented interface
 * @export
 * @class RoutingProfilesApi
 * @extends {BaseAPI}
 */
export class RoutingProfilesApi extends BaseAPI {
    /**
     * Retrieve routing profiles for a business unit
     * @summary Retrieve routing profiles for a business unit
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesGet(businessUnitId: string, options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesOptions(options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a  routing profile for a business unit
     * @summary Create a routing profile for a business unit
     * @param {string} businessUnitId 
     * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesPost(businessUnitId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesPost(businessUnitId, createRoutingProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a  routing profile for a business unit
     * @summary Deletes a routing profile for a business unit
     * @param {string} businessUnitId 
     * @param {string} routingProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId: string, routingProfileId: string, options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesRoutingProfileIdDelete(businessUnitId, routingProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a routing profile by business unit and id
     * @summary Retrieve a routing profile by business unit and id
     * @param {string} businessUnitId 
     * @param {string} routingProfileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId: string, routingProfileId: string, options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesRoutingProfileIdGet(businessUnitId, routingProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesRoutingProfileIdOptions(options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesRoutingProfileIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a  routing profile for a business unit
     * @summary Updates a routing profile for a business unit
     * @param {string} businessUnitId 
     * @param {string} routingProfileId 
     * @param {CreateRoutingProfileRequest} createRoutingProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoutingProfilesApi
     */
    public businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId: string, routingProfileId: string, createRoutingProfileRequest: CreateRoutingProfileRequest, options?: any) {
        return RoutingProfilesApiFp(this.configuration).businessUnitIdRoutingProfilesRoutingProfileIdPut(businessUnitId, routingProfileId, createRoutingProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpeechApi - axios parameter creator
 * @export
 */
export const SpeechApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Takes text string and returns voice file
         * @param {string} businessUnitId Business unit unique identifier
         * @param {string} textString Textual message to be converted to mp3
         * @param {string} [voiceId] The voice to use from Amazon Polly - defaults to Amy.
         * @param {string} [languageCode] The code of the language used by Amazon Polly. Only required if the voice chosen is bilingual. If voice is bilingual and no language specified, uses default.
         * @param {string} [engine] whether Polly will use a standard voice or a neural voice. only \&#39;standard\&#39; and \&#39;neural\&#39; are accepted here, defaults to standard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdSpeechGet: async (businessUnitId: string, textString: string, voiceId?: string, languageCode?: string, engine?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdSpeechGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'textString' is not null or undefined
            assertParamExists('businessUnitIdSpeechGet', 'textString', textString)
            const localVarPath = `/{businessUnitId}/speech`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (textString !== undefined) {
                localVarQueryParameter['textString'] = textString;
            }

            if (voiceId !== undefined) {
                localVarQueryParameter['voiceId'] = voiceId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (engine !== undefined) {
                localVarQueryParameter['engine'] = engine;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdSpeechOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/speech`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeechApi - functional programming interface
 * @export
 */
export const SpeechApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpeechApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Takes text string and returns voice file
         * @param {string} businessUnitId Business unit unique identifier
         * @param {string} textString Textual message to be converted to mp3
         * @param {string} [voiceId] The voice to use from Amazon Polly - defaults to Amy.
         * @param {string} [languageCode] The code of the language used by Amazon Polly. Only required if the voice chosen is bilingual. If voice is bilingual and no language specified, uses default.
         * @param {string} [engine] whether Polly will use a standard voice or a neural voice. only \&#39;standard\&#39; and \&#39;neural\&#39; are accepted here, defaults to standard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdSpeechGet(businessUnitId: string, textString: string, voiceId?: string, languageCode?: string, engine?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdSpeechGet(businessUnitId, textString, voiceId, languageCode, engine, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdSpeechOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdSpeechOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpeechApi - factory interface
 * @export
 */
export const SpeechApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpeechApiFp(configuration)
    return {
        /**
         * 
         * @summary Takes text string and returns voice file
         * @param {string} businessUnitId Business unit unique identifier
         * @param {string} textString Textual message to be converted to mp3
         * @param {string} [voiceId] The voice to use from Amazon Polly - defaults to Amy.
         * @param {string} [languageCode] The code of the language used by Amazon Polly. Only required if the voice chosen is bilingual. If voice is bilingual and no language specified, uses default.
         * @param {string} [engine] whether Polly will use a standard voice or a neural voice. only \&#39;standard\&#39; and \&#39;neural\&#39; are accepted here, defaults to standard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdSpeechGet(businessUnitId: string, textString: string, voiceId?: string, languageCode?: string, engine?: string, options?: any): AxiosPromise<any> {
            return localVarFp.businessUnitIdSpeechGet(businessUnitId, textString, voiceId, languageCode, engine, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdSpeechOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdSpeechOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpeechApi - object-oriented interface
 * @export
 * @class SpeechApi
 * @extends {BaseAPI}
 */
export class SpeechApi extends BaseAPI {
    /**
     * 
     * @summary Takes text string and returns voice file
     * @param {string} businessUnitId Business unit unique identifier
     * @param {string} textString Textual message to be converted to mp3
     * @param {string} [voiceId] The voice to use from Amazon Polly - defaults to Amy.
     * @param {string} [languageCode] The code of the language used by Amazon Polly. Only required if the voice chosen is bilingual. If voice is bilingual and no language specified, uses default.
     * @param {string} [engine] whether Polly will use a standard voice or a neural voice. only \&#39;standard\&#39; and \&#39;neural\&#39; are accepted here, defaults to standard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechApi
     */
    public businessUnitIdSpeechGet(businessUnitId: string, textString: string, voiceId?: string, languageCode?: string, engine?: string, options?: any) {
        return SpeechApiFp(this.configuration).businessUnitIdSpeechGet(businessUnitId, textString, voiceId, languageCode, engine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeechApi
     */
    public businessUnitIdSpeechOptions(options?: any) {
        return SpeechApiFp(this.configuration).businessUnitIdSpeechOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserPermissionsGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUserPermissionsGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/user/permissions`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserPermissionsOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/user/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a single business unit role for a user
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdDelete: async (businessUnitId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdDelete', 'businessUnitId', businessUnitId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdDelete', 'userId', userId)
            const localVarPath = `/{businessUnitId}/user/{userId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an object containing role and profile data
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdGet: async (businessUnitId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdGet', 'businessUnitId', businessUnitId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdGet', 'userId', userId)
            const localVarPath = `/{businessUnitId}/user/{userId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/user/{userId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the role for a user in a specific business unit
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdPut: async (businessUnitId: string, userId: string, updateUserRequest: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdPut', 'businessUnitId', businessUnitId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdPut', 'userId', userId)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('businessUnitIdUserUserIdPut', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/{businessUnitId}/user/{userId}`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersGet: async (businessUnitId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUsersGet', 'businessUnitId', businessUnitId)
            const localVarPath = `/{businessUnitId}/users`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersOptions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/{businessUnitId}/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersPost: async (businessUnitId: string, createUserRequest: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessUnitId' is not null or undefined
            assertParamExists('businessUnitIdUsersPost', 'businessUnitId', businessUnitId)
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('businessUnitIdUsersPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/{businessUnitId}/users`
                .replace(`{${"businessUnitId"}}`, encodeURIComponent(String(businessUnitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cognito_token required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserPermissionsGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserPermissionsGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserPermissionsOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserPermissionsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a single business unit role for a user
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserUserIdDelete(businessUnitId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserUserIdDelete(businessUnitId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns an object containing role and profile data
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserUserIdGet(businessUnitId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserUserIdGet(businessUnitId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserUserIdOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserUserIdOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the role for a user in a specific business unit
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUserUserIdPut(businessUnitId: string, userId: string, updateUserRequest: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUserUserIdPut(businessUnitId, userId, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUsersGet(businessUnitId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUsersGet(businessUnitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUsersOptions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUsersOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async businessUnitIdUsersPost(businessUnitId: string, createUserRequest: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.businessUnitIdUsersPost(businessUnitId, createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserPermissionsGet(businessUnitId: string, options?: any): AxiosPromise<PermissionsResponse> {
            return localVarFp.businessUnitIdUserPermissionsGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserPermissionsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUserPermissionsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a single business unit role for a user
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdDelete(businessUnitId: string, userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUserUserIdDelete(businessUnitId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an object containing role and profile data
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdGet(businessUnitId: string, userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.businessUnitIdUserUserIdGet(businessUnitId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUserUserIdOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the role for a user in a specific business unit
         * @param {string} businessUnitId 
         * @param {string} userId 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUserUserIdPut(businessUnitId: string, userId: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUserUserIdPut(businessUnitId, userId, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersGet(businessUnitId: string, options?: any): AxiosPromise<UsersResponse> {
            return localVarFp.businessUnitIdUsersGet(businessUnitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable CORS by returning correct headers 
         * @summary CORS support
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersOptions(options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUsersOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessUnitId 
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        businessUnitIdUsersPost(businessUnitId: string, createUserRequest: CreateUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.businessUnitIdUsersPost(businessUnitId, createUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserPermissionsGet(businessUnitId: string, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserPermissionsGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserPermissionsOptions(options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserPermissionsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a single business unit role for a user
     * @param {string} businessUnitId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserUserIdDelete(businessUnitId: string, userId: string, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserUserIdDelete(businessUnitId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an object containing role and profile data
     * @param {string} businessUnitId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserUserIdGet(businessUnitId: string, userId: string, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserUserIdGet(businessUnitId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserUserIdOptions(options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserUserIdOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the role for a user in a specific business unit
     * @param {string} businessUnitId 
     * @param {string} userId 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUserUserIdPut(businessUnitId: string, userId: string, updateUserRequest: UpdateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUserUserIdPut(businessUnitId, userId, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUsersGet(businessUnitId: string, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUsersGet(businessUnitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable CORS by returning correct headers 
     * @summary CORS support
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUsersOptions(options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUsersOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessUnitId 
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public businessUnitIdUsersPost(businessUnitId: string, createUserRequest: CreateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).businessUnitIdUsersPost(businessUnitId, createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


