import downloadAuditReport from "utils/downloadAuditReport";
import { GetState, SetState } from "zustand";
import { AuditApi, AuditRequest } from "../../../api/api";
import { getAuditApiInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export enum AuditLogType {
  EgressInstance = "queue flow instance",
  EgressTemplate = "queue flow template",
  FlowConfigInstance = "flow config instance",
  FlowConfigTemplate = "flow config template",
  IngressInstance = "ingress instance",
  IngressTemplate = "ingress template",
  OperatingHours = "operating hours",
  Agent = "agent",
  PortalUser = "portal user",
  Role = "role",
  RoutingProfile = "routing profile",
  Queue = "queues",
}

export type AuditLogDataAction =
  | "add"
  | "edit"
  | "delete"
  | "enable"
  | "disable"
  | "open"
  | "close";

type AuditLogsData = {
  Action: AuditLogDataAction;
  Result: "success" | "failure";
  ErrorReason?: string;
  BusinessUnitName?: string;
  [AdditionalProperties: string]: unknown;
};

export type AuditStore = {
  auditApi: AuditApi;
  getAuditLogs: {
    loading: boolean;
    error: string | undefined;
    call: (start: string, end: string) => Promise<void>;
  };
  submitAuditLog: {
    loading: boolean;
    call: (type: AuditLogType, message: string, data: AuditLogsData) => Promise<void>;
  };
};

export const auditStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): AuditStore => ({
  auditApi: getAuditApiInstance(),
  getAuditLogs: {
    loading: false,
    error: undefined,
    call: (start: string, end: string) => {
      const { auditApi, currentBusinessUnit } = get();
      set((state) => {
        state.getAuditLogs.loading = true;
        state.getAuditLogs.error = undefined;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No business unit set");
        } else {
          auditApi
            .businessUnitIdAuditGet(currentBusinessUnit.Id, start, end)
            .then((response) => {
              downloadAuditReport(response.data);
              resolve();
            })
            .catch((error) => {
              set((state) => {
                state.getAuditLogs.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getAuditLogs.loading = false;
              });
            });
        }
      });
    },
  },
  submitAuditLog: {
    loading: false,
    call: (type: AuditLogType, message: string, data: AuditLogsData) => {
      const { auditApi, currentBusinessUnit } = get();
      set((state) => {
        state.submitAuditLog.loading = true;
      });
      const auditLogData = {
        ...data,
        BusinessUnitName: currentBusinessUnit?.Description,
      };
      const auditRequest: AuditRequest = {
        Type: type,
        BusinessUnitId: currentBusinessUnit?.Id,
        Message: message,
        Data: auditLogData,
      };
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No business unit set");
        } else {
          auditApi
            .auditPost(auditRequest)
            .then(() => {
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.submitAuditLog.loading = false;
              });
            });
        }
      });
    },
  },
});
