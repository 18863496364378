import ErrorStatusModal from "@vfuk/core-error-status-modal";
import { useTranslation } from "react-i18next";

type SomethingWentWrongProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SomethingWentWrong = ({ isOpen, onClose }: SomethingWentWrongProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ErrorStatusModal
      srName="sign-in-error-modal"
      headingText={t("common:errors.something_went_wrong.title")}
      text={t("common:errors.something_went_wrong.body")}
      isOpen={isOpen}
      onCloseCb={onClose}
      button={{
        text: t("common:errors.something_went_wrong.button"),
        onClick: onClose,
      }}
    />
  );
};

export default SomethingWentWrong;
