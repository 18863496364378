import {
  AgentApi,
  CreateAgentRequest,
  DescribeAgentResponse,
  ListAgentSummaryItem,
  LookupItem,
  RoutingProfilesApi,
  UpdateAgentRequest,
} from "api";
import { getAgentsInstance, getRoutingProfilesInstance } from "utils/getApiInstance";
import { wait } from "utils/wait";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type AgentsStore = {
  agentsApi: AgentApi;
  routingProfilesApi: RoutingProfilesApi;
  getAgents: {
    agents: Array<ListAgentSummaryItem>;
    loading: boolean;
    error: string | undefined;
    call: () => void;
    clear: () => void;
    refresh: () => void;
  };
  getAgentDetails: {
    detailedAgents: Array<DescribeAgentResponse>;
    call: (agentId: string) => Promise<DescribeAgentResponse>;
    loading: boolean;
  };
  addAgent: {
    call: (agent: CreateAgentRequest) => Promise<DescribeAgentResponse>;
    loading: boolean;
  };
  editAgent: {
    call: (agentId: string, agent: UpdateAgentRequest) => Promise<DescribeAgentResponse>;
    loading: boolean;
  };
  getSecurityProfiles: {
    securityProfiles: Array<LookupItem>;
    call: () => Promise<void>;
    loading: boolean;
    fetched: boolean;
    error: string;
  };
  addSelectedAgent: {
    selectedAgents: Array<ListAgentSummaryItem>;
    call: (agent: ListAgentSummaryItem) => void;
  };
  deselectAgent: (agent: ListAgentSummaryItem) => void;
  clearAllSelectedAgents: () => void;
  deleteAgents: {
    loading: boolean;
    call: () => Promise<Array<ListAgentSummaryItem>>;
  };
};

export const agentsStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): AgentsStore => ({
  agentsApi: getAgentsInstance(),
  routingProfilesApi: getRoutingProfilesInstance(),
  getAgents: {
    agents: [],
    loading: false,
    error: undefined,
    call: () => {
      const { agentsApi, currentBusinessUnit } = get();
      if (!currentBusinessUnit) {
        set((state) => {
          state.getAgents.error = "No business unit set";
        });
      } else {
        set((state) => {
          state.getAgents.loading = true;
          state.getAgents.error = undefined;
        });
        agentsApi
          .businessUnitIdAgentsGet(currentBusinessUnit.Id)
          .then((result) => {
            set((state) => {
              state.getAgents.agents = result.data.AgentSummary;
            });
          })
          .catch((error) => {
            set((state) => {
              state.getAgents.error = error.toString();
              state.getAgents.loading = false;
            });
          })
          .finally(() => {
            set((state) => {
              state.getAgents.loading = false;
            });
          });
      }
    },
    clear: () => {
      set((state) => {
        state.getAgents.agents = [];
      });
    },
    refresh: () => {
      get().getAgents.clear();
      get().getAgents.call();
    },
  },
  getAgentDetails: {
    detailedAgents: [],
    loading: false,
    call: (agentId: string) => {
      const state = get();
      const { currentBusinessUnit, agentsApi } = get();
      set((state) => void (state.getAgentDetails.loading = true));
      return new Promise<DescribeAgentResponse>((resolve, reject) => {
        if (!currentBusinessUnit || !agentId) reject("No BU or agent ID");
        else {
          agentsApi
            .businessUnitIdAgentsAgentIdGet(currentBusinessUnit.Id, agentId)
            .then((result) => {
              const agentDetails = result.data;
              if (agentDetails) {
                const agentIndex = state.getAgentDetails.detailedAgents.findIndex(
                  (agent) => agent.Id === agentId
                );
                set((state) => {
                  if (agentIndex > -1)
                    state.getAgentDetails.detailedAgents[agentIndex] = agentDetails;
                  else state.getAgentDetails.detailedAgents.push(agentDetails);
                });
                resolve(agentDetails);
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getAgentDetails.loading = false;
              })
            );
        }
      });
    },
  },
  addAgent: {
    loading: false,
    call: (agent: CreateAgentRequest) => {
      const {
        currentBusinessUnit,
        agentsApi,
        getAgents: { refresh: refreshAgents },
      } = get();
      set((state) => {
        state.addAgent.loading = true;
      });
      return new Promise<DescribeAgentResponse>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          agentsApi
            .businessUnitIdAgentsPost(currentBusinessUnit.Id, agent)
            .then((result) => {
              const newAgent = result.data;
              if (newAgent) {
                set((state) => {
                  state.getAgentDetails.detailedAgents.push(newAgent);
                });
                refreshAgents();
                resolve(newAgent);
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addAgent.loading = false;
              })
            );
        }
      });
    },
  },
  editAgent: {
    loading: false,
    call: (agentId: string, agent: CreateAgentRequest) => {
      const {
        currentBusinessUnit,
        agentsApi,
        getAgents: { refresh: refreshAgents },
      } = get();
      set((state) => {
        state.editAgent.loading = true;
      });
      return new Promise<DescribeAgentResponse>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          agentsApi
            .businessUnitIdAgentsAgentIdPut(currentBusinessUnit.Id, agentId, agent)
            .then((result) => {
              const updatedAgent = result.data;
              if (updatedAgent) {
                set((state) => {
                  const agentIndex = state.getAgentDetails.detailedAgents.findIndex(
                    (agent) => agent.Id === agentId
                  );
                  if (agentIndex >= 0)
                    state.getAgentDetails.detailedAgents[agentIndex] = updatedAgent;
                  else state.getAgentDetails.detailedAgents.push(updatedAgent);
                });
                refreshAgents();
                resolve(updatedAgent);
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editAgent.loading = false;
              })
            );
        }
      });
    },
  },
  getSecurityProfiles: {
    securityProfiles: [],
    loading: false,
    fetched: false,
    error: "",
    call: () => {
      const { agentsApi, currentBusinessUnit } = get();
      set((state) => {
        state.getSecurityProfiles.loading = true;
        state.getSecurityProfiles.error = "";
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU or agent ID");
        else {
          agentsApi
            .businessUnitIdAgentsSecurityProfilesGet(currentBusinessUnit.Id)
            .then((result) => {
              const securityProfiles = result.data;
              if (securityProfiles.length > 0) {
                set((state) => {
                  state.getSecurityProfiles.securityProfiles = securityProfiles;
                });
                resolve();
              }
            })
            .catch((error) => {
              set((state) => {
                state.getSecurityProfiles.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getSecurityProfiles.loading = false;
                state.getSecurityProfiles.fetched = true;
              });
            });
        }
      });
    },
  },
  addSelectedAgent: {
    selectedAgents: [],
    call: (agent: ListAgentSummaryItem) => {
      set((state) => {
        state.addSelectedAgent.selectedAgents.push(agent);
      });
    },
  },
  deselectAgent: (agent: ListAgentSummaryItem) => {
    set((state) => {
      const index = state.addSelectedAgent.selectedAgents.findIndex(
        (selectedAgent) => selectedAgent.Id === agent.Id
      );
      state.addSelectedAgent.selectedAgents.splice(index, 1);
    });
  },
  clearAllSelectedAgents: () => {
    set((state) => {
      state.addSelectedAgent.selectedAgents = [];
    });
  },
  deleteAgents: {
    loading: false,
    call: async () => {
      const { agentsApi, currentBusinessUnit, addSelectedAgent, getAgents } = get();
      let failureEncountered = false;
      const failedAgents: ListAgentSummaryItem[] = [];
      const deletedAgents: ListAgentSummaryItem[] = [];

      set((state) => {
        state.deleteAgents.loading = true;
      });

      for (const agent of addSelectedAgent.selectedAgents) {
        if (failureEncountered || !agent.Id || !currentBusinessUnit) {
          failedAgents.push(agent);
        } else {
          try {
            await agentsApi.businessUnitIdAgentsAgentIdDelete(currentBusinessUnit.Id, agent.Id);
            deletedAgents.push(agent);
          } catch {
            failedAgents.push(agent);
            failureEncountered = true;
          }
          await wait(100);
        }
      }

      return new Promise(
        (
          resolve: (deletedAgents: Array<ListAgentSummaryItem>) => void,
          reject: (failedAgents: Array<ListAgentSummaryItem>) => void
        ) => {
          if (!currentBusinessUnit) reject(addSelectedAgent.selectedAgents);
          else {
            const trimmedAgents = getAgents.agents.filter((agent) => {
              if (
                addSelectedAgent.selectedAgents.find(
                  (selectedAgent) => selectedAgent.Id === agent.Id
                )
              ) {
                if (failedAgents.find((failedAgent) => failedAgent.Id === agent.Id)) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            });

            set((state) => {
              state.getAgents.agents = trimmedAgents;
              state.addSelectedAgent.selectedAgents = failedAgents;
              state.deleteAgents.loading = false;
            });

            if (failedAgents.length > 0) {
              reject(failedAgents);
            } else resolve(deletedAgents);
          }
        }
      );
    },
  },
});
