import { useBusinessUnitsStore, usePermissionsStore } from "hooks/stores";
import { useEffect } from "react";

const useRevalidatedPermissions = (): void => {
  const setBusinessUnits = useBusinessUnitsStore((state) => state.setBusinessUnits);
  const setPermissions = usePermissionsStore((state) => state.setPermissions);

  useEffect(() => {
    const cacheUpdateListener = async (event: MessageEvent) => {
      if (event.data.type === "CACHE_UPDATED") {
        const { url, response } = event.data.payload;

        if (url.endsWith("businessunits") && response.BusinessUnits) {
          setBusinessUnits(response.BusinessUnits);
        }

        if (url.endsWith("permissions") && response.Permissions) {
          setPermissions(response.Permissions);
        }
      }
    };

    navigator.serviceWorker.addEventListener("message", cacheUpdateListener);

    return () => navigator.serviceWorker.removeEventListener("message", cacheUpdateListener);
  }, []);
};

export default useRevalidatedPermissions;
