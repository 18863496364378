import { BreadcrumbsProps } from "@vfuk/core-breadcrumbs/dist/Breadcrumbs.types";
import { Grid } from "@vfuk/core-grid";
import Icon from "@vfuk/core-icon";
import Paragraph from "@vfuk/core-paragraph";
import { useHistory } from "react-router-dom";
import classes from "./Breadcrumbs.module.scss";

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
  const history = useHistory();

  const goToPageOnCLick = (url: string) => {
    history.push(url);
  };

  const renderBreadcrumb = (i: number, text: string, url: string) => {
    if (i === props.items.length - 1) {
      return (
        <div className={classes.LinkTextLast}>
          <Paragraph weight={2}>{text}</Paragraph>
        </div>
      );
    } else {
      return (
        <>
          <div className={classes.LinkText} onClick={() => goToPageOnCLick(url)}>
            <Paragraph size={2} weight={2}>
              {text}
            </Paragraph>
          </div>
          <Icon name="chevron-right" size={1} />
        </>
      );
    }
  };

  return (
    <Grid maxWidth="spring" className={classes.Breadcrumbs}>
      <nav aria-label="breadcrumbs">
        {props.items.map((item, i) => (
          <div key={item.id}>{renderBreadcrumb(i, item.text, item.url)}</div>
        ))}
      </nav>
    </Grid>
  );
};

export default Breadcrumbs;
