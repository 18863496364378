import { AccessTypeEnum, Permissions, PermissionTypeEnum } from "api";
import { getAllowedAccessTypes } from "utils/permissionsUtils";
import { usePermissionsStore } from "../stores";

type PermissionsHook = {
  hasPermission: (requestedPermission: Permissions) => boolean;
  hasPermissions: (requestedPermissions: Array<Permissions>) => boolean;
  hasAnyPermission: (requestedPermissions: Array<Permissions>) => boolean;
  getAccessForPermission: (requestedType: PermissionTypeEnum) => AccessTypeEnum;
};

const usePermissions = (): PermissionsHook => {
  const permissionsFetched = usePermissionsStore((state) => state.getPermissions.called);
  const permissions = usePermissionsStore((state) => state.permissions);

  const hasPermission = (requestedPermission: Permissions): boolean => {
    const allowedAccessTypes = getAllowedAccessTypes(requestedPermission.Access);
    return (
      permissionsFetched &&
      permissions.some((permission) => {
        return (
          permission.Type === requestedPermission.Type &&
          allowedAccessTypes.includes(permission.Access || AccessTypeEnum.None)
        );
      })
    );
  };

  const hasPermissions = (requestedPermissions: Array<Permissions>): boolean => {
    return (
      permissionsFetched &&
      requestedPermissions.every((requestedPermission) => {
        return hasPermission(requestedPermission);
      })
    );
  };

  const hasAnyPermission = (requestedPermissions: Array<Permissions>): boolean => {
    return (
      permissionsFetched &&
      requestedPermissions.some((requestedPermission) => {
        return hasPermission(requestedPermission);
      })
    );
  };

  const getAccessForPermission = (requestedType: PermissionTypeEnum): AccessTypeEnum => {
    const matchedPermission = permissions.find((permission) => {
      return permission.Type === requestedType;
    });
    if (matchedPermission) {
      return matchedPermission.Access || AccessTypeEnum.None;
    } else return AccessTypeEnum.None;
  };

  return {
    hasPermission: hasPermission,
    hasPermissions: hasPermissions,
    hasAnyPermission: hasAnyPermission,
    getAccessForPermission: getAccessForPermission,
  };
};

export default usePermissions;
