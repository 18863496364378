import Button from "@vfuk/core-button/dist/Button";
import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import { ReactComponent as SuccessTick } from "assets/svg/success-tick.svg";
import { ReactNode } from "react";
import classes from "./SuccessModalContent.module.scss";

export type SuccessModalContentProps = {
  heading: string;
  body: string | ReactNode;
  confirmText: string;
  onConfirm: () => void;
};

const SuccessModalContent = ({
  heading,
  body,
  confirmText,
  onConfirm,
}: SuccessModalContentProps): JSX.Element => {
  return (
    <div className={classes.SuccessContents} role="dialog">
      <SuccessTick />
      <Heading size={2} weight={3}>
        {heading}
      </Heading>
      <Paragraph>{body}</Paragraph>
      <div className={classes.BackButton}>
        <Button width="full" text={confirmText} onClick={onConfirm} />
      </div>
    </div>
  );
};

export default SuccessModalContent;
