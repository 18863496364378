import {
  AgentApi,
  AuditApi,
  BusinessUnitsApi,
  Configuration,
  EgressApi,
  FlowAttributesApi,
  FlowConfigApi,
  FlowTemplatesApi,
  IngressApi,
  OperationalHoursApi,
  QueuesApi,
  QuickConnectsApi,
  RolesApi,
  RoutingProfilesApi,
  SpeechApi,
  UsersApi,
} from "../api";
import environment from "../config/environment";

export const getUsersApiInstance = (): UsersApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new UsersApi(config);

  return api;
};

export const getRolesApiInstance = (): RolesApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new RolesApi(config);

  return api;
};

export const getBUApiInstance = (): BusinessUnitsApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new BusinessUnitsApi(config);

  return api;
};

export const getOperatingHoursInstance = (): OperationalHoursApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new OperationalHoursApi(config);

  return api;
};

export const getAgentsInstance = (): AgentApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new AgentApi(config);

  return api;
};

export const getRoutingProfilesInstance = (): RoutingProfilesApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new RoutingProfilesApi(config);

  return api;
};

export const getQueuesInstance = (): QueuesApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new QueuesApi(config);

  return api;
};

export const getQuickConnectsInstance = (): QuickConnectsApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new QuickConnectsApi(config);

  return api;
};

export const getFlowTemplatesInstance = (): FlowTemplatesApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new FlowTemplatesApi(config);

  return api;
};

export const getFlowAttributesInstance = (): FlowAttributesApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new FlowAttributesApi(config);

  return api;
};

export const getFlowConfigsInstance = (): FlowConfigApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new FlowConfigApi(config);

  return api;
};

export const getIngressInstance = (): IngressApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new IngressApi(config);

  return api;
};

export const getEgressInstance = (): EgressApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new EgressApi(config);

  return api;
};

export const getAuditApiInstance = (): AuditApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new AuditApi(config);

  return api;
};

export const getSpeechApiInstance = (): SpeechApi => {
  const config = new Configuration({
    basePath: environment.aws.apiGateway.basePath,
  });

  const api = new SpeechApi(config);

  return api;
};
