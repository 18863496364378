import { FooterProps } from "@vfuk/core-footer/dist/Footer.types";

// No translation happening here. Can just return another config based on region if needed down the line
const getFooterProps = (): FooterProps => {
  return {
    social: [
      {
        name: "twitter-inverse",
        srText: "twitter",
        url: "https://www.twitter.com/VodafoneUK",
      },
      {
        name: "facebook-inverse",
        srText: "facebook",
        url: "https://www.facebook.com/vodafoneUK?ltpn=UK:UCMS:Homepage&ltsn=footerAlready-visible&lts1=7&lts2=1&ltat=Facebook&ltad=3471",
      },
    ],
    legalLinks: [
      {
        text: "Site Map",
        url: "https://www.vodafone.com/sitemap",
      },
      {
        text: "Terms & Conditions",
        url: "https://www.vodafone.co.uk/terms-and-conditions/index.htm",
      },
      {
        text: "Privacy Policy",
        url: "https://www.vodafone.co.uk/privacy?ltpn=UK:UCMS:Homepage&ltsn=footerCome-in&lts1=7&lts2=3&ltat=Privacy_policy&ltad=4087",
      },
      {
        text: "Cookies Policy",
        url: "https://www.vodafone.co.uk/privacy?ltpn=UK:UCMS:Homepage&ltsn=footerCome-in&lts1=7&lts2=4&ltat=Cookie_policy&ltad=4087#/cookie-policy",
      },
    ],
    linkGroups: [
      {
        headingText: "Business Solutions",
        links: [
          {
            text: "Unified Communications",
            url: "https://www.vodafone.co.uk/business/unified-communications",
          },
          {
            text: "Voice connectivity",
            url: "https://www.vodafone.co.uk/business/business-connectivity/voice-connectivity",
          },
          {
            text: "Landline for business",
            url: "https://www.vodafone.co.uk/business/business-connectivity/voice-connectivity/phone-landlines",
          },
          {
            text: "Mobile Device Management",
            url: "https://www.vodafone.co.uk/business/business-mobile-phones/mobile-device-management",
          },
          {
            text: "IoT",
            url: "https://www.vodafone.co.uk/business/iot",
          },
          {
            text: "Cloud",
            url: "https://www.vodafone.co.uk/business/cloud-and-security",
          },
          {
            text: "Office 365",
            url: "https://www.vodafone.co.uk/business/business-apps/office-365-business",
          },
          {
            text: "5G for business",
            url: "https://www.vodafone.co.uk/business/why-vodafone/5g-for-business",
          },
          {
            text: "Trusted partners",
            url: "https://www.vodafone.co.uk/business/vodafone-partners",
          },
          {
            text: "Dedicated Internet Access",
            url: "https://www.vodafone.co.uk/business/business-connectivity/data-connectivity/dedicated-internet-access",
          },
        ],
      },

      {
        headingText: "Vodafone UK",
        links: [
          {
            text: "About us",
            url: "https://www.vodafone.co.uk/help-and-information/about-us",
          },
          {
            text: "For investors",
            url: "https://investors.vodafone.com/",
          },
          {
            text: "News Centre",
            url: "https://newscentre.vodafone.co.uk/",
          },
          {
            text: "Sustainable business",
            url: "https://www.vodafone.co.uk/mobile/sustainable-business",
          },
          {
            text: "Why choose us?",
            url: "https://www.vodafone.co.uk/explore/benefits/",
          },
          {
            text: "Vodafone Community",
            url: "https://forum.vodafone.co.uk/",
          },
          {
            text: "Digital Parenting",
            url: "https://www.vodafone.co.uk/mobile/digital-parenting",
          },
          {
            text: "Modern Slavery Statement",
            url: "https://www.vodafone.co.uk/cs/groups/public/documents/webcontent/modernslaverydisclosure.pdf",
          },
          {
            text: "Accessibility",
            url: "https://www.vodafone.co.uk/help-and-information/accessibility",
          },
          {
            text: "Careers",
            url: "https://careers.vodafone.co.uk/",
          },
          {
            text: "Corporate Governance Statements",
            url: "https://www.vodafone.co.uk/about-us/corporate-governance-statements",
          },
          {
            text: "User research",
            url: "https://www.vodafone.co.uk/user-research",
          },
        ],
      },

      {
        headingText: "Help & Support",
        links: [
          {
            text: "All help topics",
            url: "https://support.vodafone.co.uk/",
          },
          {
            text: "Help with your device",
            url: "https://deviceguides.vodafone.co.uk/",
          },
          {
            text: "Lost or stolen devices",
            url: "https://www.vodafone.co.uk/webcenter/portal/myvodafone/lostandstolen",
          },
          {
            text: "Leaving Vodafone",
            url: "https://www.vodafone.co.uk/help-and-information/cancel-your-account",
          },
          {
            text: "Help with my account",
            url: "https://www.vodafone.co.uk/help-and-information/accessibility/i-need-help-managing-my-account",
          },
          {
            text: "Find a store",
            url: "https://www.vodafone.co.uk/help-and-information/store-locator",
          },
          {
            text: "How to complain",
            url: "https://www.vodafone.co.uk/help-and-information/complaints",
          },
          {
            text: "Contact us",
            url: "https://www.vodafone.co.uk/contact-us",
          },
          {
            text: "Complaints code",
            url: "https://www.vodafone.co.uk/help-and-information/complaints/code-of-practice",
          },
          {
            text: "Repairs",
            url: "https://support.vodafone.co.uk/Device-help-warranty-repair/Repairs",
          },
          {
            text: "Return a product",
            url: "https://www.vodafone.co.uk/my-vodafone-account/orders/returns",
          },
          {
            text: "TOBi",
            url: "https://www.vodafone.co.uk/help-and-information/introducing-tobi",
          },
        ],
      },
    ],
    copyrightText: `© ${new Date().getFullYear()} Vodafone Limited. Registered office: Vodafone House, The Connection, Newbury, Berkshire RG14 2FN.`,
    companyInfo: "Registered in England No 1471587.",
  };
};

export default getFooterProps;
