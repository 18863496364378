import { AgentsStore } from "./agentsStore/agentsStore";
import { AuditStore } from "./auditStore/auditStore";
import { AuthStore } from "./authStore/authStore";
import { BusinessUnitsStore } from "./businessUnitStore/businessUnitsStore";
import { EgressStore } from "./egressStore/egressStore";
import { FlowConfigStore } from "./flowConfigStore/flowConfigStore";
import { IngressStore } from "./ingressStore/ingressStore";
import { OperatingHoursStore } from "./operatingHoursStore/operatingHoursStore";
import { PermissionsStore } from "./permissionsStore/permissionsStore";
import { QueuesStore } from "./queuesStore/queuesStore";
import { RolesStore } from "./rolesStore/rolesStore";
import { RoutingManagementStore } from "./routingManagementStore/routingManagementStore";
import { ServiceWorkerStore } from "./serviceWorkerStore/serviceWorkerStore";
import { useCombinedStore, UseCombinedStore } from "./useCombinedStore";
import { UserDetailsStore } from "./userDetailsStore/userDetailsStore";

// Simulate multiple stores by exporting a subset of the state contained within the combined store
export const useAuthStore: UseCombinedStore<AuthStore> = useCombinedStore;
export const useBusinessUnitsStore: UseCombinedStore<BusinessUnitsStore> = useCombinedStore;
export const usePermissionsStore: UseCombinedStore<PermissionsStore> = useCombinedStore;
export const useAgentsStore: UseCombinedStore<AgentsStore> = useCombinedStore;
export const useUserDetailsStore: UseCombinedStore<UserDetailsStore> = useCombinedStore;
export const useRolesStore: UseCombinedStore<RolesStore> = useCombinedStore;
export const useOperatingHoursStore: UseCombinedStore<OperatingHoursStore> = useCombinedStore;
export const useServiceWorkerStore: UseCombinedStore<ServiceWorkerStore> = useCombinedStore;
export const useRoutingManagementStore: UseCombinedStore<RoutingManagementStore> = useCombinedStore;
export const useQueuesStore: UseCombinedStore<QueuesStore> = useCombinedStore;
export const useFlowConfigStore: UseCombinedStore<FlowConfigStore> = useCombinedStore;
export const useIngressStore: UseCombinedStore<IngressStore> = useCombinedStore;
export const useAuditStore: UseCombinedStore<AuditStore> = useCombinedStore;
export const useEgressStore: UseCombinedStore<EgressStore> = useCombinedStore;
