import {
  CreateEgressConfig,
  DescribeEgressConfig,
  EgressApi,
  EgressTemplateDetailResponse,
  ListEgressConfigItem,
  ListEgressTemplateItem,
  UpdateEgressConfig,
} from "api";
import { getEgressInstance } from "utils/getApiInstance";
import { GetState, SetState } from "zustand";
import { CombinedStore } from "../useCombinedStore";

export type EgressStore = {
  egressApi: EgressApi;
  getEgressTemplates: {
    egressTemplates: Array<ListEgressTemplateItem>;
    loading: boolean;
    error: string;
    called: boolean;
    call: () => Promise<Array<ListEgressTemplateItem>>;
    clear: () => void;
    refresh: () => void;
  };
  getEgressTemplateDetails: {
    detailedEgressTemplates: Array<EgressTemplateDetailResponse>;
    loading: boolean;
    call: (egressTemplateId: string) => Promise<EgressTemplateDetailResponse>;
    clear: () => void;
    refresh: (egressTemplateId: string) => void;
  };
  getEgressInstances: {
    egressInstances: Array<ListEgressConfigItem>;
    loading: boolean;
    error: string;
    called: boolean;
    call: () => Promise<Array<ListEgressConfigItem>>;
    clear: () => void;
    refresh: () => void;
  };
  getEgressInstanceDetails: {
    detailedEgressInstances: Array<DescribeEgressConfig>;
    loading: boolean;
    call: (egressInstanceId: string) => Promise<DescribeEgressConfig>;
    clear: () => void;
    refresh: (egressInstanceId: string) => void;
  };
  editEgressInstance: {
    loading: boolean;
    call: (egressInstanceId: string, egressInstance: UpdateEgressConfig) => Promise<void>;
  };
  addEgressInstance: {
    loading: boolean;
    call: (egressIntance: CreateEgressConfig) => Promise<void>;
  };
  deleteEgressInstance: {
    loading: boolean;
    call: (egressInstanceId: string) => Promise<void>;
  };
};

export const egressStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): EgressStore => ({
  egressApi: getEgressInstance(),
  getEgressTemplates: {
    egressTemplates: [],
    loading: false,
    error: "",
    called: false,
    call: () => {
      const { egressApi, currentBusinessUnit } = get();
      set((state) => {
        state.getEgressTemplates.loading = true;
        state.getEgressTemplates.error = "";
      });

      return new Promise<Array<ListEgressTemplateItem>>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          egressApi
            .businessUnitIdEgressTemplatesGet(currentBusinessUnit.Id)
            .then((result) => {
              const templates = result.data;
              if (templates && templates.length > 0) {
                set((state) => {
                  state.getEgressTemplates.egressTemplates = templates;
                });
                resolve(templates);
              } else if (!templates) {
                throw new Error("Unexpected response from Egress API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getEgressTemplates.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getEgressTemplates.loading = false;
                state.getEgressTemplates.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getEgressTemplates.egressTemplates = [];
        state.getEgressTemplates.called = false;
      });
    },
    refresh: () => {
      get().getEgressTemplates.clear();
      get().getEgressTemplates.call();
    },
  },
  getEgressTemplateDetails: {
    detailedEgressTemplates: [],
    loading: false,
    call: (egressTemplateId: string) => {
      const { currentBusinessUnit, egressApi } = get();
      set((state) => {
        state.getEgressTemplateDetails.loading = true;
      });
      return new Promise<EgressTemplateDetailResponse>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          egressApi
            .businessUnitIdEgressTemplatesEgressTemplateIdGet(
              currentBusinessUnit.Id,
              egressTemplateId
            )
            .then((result) => {
              const egressTemplateDetails = result.data;
              if (egressTemplateDetails) {
                set((state) => {
                  const i = state.getEgressTemplateDetails.detailedEgressTemplates.findIndex(
                    (storedTemplate) => storedTemplate.FlowId === egressTemplateId
                  );
                  if (i >= 0)
                    state.getEgressTemplateDetails.detailedEgressTemplates[i] =
                      egressTemplateDetails;
                  else
                    state.getEgressTemplateDetails.detailedEgressTemplates.push(
                      egressTemplateDetails
                    );
                });
                resolve(egressTemplateDetails);
              } else {
                throw new Error("Unexpected response from Egress API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getEgressTemplateDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getEgressTemplateDetails.detailedEgressTemplates = [];
      });
    },
    refresh: (egressTemplateId: string) => {
      get().getEgressTemplateDetails.clear();
      get().getEgressTemplateDetails.call(egressTemplateId);
    },
  },
  getEgressInstances: {
    egressInstances: [],
    loading: false,
    error: "",
    called: false,
    call: () => {
      const { egressApi, currentBusinessUnit } = get();
      set((state) => {
        state.getEgressInstances.loading = true;
        state.getEgressInstances.error = "";
      });

      return new Promise<Array<ListEgressConfigItem>>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU Set");
        } else {
          egressApi
            .businessUnitIdEgressConfigGet(currentBusinessUnit.Id)
            .then((result) => {
              const egressInstances = result.data;
              if (egressInstances && egressInstances.length > 0) {
                set((state) => {
                  state.getEgressInstances.egressInstances = egressInstances;
                });
                resolve(egressInstances);
              } else if (!egressInstances) {
                throw new Error("Unexpected response from Egress API");
              }
            })
            .catch((error) => {
              set((state) => {
                state.getEgressInstances.error = error;
              });
              reject(error);
            })
            .finally(() => {
              set((state) => {
                state.getEgressInstances.loading = false;
                state.getEgressInstances.called = true;
              });
            });
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getEgressInstances.egressInstances = [];
        state.getEgressInstances.called = false;
      });
    },
    refresh: () => {
      get().getEgressInstances.clear();
      get().getEgressInstances.call();
    },
  },
  getEgressInstanceDetails: {
    detailedEgressInstances: [],
    loading: false,
    call: (egressInstanceId: string) => {
      const { currentBusinessUnit, egressApi } = get();
      set((state) => {
        state.getEgressInstanceDetails.loading = true;
      });
      return new Promise<DescribeEgressConfig>((resolve, reject) => {
        if (!currentBusinessUnit) {
          reject("No BU set");
        } else {
          egressApi
            .businessUnitIdEgressConfigEgressConfigIdGet(currentBusinessUnit.Id, egressInstanceId)
            .then((result) => {
              const egressInstanceDetails = result.data;
              if (egressInstanceDetails) {
                set((state) => {
                  const i = state.getEgressInstanceDetails.detailedEgressInstances.findIndex(
                    (storedInstance) => storedInstance.Configuration === egressInstanceId
                  );
                  if (i >= 0)
                    state.getEgressInstanceDetails.detailedEgressInstances[i] =
                      egressInstanceDetails;
                  else
                    state.getEgressInstanceDetails.detailedEgressInstances.push(
                      egressInstanceDetails
                    );
                });
                resolve(egressInstanceDetails);
              } else {
                throw new Error("Unexpected response from Egress API");
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.getEgressInstanceDetails.loading = false;
              })
            );
        }
      });
    },
    clear: () => {
      set((state) => {
        state.getEgressInstanceDetails.detailedEgressInstances = [];
      });
    },
    refresh: (egressInstanceId: string) => {
      get().getEgressInstanceDetails.clear();
      get().getEgressInstanceDetails.call(egressInstanceId);
    },
  },
  editEgressInstance: {
    loading: false,
    call: (egressInstanceId: string, egressInstance: UpdateEgressConfig) => {
      const {
        currentBusinessUnit,
        egressApi,
        getEgressInstances: { refresh: refreshEgressInstances },
        getEgressInstanceDetails: { refresh: refreshEgressInstanceDetails },
      } = get();
      set((state) => {
        state.editEgressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU set");
        else {
          egressApi
            .businessUnitIdEgressConfigEgressConfigIdPut(
              currentBusinessUnit.Id,
              egressInstanceId,
              egressInstance
            )
            .then(() => {
              refreshEgressInstances();
              refreshEgressInstanceDetails(egressInstanceId);
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.editEgressInstance.loading = false;
              })
            );
        }
      });
    },
  },
  addEgressInstance: {
    loading: false,
    call: (egressInstance: CreateEgressConfig) => {
      const {
        currentBusinessUnit,
        egressApi,
        getEgressInstances: { refresh: refreshEgressInstances },
      } = get();
      set((state) => {
        state.addEgressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          egressApi
            .businessUnitIdEgressConfigPost(currentBusinessUnit.Id, egressInstance)
            .then(() => {
              refreshEgressInstances();
              resolve();
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() =>
              set((state) => {
                state.addEgressInstance.loading = false;
              })
            );
        }
      });
    },
  },
  deleteEgressInstance: {
    loading: false,
    call: (egressInstanceId: string) => {
      const { currentBusinessUnit, egressApi } = get();
      set((state) => {
        state.deleteEgressInstance.loading = true;
      });
      return new Promise<void>((resolve, reject) => {
        if (!currentBusinessUnit) reject("No BU");
        else {
          egressApi
            .deleteEgressConfig(currentBusinessUnit.Id, egressInstanceId)
            .then(() => {
              set((state) => {
                state.getEgressInstances.egressInstances =
                  state.getEgressInstances.egressInstances.filter(
                    (instance) => instance.Configuration !== egressInstanceId
                  );
                state.getEgressInstanceDetails.detailedEgressInstances =
                  state.getEgressInstanceDetails.detailedEgressInstances.filter(
                    (instance) => instance.Configuration !== egressInstanceId
                  );
              });
              resolve();
            })
            .catch(reject)
            .finally(() => {
              set((state) => {
                state.deleteEgressInstance.loading = false;
              });
            });
        }
      });
    },
  },
});
