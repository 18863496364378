import environment from "config/environment";
import produce from "immer";
import create, {
  Destroy,
  EqualityChecker,
  GetState,
  SetState,
  State,
  StateCreator,
  StateSelector,
  Subscribe,
} from "zustand";
import { devtools } from "zustand/middleware";
import { agentsStore, AgentsStore } from "./agentsStore/agentsStore";
import { auditStore, AuditStore } from "./auditStore/auditStore";
import { AuthStore, authStore } from "./authStore/authStore";
import { businessUnitsStore, BusinessUnitsStore } from "./businessUnitStore/businessUnitsStore";
import { egressStore, EgressStore } from "./egressStore/egressStore";
import { FlowConfigStore, flowConfigStore } from "./flowConfigStore/flowConfigStore";
import { ingressStore, IngressStore } from "./ingressStore/ingressStore";
import {
  OperatingHoursStore,
  operatingHoursStore,
} from "./operatingHoursStore/operatingHoursStore";
import { PermissionsStore, permissionsStore } from "./permissionsStore/permissionsStore";
import { QueuesStore, queuesStore } from "./queuesStore/queuesStore";
import { RolesStore, rolesStore } from "./rolesStore/rolesStore";
import {
  rouroutingManagementStore,
  RoutingManagementStore,
} from "./routingManagementStore/routingManagementStore";
import { serviceWorkerStore, ServiceWorkerStore } from "./serviceWorkerStore/serviceWorkerStore";
import { UserDetailsStore, userDetailsStore } from "./userDetailsStore/userDetailsStore";

export type CombinedStore = AuthStore &
  PermissionsStore &
  BusinessUnitsStore &
  AgentsStore &
  UserDetailsStore &
  RolesStore &
  OperatingHoursStore &
  ServiceWorkerStore &
  RoutingManagementStore &
  QueuesStore &
  FlowConfigStore &
  IngressStore &
  AuditStore &
  EgressStore;

export interface UseCombinedStore<T extends State> {
  (): T;
  <U>(selector: StateSelector<T, U>, equalityFn?: EqualityChecker<U>): U;
  setState: SetState<CombinedStore>;
  getState: GetState<CombinedStore>;
  subscribe: Subscribe<CombinedStore>;
  destroy: Destroy;
}

// Immer middleware. Shortcuts the use of immer for immutable state management
const immer =
  <T extends State>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === "function" ? produce(partial as (state: T) => T) : (partial as T);
        return set(nextState, replace);
      },
      get,
      api
    );

// We pull all of the store definitions into this file and create a single combined store.
// Having a single combined store is more scalable, as it allows all of the stores to
// communicate with one another.
const combinedStore = (set: SetState<CombinedStore>, get: GetState<CombinedStore>) => {
  return {
    ...authStore(set),
    ...permissionsStore(set, get),
    ...agentsStore(set, get),
    ...userDetailsStore(set, get),
    ...rolesStore(set, get),
    ...operatingHoursStore(set, get),
    ...businessUnitsStore(set, get),
    ...serviceWorkerStore(),
    ...rouroutingManagementStore(set, get),
    ...queuesStore(set, get),
    ...flowConfigStore(set, get),
    ...ingressStore(set, get),
    ...auditStore(set, get),
    ...egressStore(set, get),
  };
};

export const useCombinedStore = environment.production
  ? create<CombinedStore>(immer(combinedStore))
  : create<CombinedStore>(devtools(immer(combinedStore), "Combined Store"));
