import { GetState, SetState } from "zustand";
import { Permissions, UsersApi } from "../../../api/api";
import { getUsersApiInstance } from "../../../utils/getApiInstance";
import { CombinedStore } from "../useCombinedStore";

export type PermissionsStore = {
  usersApi: UsersApi;
  permissions: Array<Permissions>;
  setPermissions: (permissions: Array<Permissions>) => void;
  getPermissions: {
    called: boolean;
    loading: boolean;
    error: string | undefined;
    call: () => void;
  };
  isSuperUser: boolean | undefined;
};

export const permissionsStore = (
  set: SetState<CombinedStore>,
  get: GetState<CombinedStore>
): PermissionsStore => ({
  usersApi: getUsersApiInstance(),
  permissions: [],
  isSuperUser: undefined,
  setPermissions: (permissions: Array<Permissions>) => {
    set((state) => {
      state.permissions = permissions;
    });
  },
  getPermissions: {
    called: false,
    loading: false,
    error: undefined,
    call: () => {
      const state = get();
      set((state) => {
        state.getPermissions.loading = true;
        state.getPermissions.error = undefined;
      });
      state.usersApi
        .businessUnitIdUserPermissionsGet(
          state.currentBusinessUnit ? state.currentBusinessUnit.Id : ""
        )
        .then((result) => {
          const permissions = result.data.Permissions;
          const isSuperUser = result.data.IsSuperUser;
          if (permissions) {
            set((state) => {
              state.permissions = permissions;
              state.getPermissions.called = true;
              state.getPermissions.loading = false;
              state.isSuperUser = isSuperUser;
            });
          }
        })
        .catch((error) => {
          set((state) => {
            state.getPermissions.error = error;
          });
        });
    },
  },
});
